import React, { useEffect, useRef, useState } from 'react';
import { Badge, Modal, Switch } from 'antd';
// import { changePasswordService } from '../services/authService';
import { Loader } from '../util/Svg';
import { useSelector } from 'react-redux';
import { notificationCountService, notificationService } from '../../services/authService';
import { BellOutlined } from '@ant-design/icons';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';

const { confirm } = Modal;

const Notification = props => {
    let history = useHistory()
    const users = useSelector(state => state.users)
    const [apiData, setApiData] = useState([]);
    const [apiDataCount, setApiDataCount] = useState([]);
    const [loader, setLoader] = useState(false);
    const [pageLimit, setPageLimit] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const [sortValue, setSortValue] = useState(1);
    const [searchKey, setSearchKey] = useState("");
    const [byAccenDecc, setByAccenDecc] = useState("1");

    useEffect(() => {
        listNotification(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
    }, [])

    useEffect(() => {
        notificationCountService()
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiDataCount(res?.responseData?.count);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
            })
            .catch((err) => {
                console.log("error notification", err)
            })

        // notification list 
        listNotification();
    }, [])

    // list content
    const listNotification = (pageNo, pageLimit, searchKey, sortValue, byAccenDecc) => {
        notificationService(pageNo, pageLimit, searchKey, sortValue, byAccenDecc)
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiData(res?.responseData);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false);
                // setSearchStatus(false);
            })
            .catch(err => {
                console.log("notification error", err);
                setLoader(false)
                // setSearchStatus(false);
            })
    }


    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">
                            <span className="me-3">Notification</span>
                            <Badge count={apiDataCount} />
                        </h2>
                    </div>
                </div>
                <div className="px-3 py-4" style={{ boxShadow: "none" }}>
                    <ul className="list-unstyled notification-list">
                        {
                            apiData?.notif?.map((notification, key) => (
                                <li className={`notification-item ${notification?.isRead === 0 ? 'unread' : ''}`} key={key}>
                                    <span className='d-block'>
                                        {notification?.msg}
                                    </span>
                                    <p className='text-end mb-0'>
                                        <small><em>
                                            <Moment format="DD/MM/YYYY">
                                                {notification?.created}
                                            </Moment>
                                        </em></small>
                                    </p>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}
export default Notification;