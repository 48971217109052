import ApiInstance from "./../config/intercepter";
import Api from "./../config/api";
import { resHandle } from "../helper";

// login
export function loginApiFun(payload) {
  return ApiInstance.post(`${Api.login}`, payload);
}

// forgot password
export function forgotPasswordApiFun(payload) {
  return ApiInstance.post(`${Api.forgotPassword}`, payload);
}

// reset Password
export function resetPasswordApiFun(payload) {
  return ApiInstance.post(`${Api.resetPassword}`, payload);
}

// reset Password
export function logoutApiFun(payload) {
  return ApiInstance.post(`${Api.logout}`, payload);
}

// reset Password
export function changePasswordService(payload) {
  return ApiInstance.put(`${Api.changePassword}`, payload);
}

// update Profile
export function updateProfileService(payload) {
  return ApiInstance.get(`${Api.updateProfile}`, payload);
}

export function organizationLoginService(payload) {
  return (
    ApiInstance.post(`${Api.organizationLogin}`, payload)
  )
}

export function organizationUserDetailService(payload) {
  return (
    ApiInstance.get(`${Api.viewOrganization}/${payload}`,)
  )
}

export function organizationProfileService(payload) {
  return (
    ApiInstance.put(`${Api.updateOrganization}`, payload)
  )
}

export function notificationService(pageNo, pageLimit, sKey, filter, sort, type) {
  return (
    ApiInstance.get(`${Api.notification}?page=${pageNo}&limit=${pageLimit}&sKey=${sKey}&sortValue=${filter}&dir=${sort}&type=${type}`)
  )
}

export function notificationCountService() {
  return (
    ApiInstance.post(`${Api.notificationCount}`)
  )
}

