import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Loader } from '../../components/util/Svg';
import { contentListApiFun, deleteContentApiFun, individualStatusApiFunction, orgStatusApiFunction, statusFunction } from '../../services/contentService';
import Moment from 'react-moment';
import { ExclamationCircleOutlined, LoadingOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Pagination, Select, Radio, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { deleteDisease, getAllDiseaseList, statusDisease } from '../../services/diseases';

const { Option } = Select;
const { confirm } = Modal;
const options = [
    { label: 'Declined', value: 2 },
    { label: 'Approved', value: 1 },
];

const DiseaseManagement = props => {
    const access = useSelector(state => state.users?.user?.org?.roles ?? [])
    const diseaseAccess = access.find(role => role.sectionName === 'Travel Disease Management');
    let history = useHistory()
    const [apiData, setApiData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [pageLimit, setPageLimit] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const [sortValue, setSortValue] = useState('');
    const [searchKey, setSearchKey] = useState("");
    const [searchStatus, setSearchStatus] = useState(false);
    const [byAccenDecc, setByAccenDecc] = useState(0);
    const [sortByTitle, setSortByTitle] = useState(false);
    const [sortByFormat, setSortByFormat] = useState(false);
    const [sortByDate, setSortByDate] = useState(false);
    const [selectType, setSellectType] = useState(0);
    const [status, setStatus] = useState(null);


    useEffect(() => {
        listDiseaseData(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
    }, [])

    // list content
    const listDiseaseData = (pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType) => {
        getAllDiseaseList(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType)
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiData(res?.responseData);
                    setPageNo(pageNo);
                } else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false);
                setSearchStatus(false);
            })
            .catch(err => {
                console.log("content error", err);
                setLoader(false)
                setSearchStatus(false);
            })
    }

    // organization status
    const changeStatus = (status, id) => {
        let params = {
            diseaseId: id,
            isEnable: status.target.checked === true ? 1 : 0,
        }
        statusDisease(params)
            .then(res => {
                if (res?.statusCode === 1) {
                    listDiseaseData(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
                } else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }



    // delete content
    const handleDelete = (id) => {
        confirm({
            title: 'Do you Want to delete this Admin?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setLoader(true)
                deleteDisease(id)
                    .then(res => {
                        if (res?.statusCode === 1) {
                            
                                listDiseaseData(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
                            
                        } else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                            localStorage.clear()
                            history.push("/login");
                        }
                        setLoader(false)
                    })
                    .catch(err => {
                        console.log("error", err);
                        setLoader(false)
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    // 
    const editContent = (id, disease) => {
        history.push(`/disease-management/edit/${id}`, disease, "Edit")
    }

    // handle pagination
    const handlePagination = page => {
        listDiseaseData(page, pageLimit, searchKey, sortValue, byAccenDecc, selectType);

    };

    const handleSearch = searchEvent => {
        setPageNo(1);
        setSearchStatus(true)
        let searchKey = searchEvent.target.value;
        setSearchKey(searchKey);
        if (searchKey.length > 1) {
            listDiseaseData(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
        } else {
            listDiseaseData(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
        }
    }


    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Disease Management</h2>
                    </div>
                </div>
                <div className="card  px-3 pb-3">
                    <div className="my-4">
                        <div className="row justify-content-between ">
                            <div className="col-md-5">
                                <div className="search">
                                    {
                                        searchStatus ?
                                            <span className="search-spiner d-flex align-items-center">
                                                <LoadingOutlined style={{ color: "#00BFFF" }} />
                                            </span>
                                            : ""
                                    }
                                    <input className="form-control" type="text" placeholder="Search..." onChange={(e) => handleSearch(e)} />
                                </div>
                            </div>
                            {diseaseAccess?.addAccess && 
                            <div className="col-md-5 text-end d-flex align-items-center justify-content-end">
                                <Link to="/disease-management/add-new" className="btn btn-primary ml-3">Add New</Link>

                            </div>
                                }
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Vaccine Name</span>
                                            {/* {
                                                sortByVaccineName === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "2", false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "2", true, false)} />
                                            } */}
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Created At</span>
                                            {/* {
                                                sortByDate === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "1", false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "1", true, true)} />
                                            } */}
                                        </div>
                                    </th>
                                    <th>Block/Unblock</th>
                                    <th colSpan='3'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    apiData?.diseases?.map((disease, index) => (
                                        <tr key={index}>
                                            <td>{pageLimit * (pageNo - 1) + (index + 1)}</td>
                                            <td>{disease?.vaccineName}</td>
                                            <td>
                                                <Moment format="DD/MM/YYYY">
                                                    {disease?.created}
                                                </Moment>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch">
                                                    <input checked={disease?.isEnable ? true : false} onChange={(e) => changeStatus(e, disease?._id)} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                    {/* <label className="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label> */}
                                                </div>
                                            </td>
                                            {diseaseAccess?.deleteAccess &&
                                            <td>
                                                <i className="cursor-pointer fa fa-trash text-danger" onClick={() => handleDelete(disease?._id)}></i>
                                            </td>
                                            }
                                            {diseaseAccess?.updateAccess &&
                                            <td>
                                                <i className="cursor-pointer fa fa-edit text-primary" onClick={() => editContent(disease?._id, disease)}></i>
                                            </td>
                                            }
                                            {diseaseAccess?.viewAccess && 
                                            <td>
                                                <Link to={`/disease-management/view/${disease?._id}`}>
                                                    <i className="cursor-pointer fa fa-eye text-info"></i>
                                                </Link>
                                            </td>
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="text-center">
                        <Pagination showSizeChanger={false} current={pageNo} onChange={handlePagination} total={apiData.total ? apiData.total : 1} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default DiseaseManagement;