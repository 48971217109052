import ApiInstance from "../../config/intercepter";
import Api from "../../config/api";

// view Role
export function contentListOrg(pageNo, pageLimit, sKey, filter, sort) {
  return ApiInstance.get(`${Api.listOrgContent}?page=${pageNo}&limit=${pageLimit}&sKey=${sKey}&sortValue=${filter}&dir=${sort}`);
}

export function addContentOrg(payload) {
  return ApiInstance.post(`${Api.addOrgContent}`, payload);
}

export function deleteContentOrg(payload) {
  return ApiInstance.delete(`${Api.deleteOrgContent}/${payload}`);
}

export function viewContentOrg(payload) {
  return ApiInstance.get(`${Api.viewOrgContent}/${payload}`);
}


export function updateContentOrg(payload) {
  return ApiInstance.put(`${Api.updateOrgContent}`, payload);
}

export function orgStatusApiOrg(payload) {
  return ApiInstance.patch(`${Api.orgContentStatus}`, payload)
}

export function sendForApproval(payload) {
  return ApiInstance.patch(`${Api.orgContentSendApproval}`, payload)
}