import { userConstants } from '../_constants';
// import { alertActions } from './';
import { history } from '../helper/history';
import { organizationLoginService } from '../services/authService';


export function loginAction(payload) {
    return dispatch => {
        dispatch(request(payload));
        return organizationLoginService(payload)
            .then(
                data => {
                    if (data?.statusCode === 1) {
                        dispatch(success(data?.responseData));
                    }else{
                        dispatch(failure(data.error));
                    }
                    return data;
                },
                error => {
                    if (error?.statusCode === 0) {
                        dispatch(failure(error));
                        console.log("error---->", error)
                    }
                    // dispatch(alertActions.error(error.toString()));
                    return error;
                }
            )
        // .catch(err => {
        //     dispatch(failure(err));
        // })
    };

    function request(data) { return { type: userConstants.LOGIN_REQUEST, data } }
    function success(data) { return { type: userConstants.LOGIN_SUCCESS, data } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

