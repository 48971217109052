import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Loader } from "../../components/util/Svg";
import Moment from "react-moment";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import { Pagination, Select, Radio, Modal } from "antd";
import { useSelector } from "react-redux";
import {
  deleteVideoApiFun,
  videoListApiFun,
  videoListApiFunSecondary,
  updateVideoStatusFun
} from "../../services/videos";
import { categorieListApiFun } from "../../services/categories";
const { Option } = Select;
const { confirm } = Modal;
const options = [
  { label: "Declined", value: 2 },
  { label: "Approved", value: 1 },
];

const VideosManagement = (props) => {
  const access = useSelector((state) => state.users?.user?.org?.roles ?? []);
  const videoAccess = access.find((role) => role.sectionName === "Videos");
  let history = useHistory();
  const [apiData, setApiData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [sortValue, setSortValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [searchStatus, setSearchStatus] = useState(false);
  const [byAccenDecc, setByAccenDecc] = useState(0);
  const [sortByTitle, setSortByTitle] = useState(false);
  const [sortByFormat, setSortByFormat] = useState(false);
  const [sortByDate, setSortByDate] = useState(false);
  const [selectType, setSellectType] = useState(0);
  // const secondaryAdmin = useSelector(state => state.users?.user?.org?.admin._id ?? "");
  const secondaryAdmin = useSelector(
    (state) => state.users?.user?.org?._id ?? ""
  );
  const type = props.type;
  const physicalId = "64a94c735a2d760019ab6071";
  const psychologicalId = "64a94c7a5a2d760019ab607d";
  const idByType = type === "Physical" ? physicalId : psychologicalId;

  useEffect(() => {
    listContent(
      pageNo,
      pageLimit,
      searchKey,
      sortValue,
      byAccenDecc,
      selectType
    );
  }, []);

  // list videos
  const listContent = (
    pageNo,
    pageLimit,
    searchKey,
    sortValue,
    byAccenDecc,
    selectType
  ) => {
    const apiFun = secondaryAdmin ? videoListApiFunSecondary : videoListApiFun;
    const params = secondaryAdmin
      ? {
          pageNo,
          pageLimit,
          searchKey,
          sortValue,
          byAccenDecc,
          selectType,
          secondaryAdmin,
        }
      : { pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType };

    apiFun(params)
      .then((res) => {
        if (res?.statusCode === 1) {
          categorieListApiFun(1, 100, "", "", 0, 0)
            .then((catRes) => {
              if (catRes?.statusCode === 1) {
                const allCategories = catRes?.responseData?.categories;
                const mainCategory = allCategories?.find(
                  (cat) => cat.name === type || cat._id === idByType
                );
                const gatherSubCategories = (categoryId, categories) => {
                  let subCategories = categories.filter(
                    (cat) => cat.parent?._id === categoryId
                  );
                  subCategories.forEach((subCat) => {
                    subCategories = subCategories.concat(
                      gatherSubCategories(subCat._id, categories)
                    );
                  });
                  return subCategories;
                };

                if (mainCategory) {
                  const allRelatedCategories = [mainCategory].concat(
                    gatherSubCategories(mainCategory._id, allCategories)
                  );
                  const categoryIds = allRelatedCategories.map(
                    (cat) => cat._id
                  );
                  const filteredData = res?.responseData.videos.filter((item) => {
                    const isCategoryMatch = categoryIds.includes(item.category._id);
                    const isStatusMatch = videoAccess?.roleName === 'SuperAdmin' ? item.status === 1 : true;
                    return isCategoryMatch && isStatusMatch;
                  });
                  setApiData(filteredData);
                } else {
                  setApiData([]);
                }
              } else {
                console.error("Error fetching categories");
              }
              setLoader(false);
              setSearchStatus(false);
            })
            .catch((err) => {
              console.error("Error fetching categories", err);
              setLoader(false);
              setSearchStatus(false);
            });
        } else if (
          res?.error?.errorCode === 46 ||
          res?.error?.errorCode === 2
        ) {
          localStorage.clear();
          history.push("/login");
        } else {
          setLoader(false);
          setSearchStatus(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoader(false);
        setSearchStatus(false);
      });
  };

  const sendForApprovalFun = (id) => {
    let params = {
        trainerId: id,
        status: 3,
    }
    updateVideoStatusFun(id,params)
        .then((res) => {
            if (res?.statusCode === 1) {
                listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
            }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                localStorage.clear()
                history.push("/login");
            }
        })
        .catch((err) => {
            console.log('err', err);
        })
}

  // // organization status
  // const organizationStatus = (status, id) => {
  //     let params = {
  //         contentId: id,
  //         isActiveOrg: status.target.checked === true ? 1 : 0,
  //     }
  //     orgStatusApiFunction(params)
  //         .then(res => {
  //             if (res?.statusCode === 1) {
  //                 listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
  //             }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
  //                 localStorage.clear()
  //                 history.push("/login");
  //             }
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //         })
  // }

  // individual status
  // const individualStatus = (status, id) => {
  //     let params = {
  //         contentId: id,
  //         isActiveIvd: status.target.checked === true ? 1 : 0,
  //     }
  //     individualStatusApiFunction(params)
  //         .then(res => {
  //             if (res?.statusCode === 1) {
  //                 listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
  //             }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
  //                 localStorage.clear()
  //                 history.push("/login");
  //             }
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //         })
  // }

  // delete videos
  const handleDelete = (id) => {
    confirm({
      title: "Do you Want to delete this Admin?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        setLoader(true);
        deleteVideoApiFun(id)
          .then((res) => {
            if (res?.statusCode === 1) {
              if (apiData?.length === 1) {
                let newPageNo = pageNo - 1;
                listContent(
                  newPageNo,
                  pageLimit,
                  searchKey,
                  sortValue,
                  byAccenDecc,
                  selectType
                );
              } else {
                listContent(
                  pageNo,
                  pageLimit,
                  searchKey,
                  sortValue,
                  byAccenDecc,
                  selectType
                );
              }
            } else if (
              res?.error?.errorCode === 46 ||
              res?.error?.errorCode === 2
            ) {
              localStorage.clear();
              history.push("/login");
            }
            setLoader(false);
          })
          .catch((err) => {
            console.log("error", err);
            setLoader(false);
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  //
  const editContent = (id, videos) => {
    const basePath = type === 'Physical' ? '/physical-videos/edit/' : '/psychological-videos/edit/';
history.push(`${basePath}${id}`, videos, "Edit");
  };

  // handle pagination
  const handlePagination = (page) => {
    listContent(page, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
  };

  const handleSearch = (searchEvent) => {
    setPageNo(1);
    setSearchStatus(true);
    let searchKey = searchEvent.target.value;
    setSearchKey(searchKey);
    if (searchKey.length > 1) {
      listContent(
        pageNo,
        pageLimit,
        searchKey,
        sortValue,
        byAccenDecc,
        selectType
      );
    } else {
      listContent(
        pageNo,
        pageLimit,
        searchKey,
        sortValue,
        byAccenDecc,
        selectType
      );
    }
  };

  const handleSort = (dir, field, byTitle, format, byDate) => {
    setLoader(true);
    setPageNo(1);
    setSortByTitle(byTitle);
    setSortByDate(byDate);
    setSortByFormat(format);
    setByAccenDecc(dir);
    setSortValue(field);
    listContent(pageNo, pageLimit, searchKey, field, dir, selectType);
  };

  const handleType = (value) => {
    setSellectType(value);
    listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, value);
  };

  // const onChangeStatus = (event, id) => {
  //     let params = {
  //         contentId: id,
  //         status: event.target.value,
  //     }
  //     statusFunction(params)
  //         .then(res => {
  //             if (res?.statusCode === 1) {
  //                 listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
  //             }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
  //                 localStorage.clear()
  //                 history.push("/login");
  //             }
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //         })
  // };

  return (
    <>
      {loader ? (
        <div className="loader_lg">
          <Loader />
        </div>
      ) : (
        ""
      )}
      <div className="content-route">
        <div className="card mb-3">
          <div className="page-header">
            <h2 className="page-title mb-0">Videos</h2>
          </div>
        </div>
        <div className="card  px-3 pb-3">
          <div className="my-4">
            <div className="row justify-content-between ">
              <div className="col-md-5">
                <div className="search">
                  {searchStatus ? (
                    <span className="search-spiner d-flex align-items-center">
                      <LoadingOutlined style={{ color: "#00BFFF" }} />
                    </span>
                  ) : (
                    ""
                  )}
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </div>
              {videoAccess?.addAccess && (
                <div className="col-md-5 text-end d-flex align-items-center justify-content-end">
                  <Link
                    to={
                      type === "Physical"
                        ? "/physical-videos/add-new"
                        : "/psychological-videos/add-new"
                    }
                    className="btn btn-primary ml-3"
                  >
                    Add New
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Title</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Title Editor</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Organization</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Created At</span>
                    </div>
                  </th>
                  {videoAccess?.roleName != "SuperAdmin" && (
                    <th>Send for Approval</th>
                  )}
                  {videoAccess?.roleName != "SuperAdmin" && (
                    <th>Approval Status</th>
                  )}
                  {/* <th>Block/Unblock</th> */}
                  <th colSpan="3">Action</th>
                </tr>
              </thead>
              <tbody>
                {apiData?.map((video, index) => (
                  <tr key={index}>
                    <td>{pageLimit * (pageNo - 1) + (index + 1)}</td>
                    <td>{video?.title}</td>
                    <td>{video?.category?.name}</td>
                    <td>
                      {video?.organizationName ?? "No Associated Organization"}
                    </td>
                    <td>
                      <Moment format="DD/MM/YYYY">{video?.created}</Moment>
                    </td>
                    {videoAccess?.roleName != "SuperAdmin" && (
                      <td>
                        {video?.status === 0 ? (
                          <button
                            className="btn btn-success"
                            onClick={() =>
                              sendForApprovalFun(video?._id)
                            }
                          >
                            Send for Approval
                          </button>
                        ) : (
                          <div className="form-check form-switch">
                            <input
                              disabled={
                                video?.status === 2 || video?.status === 3
                                  ? true
                                  : false
                              }
                              checked={video?.isActive ? true : false}
                            //   onChange={(e) =>
                            //     organizationStatus(e, assessment?._id)
                            //   }
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                            />
                            {/* <label className="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label> */}
                          </div>
                        )}
                      </td>
                    )}
                    {videoAccess?.roleName != "SuperAdmin" && (
                      <td>
                        <span
                          className={`badge p-1 
                                                ${
                                                  video?.status === 0
                                                    ? "bg-secondary text-light"
                                                    : video?.status === 1
                                                    ? "text-light bg-success"
                                                    : video?.status === 2
                                                    ? "text-light bg-danger"
                                                    : video?.status === 3
                                                    ? "text-light bg-info"
                                                    : ""
                                                }`}
                        >
                          {video?.status === 0
                            ? "No Request send"
                            : video?.status === 1
                            ? "Verified"
                            : video?.status === 2
                            ? "Declined"
                            : video?.status === 3
                            ? "Waiting for Approval"
                            : ""}
                        </span>
                      </td>
                    )}
                    {/* <td>
                                                <div className="form-check form-switch">
                                                    <input checked={video?.isEnable ? true : false} onChange={(e) => changeStatus(e, video?._id)} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                    {/* <label className="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label> */}
                    {/* </div> */}
                    {/* // </td> */}

                    {videoAccess?.deleteAccess && (
                      <td>
                        <i
                          className="cursor-pointer fa fa-trash text-danger"
                          onClick={() => handleDelete(video?._id)}
                        ></i>
                      </td>
                    )}

                    {videoAccess?.updateAccess && (
                      <td>
                        <i
                          className="cursor-pointer fa fa-edit text-primary"
                          onClick={() => editContent(video?._id, video)}
                        ></i>
                      </td>
                    )}

                    {videoAccess?.viewAccess && (
                      <td>
                        <Link to={`/videos/view/${video?._id}`}>
                          <i className="cursor-pointer fa fa-eye text-info"></i>
                        </Link>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="text-center">
            <Pagination
              showSizeChanger={false}
              current={pageNo}
              onChange={handlePagination}
              total={apiData.total ? apiData.total : 1}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default VideosManagement;
