import React, { useState, useEffect } from "react";
import { ExclamationCircleOutlined, EditOutlined, SaveOutlined , LineChartOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useSelector } from 'react-redux';
import { updateOrganizationCrisisLineFun } from "../../services/organizationService";
import { useHistory } from 'react-router-dom';

const { confirm } = Modal;

const CrisisLine = () => {
  let history = useHistory();
  const orgId = useSelector(state => state.users?.user?.org?._id ?? []);
  const existingCrisisLine = useSelector(state => state.users?.user?.org?.crisisLine ?? "");
  const existingCrisisLineDescription = useSelector(state => state.users?.user?.org?.crisisLineDescription ?? "");
  const orgName = useSelector(state => state.users?.user?.org?.orgName ?? "");
  
  const [isEditing, setIsEditing] = useState(!existingCrisisLine);
  const [crisisLine, setCrisisLine] = useState(existingCrisisLine);
  const [crisisLineDescription, setCrisisLineDescription] = useState(existingCrisisLineDescription);

  // Use useEffect to ensure the state is synchronized with the existing crisis line and description
  useEffect(() => {
    setIsEditing(!existingCrisisLine);
    setCrisisLine(existingCrisisLine);
    setCrisisLineDescription(existingCrisisLineDescription);
  }, [existingCrisisLine, existingCrisisLineDescription]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCrisisLineChange = (event) => {
    setCrisisLine(event.target.value);
  };

  const handleCrisisLineDescriptionChange = (event) => {
    setCrisisLineDescription(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (crisisLine.trim() === "") {
      confirm({
        title: "Crisis Line Number Required",
        icon: <ExclamationCircleOutlined />,
        content: "Please enter a crisis line number to proceed.",
        okText: "OK",
        cancelButtonProps: {
          style: {
            display: "none",
          },
        },
      });
      return;
    } else {
      const params = {
        organizationId: orgId,
        crisisLine: crisisLine.trim(),
        crisisLineDescription: crisisLineDescription.trim(),
      };
      console.log(params);
      updateOrganizationCrisisLineFun(params)
        .then(res => {
          if (res?.statusCode === 1) {
            confirm({
              title: "Success !",
              icon: <ExclamationCircleOutlined />,
              content: "Crisis Line Number and Description Updated Successfully!",
              okText: "OK",
              cancelButtonProps: {
                style: {
                  display: "none",
                },
              },
            });
            setIsEditing(false); // disable editing after successful update
          } else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
            localStorage.clear();
            history.push("/login");
          }
          console.log(res);
        })
        .catch(err => {
          console.log("error", err);
        });
    }
  };

  return (
    <div className="content-route">
      <div className="card mb-3">
        <div className="page-header">
          <h2 className="page-title mb-0 d-inline-flex align-items-center"> <LineChartOutlined className="me-2"/> Crisis Line</h2>
        </div>
      </div>
      <div className="card px-3 pb-3">
        {isEditing ? (
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label
                htmlFor="crisisLineInput"
                className="form-label fs-5 fw-bold"
              >
                Enter the Crisis Line Number:
              </label>
              <input
                type="text"
                className="form-control"
                id="crisisLineInput"
                value={crisisLine}
                onChange={handleCrisisLineChange}
                placeholder="e.g., 1-800-123-4567"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="crisisLineDescriptionInput"
                className="form-label fs-5 fw-bold"
              >
                Enter the Crisis Line Description:
              </label>
              <textarea
                className="form-control"
                id="crisisLineDescriptionInput"
                value={crisisLineDescription}
                onChange={handleCrisisLineDescriptionChange}
                placeholder="e.g., This line is available 24/7 for mental health support."
              />
            </div>
            <button type="submit" className="btn btn-primary mt-3 d-inline-flex align-items-center">
              <SaveOutlined className="me-2"/> Submit
            </button>
          </form>
        ) : (
          <div>
            <p className="fs-5 fw-bold">
              {orgName}'s Crisis Line Number: {crisisLine}
            </p>
            <p className="fs-5 fw-bold">
              Crisis Line Description: {crisisLineDescription}
            </p>
            <button onClick={handleEditClick} className="btn btn-primary mt-3 d-inline-flex align-items-center">
              <EditOutlined className="me-2"/> Edit
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CrisisLine;
