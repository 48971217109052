import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import LogoImg from '../assets/img/logo.svg';
import { Loader } from '../components/util/Svg';
import { setUserToken } from '../helper/uitility';
import { organizationLoginApiFun } from '../services/organizationService';
import { useDispatch } from 'react-redux';
import { loginAction, userActions } from './../_actions';

const Login = props => {
    let history = useHistory()
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [isValidForm, setIsValidForm] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        setLoader(true);
        let params = {
            email: data.email,
            password: data.password
        }

        dispatch(loginAction(params))
            .then((data) => {
                let userType = localStorage.setItem("userType", data?.responseData?.org?.type ?? '')
                if (data?.statusCode === 1) {
                    const tempPassword = data?.responseData?.org?.temporaryPassword ?? ''
                    localStorage.setItem("tempPassword", tempPassword)
                    if (tempPassword) {
                        history.push(`/set-password?token=${data?.responseData?.accessToken}`)
                        setLoader(false);
                    } else {
                        setUserToken(data?.responseData?.accessToken);
                        history.push('/');
                        setLoader(false);
                    }
                }
                else {
                    setErrorMessage(data?.error?.responseMessage);
                    setIsValidForm(false);
                    setLoader(false);
                }

            })
            .catch(() => {
                setLoader(false);
            });


    }
    return (
        <div className="d-flex justify-content-between align-items-center auth_wrapper">
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-4 card p-5">
                        <div className="logo-wrapper">
                            <img src={LogoImg} alt="Whoop" className="auth_logo" />
                        </div>
                        <div className={`form-message text-center ${isValidForm ? "text-success" : "text-danger"}`}>{errorMessage}</div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    {...register('email', { required: true, pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/ })}
                                    className={`form-control ${errors.email ? 'is-invalid' : ''} `}
                                    placeholder="Enter your email" />
                                {errors.email && errors.email.type === "required" && <p className="input-error">Email is required.</p>}
                                {errors.email && errors.email.type === "pattern" && <p className="input-error">Invalid email address.</p>}
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input
                                    className={`form-control ${errors.password ? 'is-invalid' : ''} `}
                                    {...register('password', { required: true })}
                                    placeholder="Enter your password"
                                    type="password"
                                />
                                {errors.password && <p className="input-error">Password is required.</p>}
                                <div className="d-flex justify-content-end mt-4">
                                    <small>
                                        <Link to="/forgot-password">Forgot password?</Link>
                                    </small>
                                </div>
                            </div>

                            <div className="text-center mt-3">
                                <button type="submit" className="btn btn-primary rounded-pill pl-5 pr-5">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* set Password */}
        </div>
    )
}

// 


export default Login;