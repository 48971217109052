import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Loader } from '../../components/util/Svg';
import { viewOrganizationApiFun } from '../../services/organizationService';

const ViewOrganization = () => {
    let history = useHistory()
    const [apiData, setApiData] = useState();
    const [loader, setLoader] = useState(false);
    const { organizationId } = useParams()

    useEffect(() => {
        viewOrganizationApi();
    }, [])


    const viewOrganizationApi = () => {
        setLoader(true)
        viewOrganizationApiFun(organizationId)
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiData(res.responseData.org);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false)
            })
            .catch(err => {
                setLoader(false)
            })
    }
    return (
        <>
            {loader ? <div className="loader_lg">< Loader /></div > : ''}

            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Organisation / Details</h2>
                    </div>
                </div>
                <div className="card  px-3">
                    <div className="my-4">
                        <div className="row justify-content-between ">

                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <h3 className="sub-heading">Organisation</h3>
                                </div>
                                <div className="row border-bottom mb-3">
                                    <div className="col-md-4">
                                        <div className="detailed">
                                            <p className="">
                                                <span className="label d-block">Organisation Name: </span>
                                                <span className="value fw-bold">{apiData?.orgName}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="detailed">
                                            <p className="">
                                                <span className="label d-block">Email: </span>
                                                <span className="value fw-bold">{apiData?.email}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="detailed">
                                            <p className="">
                                                <span className="label d-block">Phone: </span>
                                                <span className="value fw-bold">{apiData?.phone}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="detailed">
                                            <p className="">
                                                <span className="label d-block">Updated At: </span>
                                                <span className="value fw-bold">
                                                    <Moment format="DD/MM/YYYY">
                                                        {apiData?.updated}
                                                    </Moment>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <h3 className="sub-heading">Organization Admin</h3>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="detailed">
                                            <p className="">
                                                <span className="label d-block">Admin Name: </span>
                                                <span className="value fw-bold">{`${apiData?.admin?.firstName} ${apiData?.admin?.lastName}`}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="detailed">
                                            <p className="">
                                                <span className="label d-block">Admin Email: </span>
                                                <span className="value fw-bold">{apiData?.admin?.email}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="detailed">
                                            <p className="">
                                                <span className="label d-block">Phone: </span>
                                                <span className="value fw-bold">{apiData?.admin?.phone}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewOrganization;