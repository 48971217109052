import ApiInstance from "../config/intercepter";
import Api from "../config/api";
import { resHandle } from "../helper";

// view Role
export function allCategories() {
  return ApiInstance.get(`${Api.viewCategories}`);
}

export function categorieListApiFun(pageNo, pageLimit, sKey, filter, sort, type) {
  return ApiInstance.get(`${Api.listCategories}?page=${pageNo}&limit=${pageLimit}&sKey=${sKey}&sortValue=${filter}&dir=${sort}&type=${type}`);
}

export function addCategorieApiFun(payload) {
  return ApiInstance.post(`${Api.addCategorie}`, payload);
}

export function deleteCategorieApiFun(payload) {
  return ApiInstance.delete(`${Api.deleteCategorie}/${payload}`);
}

export function viewCategorieApiFun(payload) {
  return ApiInstance.get(`${Api.viewCategorie}/${payload}`);
}


export function updateCategorieApiFun(id, payload) {
  return ApiInstance.put(`${Api.updateCategorie}/${id}`, payload);
}