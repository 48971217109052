import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import ForgotPassword from './auth/ForgotPassword';
import Login from './auth/Login';
import { getToken } from './helper/uitility';
import { useState } from 'react';
import ResetPassword from './auth/Passwords/ResetPassword';
import LayoutWrapper from './Layout/LayoutWrapper';
import SetPassword from './auth/Passwords/SetPassword';


// Admin private
const PrivateRoute = ({ component: Component, loggedIn, ...rest }) =>
  <Route {...rest} render={(props) => getToken() ? <Component {...props} /> : <Redirect to='/login' />}
  />

// public route
const PublicRoute = ({ component: Component, loggedIn, ...rest }) =>
  <Route {...rest} render={(props) => !getToken() ?
    <Component {...props} /> : getToken() && (localStorage.getItem('tempPassword') !== "" || localStorage.getItem('tempPassword') !== undefined) ? <Redirect to={`/reset-password?token=${localStorage.getItem('token')}`} /> : <Redirect to='/' />}
  />



const App = props => {
  const [state, setState] = useState(false);
  

  useEffect(() => {
    setState(!state);
  }, [])

  return (
    <Switch>
      <PublicRoute exact path='/login' {...props} component={Login} />
      <PublicRoute exact path='/forgot-password' {...props} component={ForgotPassword} />
      <PublicRoute exact path='/reset-password' {...props} component={ResetPassword} />
      <PublicRoute exact path='/set-password' {...props} component={SetPassword} />
      <PrivateRoute component={LayoutWrapper} /> 
    </Switch>
  )
}


export default App;