import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Loader } from '../../components/util/Svg';
import { viewContentApiFun, viewContentApiFunAdmin } from '../../services/contentService';
import { viewCategorieApiFun } from '../../services/categories';


const ViewCategory = (props) => {
    let history = useHistory()
    const [apiData, setApiData] = useState();
    // const [mediaURL, setMediaURL] = useState();
    const [loader, setLoader] = useState(false);
    const { categoryId } = useParams()
    // const [thumbnail, setThumbnail] = useState('');

    useEffect(() => {
        viewContentApi();
    }, [])

    const viewContentApi = () => {
        setLoader(true)
        viewCategorieApiFun(categoryId)
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiData(res.responseData.category);  

                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false)
            })
            .catch(err => {
                setLoader(false)
            })
    }

    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Title Editor / Details</h2>
                    </div>
                </div>
                <div className="card  px-3">
                    <div className="my-4">
                        <div className="row justify-content-between ">
                            <div className="col-md-8">
                                <div className="detailed">
                                    <p className="">
                                        <span className="label d-block">Name: </span>
                                        <span className="value fw-bold">{apiData?.name}</span>
                                    </p>
                                    <p className="">
                                        <span className="label d-block">Description: </span>
                                        <span className="value fw-bold">{apiData?.description}</span>
                                    </p>
                                    <p className="">
                                        <span className="label d-block">Position: </span>
                                        <span className="value fw-bold">{apiData?.position}</span>
                                    </p>
                                    <p className="">
                                        <span className="label d-block">Parent Name: </span>
                                        <span className="value fw-bold">{apiData?.parent?.name}</span>

                                    </p>
                                    <div className='mb-4'>
                                        <span className="label d-block">Icon: </span>
                                        <div className="media-preview">
                                            {
                                                <div className="image-preview">
                                                    {
                                                        <img src={`${apiData?.icon_url}`} className="w-100" />
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                   <div className='mb-4'>
                                        <span className="label d-block">Image: </span>
                                            <div className="media-preview">
                                                {
                                                    <div className="image-preview">
                                                        {
                                                            <img src={`${apiData?.path_url}`} className="w-100" />
                                                        }
                                                    </div>
                                                }
                                        </div>
                                   </div>
                                    <p className="">
                                        <span className="label d-block">Created At: </span>
                                        <span className="value fw-bold">
                                            <Moment format="DD/MM/YYYY">
                                                {apiData?.updated}
                                            </Moment>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewCategory;