import ApiInstance from "./../config/intercepter";
import Api from "./../config/api";
import { resHandle } from "../helper";

// view Role
export function contentListApiFun(pageNo, pageLimit, sKey, filter, sort, type) {
  return ApiInstance.get(`${Api.listContent}?page=${pageNo}&limit=${pageLimit}&sKey=${sKey}&sortValue=${filter}&dir=${sort}&type=${type}`);
}

export function contentListAdminApiFun(pageNo, pageLimit, sKey, filter, sort, type) {
  const url = `${Api.listAdminContent}?page=${pageNo}&limit=${pageLimit}&sKey=${sKey}&sortValue=${filter}&dir=${sort}&type=${type}`;
  return ApiInstance.get(url);
}


export function addContentApiFun(payload) {
  return ApiInstance.post(`${Api.addContent}/org`, payload);
}

export function deleteContentApiFun(payload) {
  return ApiInstance.delete(`${Api.deleteContent}/org/${payload}`);
}

// admin
export function viewContentApiFunAdmin(payload) {
  return ApiInstance.get(`${Api.viewContent}/org/${payload}`);
}

export function viewContentApiFun(payload) {
  return ApiInstance.get(`${Api.viewContent}/${payload}`);
}


export function updateContentApiFun(payload) {
  return ApiInstance.put(`${Api.updateContent}/org`, payload);
}

export function orgStatusApiFunction(payload) {
  return ApiInstance.patch(`${Api.organizationStatus}`, payload)
}
export function individualStatusApiFunction(payload) {
  return ApiInstance.patch(`${Api.individualStatus}`, payload);
}
export function statusFunction(payload) {
  return ApiInstance.patch(`${Api.contentStatus}`, payload);
}