import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Loader } from '../../components/util/Svg';
import { viewContentApiFun, viewContentApiFunAdmin } from '../../services/contentService';
import { viewDisease } from '../../services/diseases';


const ViewDisease = (props) => {
    let history = useHistory()
    const [apiData, setApiData] = useState();
    const [loader, setLoader] = useState(false);
    const { diseaseId } = useParams()

    useEffect(() => {
        getDisease();
    }, [])

    const getDisease = () => {
        setLoader(true)
        viewDisease(diseaseId)
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiData(res.responseData.disease);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false)
            })
            .catch(err => {
                setLoader(false)
            })
    }

    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Disease / Details</h2>
                    </div>
                </div>
                <div className="card  px-3">
                    <div className="my-4">
                        <div className="row justify-content-between ">
                            <div className="col-md-8">
                                <div className="detailed">
                                    <p className="">
                                        <span className="label d-block">Vaccine Name: </span>
                                        <span className="value fw-bold">{apiData?.vaccineName}</span>
                                    </p>
                                    
                                    <p className="">
                                        <span className="label d-block">Status: </span>
                                        <span className="value fw-bold">{apiData?.isEnable ? "Unblock" : "Block"}</span>
                                    </p>
                                    
                                    <p className="">
                                        <span className="label d-block">Created At: </span>
                                        <span className="value fw-bold">
                                            <Moment format="DD/MM/YYYY">
                                                {apiData?.updated}
                                            </Moment>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewDisease;