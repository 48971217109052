import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/util/Svg';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { addContentApiFun, updateContentApiFun, viewContentApiFun, viewContentApiFunAdmin } from '../../services/contentService';
import { addDisease, updateDisease, viewDisease } from '../../services/diseases';
import { useSelector } from 'react-redux';

const AddEditDisease = props => {
    const { diseaseId } = useParams()
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
        }
    });
    let history = useHistory()
    const [loader, setLoader] = useState(false);
    const [ImageUrl, setImageUrl] = useState([]);
    const [url, setURL] = useState("");
    const [pictureType, setPictureType] = useState('');
    const [fileData, setFileData] = useState(null);
    const [thumbnail, setThumbnail] = useState('');
    useEffect(() => {
        if (diseaseId) {
            viewDiseaseApi();
        }
    }, [])

    const viewDiseaseApi = () => {
        setLoader(true)
        viewDisease(diseaseId)
            .then(res => {
                if (res?.statusCode === 1) {
                    console.log('disease view ---- ', res?.responseData?.disease)
                    reset(res?.responseData?.disease)
                    
                } else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false)
            })
            .catch(err => {
                setLoader(false)
            })
    }


    // add edit content
    const onSubmit = (data) => {
        if (!diseaseId) {
            setLoader(true);
            addDisease(data)
                .then(res => {

                    if (res?.statusCode === 1) {
                        history.push('/disease-management');
                    } else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                        localStorage.clear()
                        history.push("/login");
                    }
                    setLoader(false);
                })
                .catch(err => {
                    console.log("error", err);
                    setLoader(false);
                })
        } else {
            updateDisease({...data, diseaseId: diseaseId})
                .then(res => {

                    if (res?.statusCode === 1) {
                        history.push('/disease-management');
                    } else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                        localStorage.clear()
                        history.push("/login");
                    }
                    setLoader(false);
                })
                .catch(err => {
                    console.log("error", err);
                    setLoader(false);
                })
        }

    }
    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Disease / {diseaseId ? "Edit" : "Add New"}</h2>
                    </div>
                </div>
                <form className="card px-3 pb-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="my-4">
                        <div className="row justify-content-between flex-column">
                            <div className="col-md-5">
                                <div className="mb-4">
                                    <label>Vaccine Name</label>
                                    <input
                                        {...register('vaccineName', { required: true })}
                                        className={`form-control ${errors.vaccineName ? 'is-invalid' : ''} `} type="text" placeholder="Content Title" />
                                    {errors.vaccineName && errors.vaccineName.type === "required" && <p className="input-error">Vaccine Name is required.</p>}
                                </div>
                            </div>

                            <div className="col-md-5">
                                <div className="mb-4">
                                    <label>Document Type</label>
                                    <select className={`form-control ${errors.docType ? 'is-invalid' : ''} `}
                                        {...register('docType', { required: true })}
                                    // defaultValue={props?.history?.location?.state?.roleId}
                                    >
                                        <option value="" disabled selected>Document Type</option>

                                        <option value="0">No Cretificate required</option>
                                        <option value="1">Cretificate required</option>
                                        <option value="2">For Imunity certificate</option>

                                    </select>
                                    {errors.docType && errors.docType.type === "required" && <p className="input-error">Document type is required.</p>}
                                </div>
                            </div>

                            <div className="col-md-5">
                                <div className="mb-4">
                                    <label>Vaccine Type</label>
                                    <select className={`form-control ${errors.vaccineType ? 'is-invalid' : ''} `}
                                        {...register('vaccineType', { required: true })}
                                    // defaultValue={props?.history?.location?.state?.roleId}
                                    >
                                        <option value="" disabled selected>Vaccine Type</option>

                                        <option value="1">QR CODE</option>
                                        <option value="2">ONLY IMAGE</option>
                                        <option value="3">NONE</option>

                                    </select>
                                    {errors.vaccineType && errors.vaccineType.type === "required" && <p className="input-error">Vaccine type is required.</p>}
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddEditDisease


