import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import LogoImg from '../assets/img/logo.svg';
import { Loader } from '../components/util/Svg';
import { organizationForgotPasswordApiFun } from '../services/organizationService';


const ForgotPassword = () => {
    let history = useHistory()
    const [loader, setLoader] = useState('');
    const [isValidForm, setIsValidForm]= useState(true);
    const [errorMessage, setErrorMessage]= useState('');
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        setLoader(true);
        let params = {
            email: data.email,
        }
        organizationForgotPasswordApiFun(params)
            .then(res => {
                if (res?.statusCode === 1) {
                    localStorage.setItem('tempForgotPasswordToken', res?.responseData?.token);
                    setErrorMessage(res?.responseData?.message)
                }
                else{
                    setErrorMessage(res?.error?.responseMessage);
                    setIsValidForm(false);
                }
                setLoader(false);
            })
            .catch(err => {
                console.log("error", err);
                setLoader(false);
            })
    }


    return (
        <div className="d-flex justify-content-between align-items-center auth_wrapper">
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-4 card p-5">
                        <div className="logo-wrapper">
                            <img src={LogoImg} alt="Whoop" className="auth_logo" />
                        </div>
                        <div className={`form-message text-center ${isValidForm?"text-success":"text-danger"}`}>{errorMessage}</div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    {...register('email', { required: true, pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/ })}
                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                    placeholder="Enter your email" />
                                {errors.email && errors.email.type === "required" && <p className="input-error">Email is required.</p>}
                                {errors.email && errors.email.type === "pattern" && <p className="input-error">Invalid email address.</p>}
                            </div>

                            <div className="text-center mt-3">
                                <button type="submit" className="btn btn-primary rounded-pill pl-5 pr-5">Submit</button>
                            </div>
                        </form>
                        <div className="d-flex justify-content-center mt-4">
                            <small>
                                <Link to="/login">Back to login</Link>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;