import ApiInstance from "../config/intercepter";
import Api from "../config/api";
import { resHandle } from "../helper";

// view Role
export function videoListApiFun(params) {
  return ApiInstance.get(
    `${Api.listVideos}?page=${params.pageNo}&limit=${params.pageLimit}&sKey=${params.searchKey}&sortValue=${params.sortValue}&dir=${params.byAccenDecc}&type=${params.selectType}`
  );
}

export function videoListApiFunSecondary(params) {
  return ApiInstance.get(
    `${Api.listVideosSecondary}?page=${params.pageNo}&limit=${params.pageLimit}&sKey=${params.searchKey}&sortValue=${params.sortValue}&dir=${params.byAccenDecc}&type=${params.selectType}&secondaryAdmin=${params.secondaryAdmin}`
  );
}

export function addVideoApiFun(payload) {
  return ApiInstance.post(`${Api.addVideo}/`, payload);
}
export function addVideoApiFunSecondary(payload) {
  return ApiInstance.post(`${Api.addVideoSecondary}/`, payload);
}


export function deleteVideoApiFun(payload) {
  return ApiInstance.delete(`${Api.deleteVideo}/${payload}`);
}

export function viewVideoApiFun(payload) {
  return ApiInstance.get(`${Api.viewVideo}/${payload}`);
}

export function updateVideoApiFun(id, payload) {
  return ApiInstance.put(`${Api.updateVideo}/${id}`, payload);
}

export function updateVideoStatusFun(id, payload) {
  const url = `${Api.updateVideoStatus}/${id}`;
  return ApiInstance.put(`${Api.updateVideoStatus}/${id}`, payload);
}
