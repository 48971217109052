import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/util/Svg';
import Moment from 'react-moment';
import { BarChartOutlined, LoadingOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Pagination, Select } from 'antd';
import { EmployeeListService, employeeStatusService } from '../../services/organization/employee';
import { useHistory } from 'react-router-dom';

const Employee = props => {
    let history = useHistory()
    const [apiData, setApiData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [pageLimit, setPageLimit] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const [sortValue, setSortValue] = useState(1);
    const [searchKey, setSearchKey] = useState("");
    const [searchStatus, setSearchStatus] = useState(false);
    const [byAccenDecc, setByAccenDecc] = useState("1");
    const [sortCode, setSortCode] = useState(false);
    const [sortEmail, setSortEmail] = useState(false);
    const [sortRegister, setSortRegister] = useState(false);
    const [sortByCreated, setSortCreated] = useState(false);


    useEffect(() => {
        listEmployee(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
    }, [])

    // list content
    const listEmployee = (pageNo, pageLimit, searchKey, sortValue, byAccenDecc) => {
        EmployeeListService(pageNo, pageLimit, searchKey, sortValue, byAccenDecc)
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiData(res?.responseData);
                    setPageNo(pageNo);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false);
                setSearchStatus(false);
            })
            .catch(err => {
                console.log("employee error", err);
                setLoader(false)
                setSearchStatus(false);
            })
    }

    // individual status
    const employeeStatus = (status, id) => {
        let params = {
            contentId: id,
            isActiveIvd: status.target.checked === true ? 1 : 0,
        }
        employeeStatusService(params)
            .then(res => {
                if (res?.statusCode === 1) {
                    listEmployee(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
                    
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }


    // handle pagination
    const handlePagination = page => {
        listEmployee(page, pageLimit, searchKey, sortValue, byAccenDecc);
    };

    const handleSearch = searchEvent => {
        setPageNo(1);
        setSearchStatus(true)
        let searchKey = searchEvent.target.value;
        setSearchKey(searchKey);
        if (searchKey.length > 1) {
            listEmployee(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
        } else {
            listEmployee(pageNo, pageLimit, '', sortValue, byAccenDecc);
        }
    }


    const handleSort = (dir, field, code, email,register, created) => {
        setLoader(true)
        setPageNo(1)
        setSortCode(code);
        setSortEmail(email);
        setSortRegister(register);
        setSortCreated(created);
        setByAccenDecc(dir)
        setSortValue(field)
        listEmployee(pageNo, pageLimit, searchKey, field, dir);
    }




    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Employee</h2>
                    </div>
                </div>
                <div className="row organization-dashboard-card mb-3">
                    <div className="col-md-4">
                        <div className="card text-center p-3">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="text-center">
                                    <BarChartOutlined style={{ fontSize: "40px" }} className="mb-3" />
                                    <h4 className="">Total Code <br />{apiData?.totalCode ?? "0"}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card text-center p-3">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="text-center">
                                    <BarChartOutlined style={{ fontSize: "40px" }} className="mb-3" />
                                    <h4 className="">Unused Code <br />{apiData?.usedCode ?? "0"}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card text-center p-3">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="text-center">
                                    <BarChartOutlined style={{ fontSize: "40px" }} className="mb-3" />
                                    <h4 className="">Used Code <br />{apiData?.unusedCode ?? "0"}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card  px-3 pb-3">
                    <div className="my-4">
                        <div className="row justify-content-between ">
                            <div className="col-md-5">
                                <div className="search">
                                    {
                                        searchStatus ?
                                            <span className="search-spiner d-flex align-items-center">
                                                <LoadingOutlined style={{ color: "#00BFFF" }} />
                                            </span>
                                            : ""
                                    }
                                    <input className="form-control" type="text" placeholder="Search..." onChange={(e) => handleSearch(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Employee Code</span>
                                            {/* {
                                                sortCode === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "2", false, false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "2", true, false, false, false)} />
                                            } */}
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Email</span>
                                            {/* {
                                                sortEmail === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "3", false, false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "3", false, true, false, false)} />
                                            } */}
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Register On</span>
                                            {/* {
                                                sortRegister === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "4", false, false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "4", false, false, true, false)} />
                                            } */}
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Created At</span>
                                            {/* {
                                                sortByCreated === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "1", false, false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "1", false, false, false, true)} />
                                            } */}
                                        </div>
                                    </th>
                                    <th>Block/Unblock</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    apiData?.employees?.map((employee, index) => (
                                        <tr key={index}>
                                            <td>{pageLimit *(pageNo -1) +(index+1)}</td>
                                            <td>{employee?.code}</td>
                                            <td>{employee?.email}</td>
                                            <td>
                                                <Moment format="DD/MM/YYYY">
                                                    {employee?.register}
                                                </Moment>
                                            </td>
                                            <td>
                                                <Moment format="DD/MM/YYYY">
                                                    {employee?.created}
                                                </Moment>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch">
                                                    <input checked={employee?.status ? true : false} onChange={(e) => employeeStatus(e, employee?._id)} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                    {/* <label className="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label> */}
                                                </div>
                                                {/* <Switch defaultChecked={content?.isActiveOrg ? true : false}
                                                    onChange={(e) => organizationStatus(e, content?._id)}
                                                /> */}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="text-center">
                        <Pagination showSizeChanger={false} current={pageNo} onChange={handlePagination} total={apiData.total ? apiData.total : 1} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Employee;