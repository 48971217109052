import React, { useEffect, useState } from "react";
import { Loader } from "../../components/util/Svg";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  addTrainerApiFun,
  allTrainers,
  updateTrainerApiFun,
  viewTrainerApiFun,
} from "../../services/trainers";
import moment from "moment";
import { allCategories, categorieListApiFun } from "../../services/categories";

const AddEditTrainer = (props) => {
  const { trainerId } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  let history = useHistory();
  const [loader, setLoader] = useState(false);
  const [ImageUrl, setImageUrl] = useState([]);
  const [categoriesParentOptions, setCategoriesParentOptions] = useState([]);
  const [url, setURL] = useState("");
  const [pictureType, setPictureType] = useState("");
  const [fileData, setFileData] = useState(null);
  const type = props.addType;
  const filterCategoriesByType = (allCategories, type) => {
    // Find the main parent category based on the type
    const parentCategory = allCategories.find(
      (cat) => cat.name.toLowerCase() === type.toLowerCase() && !cat.parent
    );
  
    if (parentCategory) {
      let specificSubCategory = null;
  
      if (type.toLowerCase() === "physical") {
        specificSubCategory = allCategories.find(
          (cat) => cat.name === "Online Trainers" && cat.parent?._id === parentCategory._id
        );
      } else if (type.toLowerCase() === "psychological") {
        specificSubCategory = allCategories.find(
          (cat) => cat.name === "Online Psychologists" && cat.parent?._id === parentCategory._id
        );
      }
  
      if (specificSubCategory) {
        return [{
          label: specificSubCategory.name,
          value: specificSubCategory._id,
        }];
      }
    }
  
    // Return an empty array if no matching parent category or specific subcategory is found
    return [];
  };
  
  useEffect(() => {
    if (trainerId) {
      viewTrainerApi();
    } else {
      setLoader(true);
      categorieListApiFun(1, 100, "", "", 0, 0)
        .then((res) => {
          if (res?.statusCode === 1) {
            const allCategories = res?.responseData?.categories;
            const filteredCategories = filterCategoriesByType(
              allCategories,
              type
            );
            // Set the filtered categories as options
            setCategoriesParentOptions(
              filteredCategories[0].value
            );
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  }, [trainerId, type]);

  const viewTrainerApi = () => {
    setLoader(true);
    Promise.all([
      viewTrainerApiFun(trainerId),
      categorieListApiFun(1, 100, "", "", 0, 0),
    ])
      .then((res) => {
        if (res[0]?.statusCode === 1) {
          const data = res[0].responseData.trainer;
          setURL(data?.avatar_url);
          setValue("firstName", data?.firstName);
          setValue("lastName", data?.lastName);
          setValue("description", data?.info);
          setValue("email", data?.email);
          setValue("phone", data?.phone);
          setValue("categoryId", data?.categoryId);
          setValue("facebook", data?.facebook);
          setValue("instagram", data?.instagram);
          setValue("timezone", data?.timezone);
          setValue("language", data?.languages);
          if (res[1]?.statusCode === 1) {
            const allCategories = res[1]?.responseData?.categories;
            const filteredCategories = filterCategoriesByType(
              allCategories,
              type
            );
            // Set the filtered categories as options
            setCategoriesParentOptions(
              filteredCategories.map((catRes) => ({
                label: catRes?.label,
                value: catRes?._id,
              }))
            );
          } else if (
            res[1]?.error?.errorCode === 46 ||
            res[1]?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          }
        } else if (
          res[0]?.error?.errorCode === 46 ||
          res[0]?.error?.errorCode === 2
        ) {
          localStorage.clear();
          history.push("/login");
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  // add edit content
  const onSubmit = (data) => {
    if (!trainerId) {
      setLoader(true);
      let trainerFormData = new FormData();
      let mediaData = data.trainer[0];
      if (mediaData) {
        setImageUrl(URL.createObjectURL(mediaData));
        trainerFormData.append("media", mediaData);
      }
      trainerFormData.append("firstName", data?.firstName);
      trainerFormData.append("lastName", data?.lastName);
      trainerFormData.append("description", data?.description );
      trainerFormData.append("info", data?.description);
      trainerFormData.append("email", data?.email);
      trainerFormData.append("phone", data?.phone);
      trainerFormData.append("categoryId", categoriesParentOptions);
      trainerFormData.append("facebook", data?.facebook);
      trainerFormData.append("instagram", data?.instagram);
      trainerFormData.append("languages", data?.language);
      trainerFormData.append("timezone", data?.timezone);
      addTrainerApiFun(trainerFormData)
        .then((res) => {
          if (res?.statusCode === 1) {
            history.goBack();
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          }
          setLoader(false);
        })
        .catch((err) => {
          console.log("error", err);
          setLoader(false);
        });
    } else {
      let mediaData = data?.trainer[0];
      let trainerFormData = new FormData();
      if (mediaData !== undefined) {
        setImageUrl(URL.createObjectURL(mediaData));
        trainerFormData.append("media", mediaData);
      }
      if (data?.firstName) {
        trainerFormData.append("firstName", data?.firstName);
      }
      if (data?.lastName) {
        trainerFormData.append("lastName", data?.lastName);
      }
      if (data?.description) {
        trainerFormData.append("info", data?.description);
      }
      if (data?.email) {
        trainerFormData.append("email", data?.email);
      }
      if (data?.phone) {
        trainerFormData.append("phone", data?.phone);
      }
      if (data?.categoryId) {
        trainerFormData.append("categoryId", data?.categoryId);
      }
      if (data?.facebook) {
        trainerFormData.append("facebook", data?.facebook);
      }
      if (data?.language) {
        trainerFormData.append("languages", data?.language);
      }
      if (data?.timezone) {
        trainerFormData.append("timezone", data?.timezone);
      }
      updateTrainerApiFun(trainerId, trainerFormData)
        .then((res) => {
          if (res?.statusCode === 1) {
            history.goBack();
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          }
          setLoader(false);
        })
        .catch((err) => {
          console.log("error", err);
          setLoader(false);
        });
    }
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      let mediaType =
        e.target.files[0]?.type?.split("/")[0] === "application"
          ? "document"
          : e.target.files[0]?.type?.split("/")[0];
      setValue("contentFormat", mediaType);
      setPictureType(mediaType);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setFileData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleInputChanges = (event) => {
    const value = event.target.value;
    setValue("categoryId", value);
  };

  return (
    <>
      {loader ? (
        <div className="loader_lg">
          <Loader />
        </div>
      ) : (
        ""
      )}
      <div className="content-route">
        <div className="card mb-3">
          <div className="page-header">
            <h2 className="page-title mb-0">
              Online Professionals / {trainerId ? "Edit" : "Add New"}
            </h2>
          </div>
        </div>
        <form className="card px-3 pb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="my-4">
            <div className="row justify-content-between flex-column">
              <div className="col-md-5">
                <div className="mb-4">
                  <label>First Name</label>
                  <input
                    {...register("firstName", { required: true })}
                    className={`form-control ${
                      errors.firstName ? "is-invalid" : ""
                    } `}
                    type="text"
                    placeholder="First Name..."
                  />
                  {errors.firstName && errors.firstName.type === "required" && (
                    <p className="input-error">First Name is required.</p>
                  )}
                </div>
              </div>

              <div className="col-md-5">
                <div className="mb-4">
                  <label>Last Name</label>
                  <input
                    {...register("lastName", { required: false })}
                    className={`form-control ${
                      errors.lastName ? "is-invalid" : ""
                    } `}
                    type="text"
                    placeholder="Last Name..."
                  />
                  {errors.lastName && errors.lastName.type === "required" && (
                    <p className="input-error">Last Name is required.</p>
                  )}
                </div>
              </div>

              <div className="col-md-5">
                <div className="mb-4">
                  <label>Description</label>
                  <textarea
                    {...register("description", { required: true })}
                    className={`form-control ${
                      errors.description ? "is-invalid" : ""
                    } `}
                    placeholder="Trainer Description"
                    rows="4"
                  ></textarea>
                  {errors.description &&
                    errors.description.type === "required" && (
                      <p className="input-error">Description is required.</p>
                    )}
                </div>
              </div>


              <div className="col-md-5">
                <div className="mb-4">
                  <label>Email</label>
                  <input
                    {...register("email", { required: false })}
                    className={`form-control ${
                      errors.email ? "is-invalid" : ""
                    } `}
                    type="text"
                    placeholder="Email..."
                  />
                  {errors.email && errors.email.type === "required" && (
                    <p className="input-error">Email is required.</p>
                  )}
                </div>
              </div>

              <div className="col-md-5">
                <div className="mb-4">
                  <label>Phone Number</label>
                  <input
                    {...register("phone", { required: false })}
                    className={`form-control ${
                      errors.phone ? "is-invalid" : ""
                    } `}
                    type="text"
                    placeholder="Phone Number..."
                  />
                  {errors.phone && errors.phone.type === "required" && (
                    <p className="input-error">Phone Number is required.</p>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="mb-4">
                  <label>Facebook</label>
                  <input
                    {...register("facebook", { required: false })}
                    className={`form-control ${
                      errors.facebook ? "is-invalid" : ""
                    } `}
                    type="text"
                    placeholder="Facebook..."
                  />
                  {errors.facebook && errors.facebook.type === "required" && (
                    <p className="input-error">Facebook is required.</p>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="mb-4">
                  <label>Instagram</label>
                  <input
                    {...register("instagram", { required: false })}
                    className={`form-control ${
                      errors.instagram ? "is-invalid" : ""
                    } `}
                    type="text"
                    placeholder="Instagram..."
                  />
                  {errors.instagram && errors.instagram.type === "required" && (
                    <p className="input-error">Instagram is required.</p>
                  )}
                </div>
              </div>

              {/* Timezone Field */}
              <div className="col-md-5">
                <div className="mb-4">
                  <label>Timezone</label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.timezone ? "is-invalid" : ""
                    }`}
                    {...register("timezone", { required: true })}
                    value={watch("timezone") || ""}
                    placeholder="Enter Timezone"
                  />
                  {errors.timezone && errors.timezone.type === "required" && (
                    <p className="input-error">Timezone is required.</p>
                  )}
                </div>
              </div>

              {/* Language Field */}
              <div className="col-md-5">
                <div className="mb-4">
                  <label>Language</label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.language ? "is-invalid" : ""
                    }`}
                    {...register("language", { required: true })}
                    value={watch("language") || ""}
                    placeholder="Enter Language"
                  />
                  {errors.language && errors.language.type === "required" && (
                    <p className="input-error">Language is required.</p>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="mb-4">
                  <div className="media-preview mb-4">
                    {
                      <div className="image-preview">
                        {
                          fileData ? (
                            <img
                              src={
                                fileData?.split(":")[0] == "data"
                                  ? fileData
                                  : `${url}${fileData}`
                              }
                              className="w-100"
                            />
                          ) : url ? (
                            <img src={url} className="w-100" />
                          ) : null
                          // <img src={fileData} className="w-100" />
                        }
                      </div>
                    }
                  </div>
                  {trainerId ? (
                    <>
                      <label>Edit Media</label>
                      <input
                        {...register("trainer", { required: false })}
                        onChange={handleChange}
                        className={`form-control`}
                        type="file"
                        placeholder="Enter Email"
                      />
                    </>
                  ) : (
                    <>
                      <label>Upload Media</label>
                      <input
                        {...register("trainer", { required: false })}
                        onChange={handleChange}
                        className={`form-control ${
                          errors.trainer ? "is-invalid" : ""
                        } `}
                        type="file"
                        placeholder="Enter Email"
                      />
                      {/* {errors.trainer && errors.trainer.type === "required" && <p className="input-error">Media is required.</p>} */}
                    </>
                  )}
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEditTrainer;
