import React, { useState, useEffect } from "react";
import {
  EditOutlined,
  SaveOutlined,
  SmileOutlined,
  PlusOutlined,
  DeleteOutlined,
  NotificationFilled,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Modal, Card, Row, Col, Switch, DatePicker } from "antd"; // Added DatePicker from Ant Design
import moment from "moment"; // Import moment for date formatting
import { Loader } from "../../components/util/Svg";
import { useHistory } from "react-router-dom";
import { listEmailsBasedOnOrg, updateEmails } from "../../services/emails";

const { confirm } = Modal;

const SignUpMessages = () => {
  let history = useHistory();
  const [emails, setEmails] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const fetchEmailData = async () => {
      try {
        const res = await listEmailsBasedOnOrg();
        if (res) {
          setEmails(
            res?.responseData?.emails.map((email) => ({
              ...email,
              frequencyInterval: email.frequency.split(" ")[0] || 1, // Set default interval to 1
              frequencyType: email.frequency.split(" ")[1] || "Day", // Set default frequency type to 'Day'
              enabled: email.enabled || false, // Set default to false if missing
              date: email.date || null, // If there's a date, map it here
            }))
          );
          setLoader(false);
        }
      } catch (error) {
        console.error("Error fetching emails:", error);
        setLoader(false);
      }
    };

    fetchEmailData();
  }, []);

  const handleEditClick = (emailId) => {
    setIsEditing(emailId);
  };

  const handleInputChange = (event, emailId, field) => {
    setEmails((prevEmails) =>
      prevEmails.map((email) =>
        email._id === emailId
          ? { ...email, [field]: event.target.value }
          : email
      )
    );
  };

  const handleDateChange = (date, dateString, emailId) => {
    setEmails((prevEmails) =>
      prevEmails.map((email) =>
        email._id === emailId ? { ...email, date: dateString } : email
      )
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const email = emails.find((email) => email._id === isEditing);
    let frequency = email.frequency;
    if (frequency === "") {
      
    }
    else if (email.frequencyInterval && email.frequencyType) {
      frequency = `${email.frequencyInterval} ${email.frequencyType}`;
    }
    const payload = {
      content: email.content.trim(),
      frequency: frequency, // Frequency will be null if no valid values were provided
      enabled: email.enabled,
      date: email.date, // Include the date if it exists
    };
  
    updateEmails(email._id, payload)
      .then((res) => {
        confirm({
          title: "Success!",
          icon: <SmileOutlined />,
          content: "Messages Updated Successfully!",
          okText: "OK",
          cancelButtonProps: {
            style: { display: "none" },
          },
        });
        setIsEditing(null);
      })
      .catch((err) => {
        console.error("Error updating messages:", err);
      });
  };
  

  const handleRemoveFrequency = (emailId) => {
    setEmails((prevEmails) =>
      prevEmails.map((email) =>
        email._id === emailId
          ? {
              ...email,
              frequency: "",
              frequencyInterval: "",
              frequencyType: "",
            }
          : email
      )
    );
  };

  const handleToggleEnabled = async (emailId) => {
    // Find the email to toggle its enabled state
    const email = emails.find((email) => email._id === emailId);
    if (!email) {
      console.error("Email not found");
      return;
    }
  
    // Optimistically set the new state for faster UI feedback
    const newEnabled = !email.enabled;
    setEmails((prevEmails) =>
      prevEmails.map((email) =>
        email._id === emailId ? { ...email, enabled: newEnabled } : email
      )
    );
  
    // Prepare payload for backend update
    const payload = { enabled: newEnabled };
  
    try {
      // Wait for backend response
      await updateEmails(emailId, payload);
    } catch (err) {
  
      // If there's an error, revert the change
      setEmails((prevEmails) =>
        prevEmails.map((email) =>
          email._id === emailId ? { ...email, enabled: !newEnabled } : email
        )
      );
    }
  };
  

  return (
    <div className="content-route">
      <div
        className="card mb-3"
        style={{ maxWidth: "1200px", margin: "0 auto" }}
      >
        <div className="page-header">
          <h2
            className="page-title mb-0 d-inline-flex align-items-center"
            style={{ color: "#274c77" }}
          >
            <NotificationFilled className="me-2" style={{ color: "#274c77" }} />
            Notifications Management
          </h2>
        </div>
      </div>

      <div
        className="card px-3 pb-3"
        style={{ maxWidth: "1200px", margin: "0 auto" }}
      >
        {loader ? (
          <div className="loader_lg">
            <Loader />
          </div>
        ) : (
          <Row gutter={[16, 16]}>
            {emails.map((email) => (
              <Col xs={24} sm={12} md={8} key={email._id}>
                <Card
                  title={email.title}
                  extra={
                    <>
                      <EditOutlined
                        onClick={() => handleEditClick(email._id)}
                        style={{ cursor: "pointer", color: "#274c77" }}
                      />
                      <Switch
                        checked={email.enabled}
                        onChange={() => handleToggleEnabled(email._id)}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        style={{ marginLeft: 10 }}
                      />
                    </>
                  }
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                    height: "380px", // Ensure the card can expand if needed
                    overflow: "auto", // Enable scroll if content overflows
                  }}
                >
                  {isEditing === email._id ? (
                    <Section
                      message={email.content}
                      frequency={email.frequency}
                      isEditing={isEditing === email._id}
                      onInputChange={(e) =>
                        handleInputChange(e, email._id, "content")
                      }
                      onSubmit={handleSubmit}
                      frequencyInterval={email.frequencyInterval}
                      frequencyType={email.frequencyType}
                      setFrequencyInterval={(e) =>
                        handleInputChange(
                          { target: { value: e } },
                          email._id,
                          "frequencyInterval"
                        )
                      }
                      setFrequencyType={(e) =>
                        handleInputChange(
                          { target: { value: e } },
                          email._id,
                          "frequencyType"
                        )
                      }
                      hasFrequency={email.frequency && email.frequency !== "-"}
                      onRemoveFrequency={() =>
                        handleRemoveFrequency(email._id)
                      }
                      date={email.date}
                      setDate={(date, dateString) =>
                        handleDateChange(date, dateString, email._id)
                      }
                    />
                  ) : (
                    <>
                      <p>{email.content}</p>
                      {email.frequency && email.frequency !== "-" && (
                        <p>
                          <strong>Frequency: </strong> {email.frequency}
                        </p>
                      )}
                      {email.date && (
                        <p>
                          <strong>Date: </strong> {email.date}
                        </p>
                      )}
                    </>
                  )}
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </div>
    </div>
  );
};

const Section = ({
  message,
  isEditing,
  onInputChange,
  onSubmit,
  frequencyInterval,
  frequencyType,
  setFrequencyInterval,
  setFrequencyType,
  hasFrequency,
  onRemoveFrequency,
  date,
  setDate,
}) => {
  // The "showFrequencyFields" is now controlled by "hasFrequency"
  const [showFrequencyFields] = useState(hasFrequency);

  return (
    <form onSubmit={onSubmit}>
      <textarea
        className="form-control mb-2"
        value={message}
        onChange={onInputChange}
        style={{
          height: "150px", // Fixed height
          overflow: "auto", // Enable scroll if content exceeds height
          resize: "vertical", // Allow user to resize vertically if needed
        }}
      />

      {date && (
        <div className="mb-2">
          <label>Select Date:</label>
          <DatePicker
            value={moment(date, "D MMMM")}
            format="D MMMM"
            onChange={setDate}
            className="form-control"
            allowClear={false} // This removes the "X" button
          />
        </div>
      )}

      {showFrequencyFields ? (
        <div className="mb-2">
          <label>How frequently do you want this message to be sent?</label>
          <input
            type="number"
            value={frequencyInterval || ''} // Don't default to 1
            min="1"
            onChange={(e) => setFrequencyInterval(e.target.value)}
            className="form-control"
            placeholder="Enter number"
            style={{ width: "100px" }}
          />
          <select
            value={frequencyType || ''} // Don't default to 'day'
            onChange={(e) => setFrequencyType(e.target.value)}
            className="form-control mt-2"
            style={{ width: "120px" }}
          >
            <option value="">Select frequency type</option> {/* Blank option to handle no selection */}
            <option value="day">Day(s)</option>
            <option value="week">Week(s)</option>
            <option value="month">Month(s)</option>
          </select>
        </div>
      ) : null}

      <button
        type="submit"
        className="custom-btn btn mt-3"
        style={{
          backgroundColor: "#274c77",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          padding: "8px 16px",
          cursor: "pointer",
          display: "inline-flex",
          alignItems: "center",
          gap: "6px",
        }}
      >
        <SaveOutlined /> Save
      </button>
    </form>
  );
};


export default SignUpMessages;
