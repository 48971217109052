import ApiInstance from "../../config/intercepter";
import Api from "../../config/api";

export function CodeListService(pageNo, pageLimit, sKey, filter, sort) {
  return ApiInstance.get(
    `${Api.codeList}?page=${pageNo}&limit=${pageLimit}&sKey=${sKey}&sortValue=${filter}&dir=${sort}`
  );
}

export function orgCodeListService(pageNo, pageLimit, sKey, filter, sort) {
  return ApiInstance.get(
    `${Api.orgCodeList}?page=${pageNo}&limit=${pageLimit}&sKey=${sKey}&sortValue=${filter}&dir=${sort}`
  );
}

export function codeStatusService(payload) {
  return ApiInstance.put(`${Api.codeStatus}`, payload);
}

export function codeGenerate(payload) {
  return ApiInstance.post(`${Api.codeGenerate}`, payload);
}

export function codeGenerate1(payload) {
  return ApiInstance.post(`${Api.codeGenerate1}`, payload);
}

export function deleteCode(payload) {
  return ApiInstance.delete(`${Api.deleteCode}/${payload}`);
}

export function deletedSelectedCodes(payload) {
  return ApiInstance.delete(`${Api.deletedSelectedCodes}`, payload);
}
