import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export function users(state = initialState, action) {
    switch (action.type) {
        case userConstants.GETALL_REQUEST:
            return {
                loggingIn: true,
                user: action.data
            };
        case userConstants.GETALL_SUCCESS:
            return {
                loggedIn: true,
                user: action.data
            };
        case userConstants.GETALL_FAILURE:
            return {};
        case userConstants.LOGOUT:
            return {};
        default:
            return state
    }
}