import React, { useEffect, useState } from "react";
import { Loader } from "../../components/util/Svg";
import { useForm } from "react-hook-form";
import {
  addAdminApiFun,
  roleListAdmiApiFun,
  updateAdminListApiFun,
} from "../../services/adminService";
import { useHistory, useParams } from "react-router-dom";
import { Modal } from "antd";
import {
  addOrganizationApiFun,
  updateOrganizationApiFun,
} from "../../services/organizationService";

const AddEditOrganization = (props) => {
  const { organizationId } = useParams();
  const [message, setMessage] = useState("");
  let history = useHistory();
  const [loader, setLoader] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      orgPhone: props?.history?.location?.state?.phone,
      orgName: props?.history?.location?.state?.orgName,
      orgEmail: props?.history?.location?.state?.email,
      orgInitials: props?.history.location?.state?.orgInitials,
      adminFirstName: props?.history?.location?.state?.admin[0]?.firstName,
      adminLastName: props?.history?.location?.state?.admin[0]?.lastName,
      adminPhone: props?.history?.location?.state?.admin[0]?.phone,
      adminEmail: props?.history?.location?.state?.admin[0]?.email,
    },
  });

  const onSubmit = (data) => {
    setLoader(true);
    let adminDetails = {
      firstName: data.adminFirstName,
      lastName: data.adminLastName,
      phone: data.adminPhone,
      email: data.adminEmail,
    };
    let params = {
      orgName: data.orgName,
      phone: data.orgPhone,
      email: data.orgEmail,
      organizationId: organizationId,
      orgInitials: data.orgInitials,
      adminDetails: JSON.stringify(adminDetails),
    };
    if (!organizationId) {
      addOrganizationApiFun(params)
        .then((res) => {
          if (res?.statusCode === 1) {
            history.push("/organization");
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          } else {
            setMessage(message);
            error(res?.error?.responseMessage);
          }
          setLoader(false);
        })
        .catch((err) => {
          console.log("error", err);
          setLoader(false);
        });
    } else {
      updateOrganizationApiFun(params)
        .then((res) => {
          if (res?.statusCode === 1) {
            history.push("/organization");
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          }
        })
        .catch((err) => {
          console.log("error", err);
          setLoader(false);
        });
    }
  };

  function error(message) {
    Modal.error({
      title: "Error",
      content: message,
      centered: true,
    });
  }

  return (
    <>
      {loader ? (
        <div className="loader_lg">
          <Loader />
        </div>
      ) : (
        ""
      )}
      <div className="content-route">
        <div className="card mb-3">
          <div className="page-header">
            <h2 className="page-title mb-0">
              Organisation / {organizationId ? "Edit" : "Add"} Admin
            </h2>
          </div>
        </div>
        <form className="card px-3 pb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="my-4">
            <div className="row justify-content-between flex-column">
              <div className="col-md-12">
                <h3 className="sub-heading">Organisation</h3>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-4">
                    <label>Organisation Name</label>
                    <input
                      {...register("orgName", { required: true })}
                      // defaultValue={adminId && props?.history?.location?.state?.name}
                      className={`form-control ${
                        errors.orgName ? "is-invalid" : ""
                      } `}
                      type="text"
                      placeholder="Ex:- xyz limited"
                    />
                    {errors.orgName && errors.orgName.type === "required" && (
                      <p className="input-error">
                        Organisation name is required.
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-4">
                    <label>Organisation Email</label>
                    <input
                      // defaultValue={adminId && props?.history?.location?.state?.email}
                      {...register("orgEmail", {
                        required: true,
                        pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                      })}
                      className={`form-control ${
                        errors.orgEmail ? "is-invalid" : ""
                      } `}
                      type="text"
                      placeholder="ex:- email@xyz.com"
                      disabled={organizationId ? true : false}
                    />
                    {errors.orgEmail && errors.orgEmail.type === "required" && (
                      <p className="input-error">Email is required.</p>
                    )}
                    {errors.orgEmail && errors.orgEmail.type === "pattern" && (
                      <p className="input-error">Invalid email address.</p>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-4">
                    <label>Organisation Phone</label>
                    <input
                      // defaultValue={adminId && props?.history?.location?.state?.email}
                      {...register("orgPhone", { required: false })}
                      className={`form-control ${
                        errors.orgPhone ? "is-invalid" : ""
                      } `}
                      type="text"
                      placeholder="ex:- 7675432123"
                    />
                    {errors.orgPhone && errors.orgPhone.type === "required" && (
                      <p className="input-error">Phone is required.</p>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-4">
                    <label>Organization Initials</label>
                    <input
                      {...register("orgInitials", {
                        required: "Organization initials are required.",
                        pattern: {
                          value: /^[A-Za-z]{3}$/,
                          message:
                            "Organization initials must be exactly 3 letters.",
                        },
                      })}
                      className={`form-control ${
                        errors.orgInitials ? "is-invalid" : ""
                      }`}
                      type="text"
                      placeholder="ex:- ABC"
                      disabled={organizationId ? true : false}
                    />
                    {errors.orgInitials && (
                      <p className="input-error">
                        {errors.orgInitials.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* organization admin details */}
              <div className="col-md-12">
                <h3 className="sub-heading">Organisation Admin</h3>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-4">
                    <label>Admin Firstname</label>
                    <input
                      {...register("adminFirstName", { required: true })}
                      // defaultValue={adminId && props?.history?.location?.state?.name}
                      className={`form-control ${
                        errors.adminFirstName ? "is-invalid" : ""
                      } `}
                      type="text"
                      placeholder="Ex:- Jhon"
                    />
                    {errors.adminFirstName &&
                      errors.adminFirstName.type === "required" && (
                        <p className="input-error">
                          Admin firstname is required.
                        </p>
                      )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-4">
                    <label>Admin Lastname</label>
                    <input
                      // defaultValue={adminId && props?.history?.location?.state?.email}
                      {...register("adminLastName", { required: true })}
                      className={`form-control ${
                        errors.adminLastName ? "is-invalid" : ""
                      } `}
                      type="text"
                      placeholder="Ex:- Doe"
                    />
                    {errors.adminLastName &&
                      errors.adminLastName.type === "required" && (
                        <p className="input-error">
                          Admin lastname is required.
                        </p>
                      )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-4">
                    <label>Admin Email</label>
                    <input
                      // defaultValue={adminId && props?.history?.location?.state?.email}
                      {...register("adminEmail", {
                        required: true,
                        pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                      })}
                      className={`form-control ${
                        errors.adminEmail ? "is-invalid" : ""
                      } `}
                      type="text"
                      placeholder="ex:- jhondoe@xyz.com"
                      disabled={organizationId ? true : false}
                    />
                    {errors.adminEmail &&
                      errors.adminEmail.type === "required" && (
                        <p className="input-error">Email is required.</p>
                      )}
                    {errors.adminEmail &&
                      errors.adminEmail.type === "pattern" && (
                        <p className="input-error">Invalid email address.</p>
                      )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-4">
                    <label>Admin Phone</label>
                    <input
                      // defaultValue={adminId && props?.history?.location?.state?.email}
                      {...register("adminPhone", { required: false })}
                      className={`form-control ${
                        errors.orgPhone ? "is-invalid" : ""
                      } `}
                      type="text"
                      placeholder="ex:- 8765432134"
                    />
                    {errors.adminPhone &&
                      errors.adminPhone.type === "required" && (
                        <p className="input-error">Admin phone is required.</p>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEditOrganization;
