import ApiInstance from "../config/intercepter";
import Api from "../config/api";

// view Role
export function allEmails() {
  return ApiInstance.get(`${Api.listEmails}`);
}

export function listEmailsBasedOnOrg() {
    return ApiInstance.get(`${Api.listEmailsBasedOnOrg}`);
}

export function updateEmails(id, payload) {
    console.log(id,payload)
    return ApiInstance.put(`${Api.updateEmails}/${id}`, payload);
  }