import React, { useEffect, useState } from "react";
import { Loader } from "../../components/util/Svg";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  addAssessmentApiFun,
  updateAssessmentApiFun,
  viewAssessmentApiFun,
  addAssessmentApiFunSecondary,
} from "../../services/assessments";
import { allCategories } from "../../services/categories";
import { useSelector } from "react-redux";
import { categorieListApiFun } from "../../services/categories";
const AddEditAssessment = (props) => {
  const { assessmentId } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  let history = useHistory();
  const [loader, setLoader] = useState(false);
  const [categoriesOptions, setCategoriesParentOptions] = useState([]);
  const type = props.addType;

  const filterCategoriesByType = (allCategories, type) => {
    // Find the main parent category based on the type
    const parentCategory = allCategories.find(
      (cat) => cat.name.toLowerCase() === type.toLowerCase() && !cat.parent
    );

    if (parentCategory) {
      let specificSubCategory = null;

      if (type.toLowerCase() === "physical") {
        specificSubCategory = allCategories.find(
          (cat) =>
            cat.name === "Assessment" && cat.parent?._id === parentCategory._id
        );
      } else if (type.toLowerCase() === "psychological") {
        specificSubCategory = allCategories.find(
          (cat) =>
            cat.name === "Assessment" && cat.parent?._id === parentCategory._id
        );
      }

      if (specificSubCategory) {
        return [
          {
            label: specificSubCategory.name,
            value: specificSubCategory._id,
          },
        ];
      }
    }

    // Return an empty array if no matching parent category or specific subcategory is found
    return [];
  };

  useEffect(() => {
    if (assessmentId) {
      viewAssessmentApi();
    } else {
      setLoader(true);
      categorieListApiFun(1, 100, "", "", 0, 0)
        .then((res) => {
          if (res?.statusCode === 1) {
            const allCategories = res?.responseData?.categories;
            const filteredCategories = filterCategoriesByType(
              allCategories,
              type
            );
            const catId = filteredCategories[0].value;
            setCategoriesParentOptions(catId);
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  }, [assessmentId, type]);

  const viewAssessmentApi = () => {
    setLoader(true);
    Promise.all([
      viewAssessmentApiFun(assessmentId),
      categorieListApiFun(1, 100, "", "", 0, 0),
    ])
      .then((res) => {
        if (res[0]?.statusCode === 1) {
          const data = res[0].responseData.assessment;
          setValue("categoryId", data?.categoryId);
          setValue("title", data?.title);
          setValue("beforeDescription", data?.beforeDescription);
          setValue("afterDescription", data?.afterDescription);
        } else if (
          res[0]?.error?.errorCode === 46 ||
          res[0]?.error?.errorCode === 2
        ) {
          localStorage.clear();
          history.push("/login");
        }

        if (res[1]?.statusCode === 1) {
          const allCategories = res[1]?.responseData?.categories;
          // Ensure type is correctly applied here
          const filteredCategories = filterCategoriesByType(
            allCategories,
            type
          );

          // Set the filtered categories as options
          setCategoriesParentOptions(filteredCategories);
        } else if (
          res[1]?.error?.errorCode === 46 ||
          res[1]?.error?.errorCode === 2
        ) {
          localStorage.clear();
          history.push("/login");
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  // add edit content
  const onSubmit = (data) => {
    setLoader(true);
    const { isActive, ...newData } = data;
    const categoryId = categoriesOptions;

    // Add categoryId to newData
    const dataWithCategory = { ...newData, categoryId };
    if (!assessmentId) {
      addAssessmentApiFun(dataWithCategory)
        .then((res) => {
          handleResponse(res);
        })
        .catch((err) => {
          console.log("error", err);
          setLoader(false);
        });
    } else {
      updateAssessmentApiFun(assessmentId, newData)
        .then((res) => {
          handleResponse(res);
        })
        .catch((err) => {
          console.log("error", err);
          setLoader(false);
        });
    }
  };

  const handleResponse = (res) => {
    if (res?.statusCode === 1) {
      history.goBack();
    } else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
      localStorage.clear();
      history.push("/login");
    }
    setLoader(false);
  };

  return (
    <>
      {loader ? (
        <div className="loader_lg">
          <Loader />
        </div>
      ) : (
        ""
      )}
      <div className="content-route">
        <div className="card mb-3">
          <div className="page-header">
            <h2 className="page-title mb-0">
              Assessment / {assessmentId ? "Edit" : "Add New"}
            </h2>
          </div>
        </div>
        <form className="card px-3 pb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="my-4">
            <div className="row justify-content-between flex-column">
              <div className="col-md-5">
                <div className="mb-4">
                  <label>Title</label>
                  <input
                    {...register("title", { required: true })}
                    className={`form-control ${
                      errors.title ? "is-invalid" : ""
                    } `}
                    type="text"
                    placeholder="Assessment Title"
                  />
                  {errors.title && errors.title.type === "required" && (
                    <p className="input-error">Title is required.</p>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-4">
                  <label>Before Assessment Description</label>
                  <textarea
                    {...register("beforeDescription", { required: true })}
                    className={`form-control ${
                      errors.description ? "is-invalid" : ""
                    } `}
                    placeholder="Before Assessment Description"
                    rows="4"
                  ></textarea>
                  {errors.description &&
                    errors.description.type === "required" && (
                      <p className="input-error">Before Description is required.</p>
                    )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-4">
                  <label>After Assessment Description</label>
                  <textarea
                    {...register("afterDescription", { required: true })}
                    className={`form-control ${
                      errors.description ? "is-invalid" : ""
                    } `}
                    placeholder="After Assessment Description"
                    rows="4"
                  ></textarea>
                  {errors.description &&
                    errors.description.type === "required" && (
                      <p className="input-error">After Assessment Description is required.</p>
                    )}
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEditAssessment;
