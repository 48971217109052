import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from '../Dashboard';
import RoleManagement from '../RoleManagement';
import Administraion from '../Administration';
import Header from '../../components/templates/Header';
import Sidebar from '../../components/templates/Sidebar';
import OrganigationManagement from '../Organigation';
import Users from '../Users';
import AddNewRole from '../RoleManagement/AddNewRole';
import PageNotFound from '../../components/PageNotFound';
import ViewRole from '../RoleManagement/ViewRole';
import AddEditAdmin from '../Administration/AddEditAdmin';
import ViewAdmin from '../Administration/ViewAdministration';
import ContentManagement from '../ContentManagement';
import AddEditContent from '../ContentManagement/AddEditContent';
import ViewContent from '../ContentManagement/viewContent';
import AddEditOrganization from '../Organigation/AddEditOrganization';
import ViewOrganization from '../Organigation/ViewOrganization';
import Notification from '../../components/Notification/Notification';
import ChangePassword from '../../auth/ChangePassword';
import Profile from '../../components/Profile/Profile';
import { useSelector } from 'react-redux';
import DiseaseManagement from '../DiseaseManagement';
import AddEditDisease from '../DiseaseManagement/AddEditDisease';
import ViewDisease from '../DiseaseManagement/ViewDisease';
import Code from '../../organizationPanel/Code';
import ViewCategory from '../Categories/viewCategories';
import CategoriesManagement from '../Categories';
import AddEditCategory from '../Categories/AddEditCategories';
import VideosManagement from '../Videos';
import ViewVideo from '../Videos/viewVideo';
import AddEditVideo from '../Videos/AddEditVideo';
import AddEditAssessment from '../Assessments/AddEditAssessments';
import AssessmentsManagement from '../Assessments';
import ViewAssessment from '../Assessments/viewAssessment';
import QuestionsManagement from '../Questions';
import ViewQuestion from '../Questions/viewQuestion';
import AddEditQuestion from '../Questions/AddEditQuestion';
import UsersAnswersManagement from '../userAsnwers';
import ViewUsersAnswers from '../userAsnwers/viewUsersAnswers';
import TrainersManagement from '../Trainers';
import { viewTrainerApiFun } from '../../services/trainers';
import ViewTrainer from '../Trainers/viewTrainer';
import AddEditTrainer from '../Trainers/AddEditTrainer';
import CrisisLine from '../CrisisLine';
import TravelTipsInfo from '../TravelTipsInfo';
import WelcomeMessage from '../Notifications';
const Routes = props => {
    const access = useSelector(state => state.users?.user?.org?.roles ?? [])
    const roleAccess = access.find(role => role.sectionName === 'Role Management');
    const adminAccess = access.find(role => role.sectionName === 'Administration Management');
    const orgsAccess = access.find(role => role.sectionName === 'Organization Management');
    const contentAccess = access.find(role => role.sectionName === 'Content');
    const videosAccess = access.find(role => role.sectionName === 'Videos');
    const categoriesAccess = access.find(role => role.sectionName === 'Categories');
    const assessmentsAccess = access.find(role => role.sectionName === 'Assessments');
    const questionsAccess = access.find(role => role.sectionName === 'Questions');
    const trainersAccess = access.find(role => role.sectionName === 'Trainers');
    const usersAnswersAccess = access.find(role => role.sectionName === 'UsersAnswers');
    const usersAccess = access.find(role => role.sectionName === 'Users');
    const notificationAccess = access.find(role => role.sectionName === 'Notification Management');
    const diseaseAccess = access.find(role => role.sectionName === 'Travel Disease Management');
    // const roleRouteAccess = access[1]?.updateAccess + access[1]?.deleteAccess + access[1]?.viewAccess + access[1]?.addAccess
    // const adminRouteAccess = access[2]?.updateAccess + access[2]?.deleteAccess + access[2]?.viewAccess + access[2]?.addAccess
    // const organizationRouteAccess = access[3]?.updateAccess + access[3]?.deleteAccess + access[3]?.viewAccess + access[3]?.addAccess
    // const contentRouteAccess = access[6]?.updateAccess + access[6]?.deleteAccess + access[6]?.viewAccess + access[6]?.addAccess
    // const videosAccessCount = access[7]?.addAccess + access[7]?.deleteAccess + access[7]?.updateAccess + access[7]?.viewAccess
    // const categoriesAccessCount = access[8]?.addAccess + access[8]?.deleteAccess + access[8]?.updateAccess + access[8]?.viewAccess
    // const assessmentAccessCount = access[9]?.addAccess + access[9]?.deleteAccess + access[9]?.updateAccess + access[9]?.viewAccess
    // const questionsAccessCount = access[10]?.addAccess + access[10]?.deleteAccess + access[10]?.updateAccess + access[10]?.viewAccess
    // const usersAnswersAccessCount = access[11]?.addAccess + access[11]?.deleteAccess + access[11]?.updateAccess + access[11]?.viewAccess
    // const trainersAccessCount = access[12]?.addAccess + access[12]?.deleteAccess + access[12]?.updateAccess + access[12]?.viewAccess
    const adminRole = useSelector(state => state.users?.user?.org?.adminRole.roleName ?? [])
    const [sidebar, setSidebar] = useState(false);
    const toggleSidebar = () => {
        setSidebar(!sidebar)
    }
    return (
        <div className="site_wrapper">
            <Header
            />
            <div className="main-cointainer">
                <Sidebar
                    sidebarFun={toggleSidebar}
                    sidebarState={sidebar}
                />
                <div className={`content-wrapper ms-auto ${sidebar ? "full-content" : ""}`}>
                    <Switch>

                        <Route exact path='/dashboard' component={Dashboard} />

                        <Route exact path='/code' component={Code} />

                        <Route exact path='/change-password' component={ChangePassword} />
                        {
                            roleAccess?.viewAccess &&
                            <Route exact path='/role-management' component={RoleManagement} />
                        }

                        {
                            roleAccess?.addAccess &&
                            <Route exact path='/role-management/add-new-role' component={AddNewRole} />
                        }
                        {
                            roleAccess?.updateAccess &&
                            <Route exact path='/role-management/edit-role/:roleId' component={AddNewRole} />
                        }
                        {
                            roleAccess?.viewAccess &&
                            <Route exact path='/role-management/view-role/:roleId' component={ViewRole} />
                        }

                        {
                            adminAccess?.viewAccess &&
                            <Route exact path='/administration' component={Administraion} />
                        }

                        {
                            adminAccess?.addAccess &&
                            <Route exact path='/administration/add-admin' component={AddEditAdmin} />
                        }

                        {
                            adminAccess?.updateAccess &&
                            <Route exact path='/administration/edit-admin/:adminId' component={AddEditAdmin} />
                        }
                        {
                            adminAccess?.viewAccess &&
                            <Route exact path='/administration/view/:adminId' component={ViewAdmin} />
                        }

                        {
                            orgsAccess?.viewAccess  &&
                            <Route exact path='/organization' component={OrganigationManagement} />
                        }

                        {
                            orgsAccess?.addAccess &&
                            <Route exact path='/organization/add-new' component={AddEditOrganization} />
                        }
                        {
                            orgsAccess?.updateAccess &&
                            <Route exact path='/organization/edit/:organizationId' component={AddEditOrganization} />
                        }
                        {
                            orgsAccess?.viewAccess &&
                            <Route exact path='/organization/view/:organizationId' component={ViewOrganization} />
                        }
                        {
                           usersAccess?.viewAccess &&
                            <Route exact path='/users' component={Users} />
                        }

                        {/* <Route exact path='/settings' component={Dashboard} /> */}
                        {
                            contentAccess &&
                            <Route exact path='/content-management' component={ContentManagement} />
                        }
                                                {
                            contentAccess?.viewAccess &&
                            <Route exact path='/content-management/view/:contentId' component={ViewContent} />
                        }



                        { videosAccess?.viewAccess && <Route exact path='/physical-videos' component={() => <VideosManagement type = "Physical"/>} />}
                        { videosAccess?.viewAccess && <Route exact path='/psychological-videos' component={() => <VideosManagement type = "Psychological"/>} />}
                        { videosAccess?.viewAccess  && <Route exact path='/videos/view/:videoId' component={ViewVideo} />}
                        { videosAccess?.addAccess && <Route exact path='/psychological-videos/add-new' component={() => <AddEditVideo addType = "Psychological"/> } />}
                        { videosAccess?.addAccess && <Route exact path='/physical-videos/add-new' component={() => <AddEditVideo addType= "Physical"/>} />}
                        { videosAccess?.updateAccess && <Route exact path='/physical-videos/edit/:videoId' component={() => <AddEditVideo addType = "Physical"/>} />}
                        { videosAccess?.updateAccess && <Route exact path='/psychological-videos/edit/:videoId' component={() => <AddEditVideo addType= "Psychological"/>}  />}

                        { categoriesAccess?.viewAccess && <Route exact path='/title-editors' component={CategoriesManagement} />}
                        { categoriesAccess?.viewAccess  && <Route exact path='/title-editors/view/:categoryId' component={ViewCategory} />}
                        { categoriesAccess?.addAccess && <Route exact path='/title-editors/add-new' component={AddEditCategory} />}
                        { categoriesAccess?.updateAccess && <Route exact path='/title-editors/edit/:categoryId' component={AddEditCategory} />}
                        
                        { assessmentsAccess?.viewAccess && <Route exact path='/physical-assessments' component={() => <AssessmentsManagement type="Physical" />} />}
                        { assessmentsAccess?.viewAccess && <Route exact path='/psychological-assessments' component={() => <AssessmentsManagement type="Psychological" />} />}
                        { assessmentsAccess?.viewAccess  && <Route exact path='/assessments/view/:assessmentId' component={ViewAssessment} />}
                        { assessmentsAccess?.addAccess && <Route exact path='/physical-assessments/add-new' component={() => <AddEditAssessment addType = "Physical"/>} />}
                        { assessmentsAccess?.addAccess && <Route exact path='/psychological-assessments/add-new' component={() => <AddEditAssessment addType = "Psychological"/>} />}
                        { assessmentsAccess?.updateAccess && <Route exact path='/physical-assessments/edit/:assessmentId' component={() => <AddEditAssessment addType= "Physical"/>} />}
                        { assessmentsAccess?.updateAccess && <Route exact path='/psychological-assessments/edit/:assessmentId' component={() => <AddEditAssessment addType= "Psychological"/>} />}

                        { questionsAccess?.viewAccess && <Route exact path='/questions' component={QuestionsManagement} />}
                        { questionsAccess?.viewAccess  && <Route exact path='/questions/view/:questionId' component={ViewQuestion} />}
                        { questionsAccess?.addAccess && <Route exact path='/questions/add-new' component={AddEditQuestion} />}
                        { questionsAccess?.updateAccess && <Route exact path='/questions/edit/:questionId' component={AddEditQuestion} />}
{/* 
                        { usersAnswersAccess?.viewAccess  && <Route exact path='/usersAnswers' component={UsersAnswersManagement} />}
                        { usersAnswersAccess?.viewAccess  && <Route exact path='/usersAnswers/view/:userAnswerId' component={ViewUsersAnswers} />} */}
                        {/* { usersAnswersAccess?.addAccess && <Route exact path='/userAnswers/add-new' component={AddEditQuestion} />}
                        { usersAnswersAccess?.updateAccess && <Route exact path='/userAnswers/edit/:questionId' component={AddEditQuestion} />} */}
                        
                        { trainersAccess?.viewAccess  && <Route exact path='/physical-trainers' component={() => <TrainersManagement type="Physical" />} />}
                        { trainersAccess?.viewAccess  && <Route exact path='/psychological-trainers' component={() => <TrainersManagement type="Psychological" />} />}
                        { trainersAccess?.viewAccess  && <Route exact path='/trainers/view/:trainerId' component={ViewTrainer} />}
                        { trainersAccess?.addAccess && <Route exact path='/physical-trainers/add-new' component={() => <AddEditTrainer addType = "Physical"/>} />}
                        { trainersAccess?.addAccess && <Route exact path='/psychological-trainers/add-new' component={() => <AddEditTrainer addType = "Psychological"/>} />}
                        { trainersAccess?.updateAccess && <Route exact path='/physical-trainers/edit/:trainerId' component={() => <AddEditTrainer addType = "Physical"/>} />}
                        { trainersAccess?.updateAccess && <Route exact path='/psychological-trainers/edit/:trainerId' component={() => <AddEditTrainer addType = "Psychological"/>} />}
                       
                        {contentAccess?.viewAccess && <Route exact path='/travel-tips-info' component={TravelTipsInfo} /> }
                        {
                            contentAccess?.addAccess &&
                            <Route exact path='/travel-tips-info/add-new' component={AddEditContent} />
                        }
                        {
                            contentAccess?.updateAccess &&
                            <Route exact path='/travel-tips-info/edit/:contentId' component={AddEditContent} />
                        }
                        {
                            contentAccess?.viewAccess &&
                            <Route exact path='/travel-tips-info/view/:contentId' component={ViewContent} />
                        }
                        {notificationAccess?.updateAccess && <Route exact path='/notifications' component={WelcomeMessage} /> }

                        <Route exact path='/profile/:userId' component={Profile} />
                        <Route exact path='/notification' component={Notification} />
                        {diseaseAccess?.viewAccess && <Route exact path='/disease-management' component={DiseaseManagement} />}
                        {diseaseAccess?.addAccess && <Route exact path='/disease-management/add-new' component={AddEditDisease} /> }
                        {diseaseAccess?.updateAccess && <Route exact path='/disease-management/edit/:diseaseId' component={AddEditDisease} /> }
                        {diseaseAccess?.viewAccess &&<Route exact path='/disease-management/view/:diseaseId' component={ViewDisease} /> }
                        
                        {adminRole === "SuperAdmin" && <Route exact path='/crisis-line' component={CrisisLine} />}
                        <Redirect exact from={"/"} to={"/dashboard"} />
                        <Route to="*" component={PageNotFound} />
                    </Switch>
                </div>
            </div>
        </div>
    )
}

export default Routes;