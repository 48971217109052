import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Loader } from '../../components/util/Svg';
import { viewVideoApiFun } from '../../services/videos';


const ViewVideo = (props) => {
    let history = useHistory()
    const [apiData, setApiData] = useState();
    // const [mediaURL, setMediaURL] = useState();
    const [loader, setLoader] = useState(false);
    const { videoId } = useParams()
    const [thumbnail, setThumbnail] = useState('');

    useEffect(() => {
        viewContentApi();
    }, [])

    const viewContentApi = () => {
        setLoader(true)
        viewVideoApiFun(videoId)
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiData(res?.responseData?.video);
                    setThumbnail(res?.responseData?.video?.th_media)
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false)
            })
            .catch(err => {
                setLoader(false)
            })
    }

    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Video / Details</h2>
                    </div>
                </div>
                <div className="card  px-3">
                    <div className="my-4">
                        <div className="row justify-content-between ">
                            <div className="col-md-8">
                                <div className="detailed">
                                    <p className="">
                                        <span className="label d-block">Title: </span>
                                        <span className="value fw-bold">{apiData?.title}</span>
                                    </p>
                                    <p className="">
                                        <span className="label d-block">Description: </span>
                                        <span className="value fw-bold">{apiData?.description}</span>
                                    </p>
                                    <p className="">
                                        <span className="label d-block">Category Name: </span>
                                        <span className="value fw-bold">{apiData?.category?.name}</span>

                                    </p>
                                    <div className="media-preview">
                                        {/* <span className="label d-block">media: </span> */}
                                        {
                                            <div className="image-preview">
                                                {
                                                    apiData?.path_url ? 
                                                    <video controls className="video-preview" poster={thumbnail}>
                                                        <source src={apiData?.path_url} id="video_here"></source>                                
                                                    </video> : null 
                                                }
                                            </div>
                                        }
                                    </div>
                                    <p className="">
                                        <span className="label d-block">Created At: </span>
                                        <span className="value fw-bold">
                                            <Moment format="DD/MM/YYYY">
                                                {apiData?.updated}
                                            </Moment>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewVideo;