import React, { useEffect, useState } from 'react';
import { Route, Redirect, Link, useHistory, useParams } from 'react-router-dom';
import { Loader } from '../../components/util/Svg';
import Moment from 'react-moment';
import { Modal, Switch } from 'antd';
import { ExclamationCircleOutlined, LoadingOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Pagination, Select } from 'antd';
import { blockUnclockUserService, deleteUserService, userListService } from '../../services/user';
import { useSelector } from 'react-redux';
const { Option } = Select;
const { confirm } = Modal;

const User = props => {

    let history = useHistory()
    const [apiData, setApiData] = useState({});
    const [loader, setLoader] = useState(true);
    const [pageLimit, setPageLimit] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const [sortValue, setSortValue] = useState("1");
    const [searchKey, setSearchKey] = useState("");
    const [searchStatus, setSearchStatus] = useState(false);
    const [byAccenDecc, setByAccenDecc] = useState("");
    const [sortByName, setSortByName] = useState(false);
    const [sortByEmail, setSortByEmail] = useState(false);
    const [sortByDate, setSortByDate] = useState(false);
    const [genderFilter, setGenderFilter] = useState('');
    const users = useSelector(state => state.users?.user?.org?.roles ?? [])
    const usersAccess = users.find(role => role.sectionName === 'Users');

    const { id } = useParams()
    useEffect(() => {
        userList(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, genderFilter);
    }, [])

    const userList = (pageNo, pageLimit, searchKey, sortValue, byAccenDecc, genderFilter) => {
        // setLoader(true)
        userListService(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, genderFilter)
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiData(res?.responseData);
                    setPageNo(pageNo);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false)
                setSearchStatus(false);
            })
            .catch(err => {
                console.log("Role error", err);
                setLoader(false)
                setSearchStatus(false);
            })
    }


    // pagination
    const handlePagination = page => {
        userList(page, pageLimit, searchKey, sortValue, byAccenDecc, genderFilter);
    };

    const handleSearch = searchEvent => {
        setPageNo(1);
        setSearchStatus(true)
        let searchKey = searchEvent.target.value;
        setSearchKey(searchKey);
        if (searchKey.length > 1) {
            userList(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, genderFilter);
        } else {
            userList(pageNo, pageLimit, '', sortValue, byAccenDecc);
        }
    }

    const handleSort = (dir, field, byName, byEmail, byDate) => {
        setLoader(true)
        setPageNo(1)
        setSortByName(byName);
        setSortByEmail(byEmail);
        setSortByDate(byDate);
        setByAccenDecc(dir)
        setSortValue(field)
        userList(pageNo, pageLimit, searchKey, field, dir, genderFilter);
    }

    const blockUnblockUser = (status, id) => {
        let params = {
            userId: id,
            status: status.target.checked === true ? 1 : 0,
        }
        setLoader(true)
        blockUnclockUserService(params)
            .then(res => {
                if (res?.statusCode === 1) {
                    userList(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, genderFilter);
                    
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false)
            })
            .then((err) => {
                console.log(err);
                setLoader(false)
            })
    }

    const filterGenderType = (value) => {
        setGenderFilter(value);
        userList(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, value);
    }

    const handleDelete = (id) => {
        confirm({
            title: 'Do you Want to delete this Admin?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setLoader(true)
                deleteUserService(id)
                    .then(res => {
                        if (res?.statusCode === 1) {
                            if (apiData?.users?.length === 1) {
                                let newPageNo = pageNo - 1;
                                userList(newPageNo, pageLimit, searchKey, '', '', genderFilter);
                            } else {
                                userList(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, '');
                            }
                        }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                            localStorage.clear()
                            history.push("/login");
                        }
                        console.log('test',res)
                        setLoader(false)
                    })
                    .catch(err => {
                        console.log("error", err);
                        setLoader(false)
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Users</h2>
                    </div>
                </div>
                <div className="card  px-3  pb-3">
                    <div className="my-4">
                        <div className="row justify-content-between ">
                            <div className="col-md-5">
                                <div className="search">
                                    {
                                        searchStatus ?
                                            <span className="search-spiner d-flex align-items-center">
                                                <LoadingOutlined style={{ color: "#00BFFF" }} />
                                            </span>
                                            : ""
                                    }
                                    <input className="form-control" type="text" placeholder="Search..." onChange={(e) => handleSearch(e)} />
                                </div>
                            </div>
                            <div className="col-md-5 text-end d-flex align-items-center justify-content-end">
                                <div className="d-flex align-items-center me-3">
                                    <Select className="text-start" value={genderFilter} style={{ width: 120 }} onChange={filterGenderType}>
                                        <Option value="">All</Option>
                                        <Option value="2">Female</Option>
                                        <Option value="1">Male</Option>
                                        <Option value="3">Other</Option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    {/* <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Name</span>
                                            {
                                                sortByName === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "5", false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "5", true, false, false)} />
                                            }
                                        </div>
                                    </th> */}
                                    {/* <th>
                                        Gender
                                    </th> */}
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Email</span>
                                            {/* {
                                                sortByEmail === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "3", false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "3", true, true, false)} />
                                            } */}
                                        </div>
                                    </th>
                                    <th>
                                        Nationality
                                    </th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Created At</span>
                                            {/* {
                                                sortByDate === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "1", false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "1", true, true, true)} />
                                            } */}
                                        </div>
                                    </th>
                                    <th>Active/Inactive</th>
                                    {usersAccess.roleName === "SuperAdmin" && <th>Block/Unblock</th> }
                                    {usersAccess.roleName === "SuperAdmin" && <th>Delete</th> }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    apiData?.users?.map((user, index) => (
                                        <tr key={index}>
                                            <td>{pageLimit *(pageNo -1) +(index+1)}</td>
                                            {/* <td>{`${user?.firstName} ${user?.lastName}`}</td> */}
                                            {/* <td>{`${user?.gender===1?'Male':user?.gender===2?'Female':user?.gender===3?'Other':'' }`}</td> */}
                                            <td>{user?.email}</td>
                                            <td>{user?.nationality ? user?.nationality : '-'}</td>
                                            <td>
                                                <Moment format="DD/MM/YYYY">
                                                    {user?.created}
                                                </Moment>
                                            </td>
                                            <td>
                                                {user?.isActive === 1 ? <span className="badge bg-success text-light fw-bold">Active</span> : <span className=" badge bg-danger text-light fw-bold">Inactive</span>}
                                            </td>
                                            <td>
                                                <div className="form-check form-switch">
                                                   {usersAccess.roleName === "SuperAdmin" && <input checked={user?.status ? true : false} onChange={(e) => blockUnblockUser(e, user?._id)} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" /> }
                                                    {/* <label className="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label> */}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="form-check form-switch">
                                                {usersAccess.roleName === "SuperAdmin" && <i className="cursor-pointer fa fa-trash text-danger" onClick={() => handleDelete(user?._id)}></i> }
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="text-center">
                        <Pagination showSizeChanger={false} current={pageNo} onChange={handlePagination} total={apiData.total ? apiData.total : 1} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default User;