import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from '../OrganizationDashboard';
import Header from '../templates/Header';
import Sidebar from '../templates/Sidebar';
import PageNotFound from '../../components/PageNotFound';
import Employee from '../Employee';
import Code from '../Code';
import Content from '../Content';
import AddEditContent from '../Content/AddEditContent';
import ViewContent from '../Content/viewContent';
import ChangePassword from '../../auth/ChangePassword';
import Profile from '../../components/Profile/Profile';
import Notification from '../../components/Notification/Notification';
import CrisisLine from '../../adminPanel/CrisisLine';
import VideosManagement from '../../adminPanel/Videos';
import TrainersManagement from '../../adminPanel/Trainers';
import AssessmentsManagement from '../../adminPanel/Assessments'; 
import AddEditAssessment from '../../adminPanel/Assessments/AddEditAssessments';
import AddEditVideo from '../../adminPanel/Videos/AddEditVideo';
import { useSelector } from 'react-redux';
import ViewVideo from '../../adminPanel/Videos/viewVideo';
import ViewAssessment from '../../adminPanel/Assessments/viewAssessment';
import ViewQuestion from '../../adminPanel/Questions/viewQuestion';
import AddEditQuestion from '../../adminPanel/Questions/AddEditQuestion';
import ViewTrainer from '../../adminPanel/Trainers/viewTrainer';
import AddEditTrainer from '../../adminPanel/Trainers/AddEditTrainer';
import Notifications from "../../adminPanel/Notifications/index"
import Codes from '../OrgCodePage';
const OrganizationRoutes = props => {
    const [sidebar, setSidebar] = useState(false);
    const toggleSidebar = ()=>{
        setSidebar(!sidebar)
    }
    const access = useSelector(state => state.users?.user?.org?.roles ?? [])
    const assessmentsAccess = access.find(role => role.sectionName === 'Assessments');
    const videosAccess = access.find(role => role.sectionName === 'Videos');
    const questionsAccess = access.find(role => role.sectionName === 'Questions');
    const trainersAccess = access.find(role => role.sectionName === 'Trainers');
    const notificationAccess = access.find(role => role.sectionName === 'Notification Management');

    return (
        <div className="site_wrapper">
            <Header 
                sidebarFun={toggleSidebar}
                sidebarState={sidebar}
            />
            <div className="main-cointainer">
                <Sidebar 
                    sidebarFun={toggleSidebar}
                    sidebarState={sidebar}
                />
                <div className={`content-wrapper ms-auto ${sidebar? "full-content" :""}`}>
                    <Switch>
                        <Route exact path='/organization-dashboard' component={Dashboard} />
                        <Route exact path='/change-password' component={ChangePassword} />
                        <Route exact path='/employee' component={Employee} />
                        <Route exact path='/codes' component={Codes} />
                        <Route exact path='/organization-content' component={Content} />
                        <Route exact path='/organization-content/add' component={AddEditContent} />
                        <Route exact path='/organization-content/edit/:contentId' component={AddEditContent} />
                        <Route exact path='/organization-content/view/:contentId' component={ViewContent} />
                        <Route exact path='/profile/:userId' component={Profile} />
                        <Route exact path='/notification' component={Notification} />
                        <Route exact path='/crisis-line' component={CrisisLine} />
                        <Redirect exact from={"/"} to={"/organization-dashboard"} />
                        { assessmentsAccess?.viewAccess && <Route exact path='/physical-assessments' component={() => <AssessmentsManagement type="Physical" />} />}
                        { assessmentsAccess?.viewAccess && <Route exact path='/psychological-assessments' component={() => <AssessmentsManagement type="Psychological" />} />}
                        { assessmentsAccess?.viewAccess  && <Route exact path='/assessments/view/:assessmentId' component={ViewAssessment} />}
                        { assessmentsAccess?.addAccess && <Route exact path='/physical-assessments/add-new' component={() => <AddEditAssessment addType = "Physical"/>} />}
                        { assessmentsAccess?.addAccess && <Route exact path='/psychological-assessments/add-new' component={() => <AddEditAssessment addType = "Psychological"/>} />}
                        { assessmentsAccess?.updateAccess && <Route exact path='/physical-assessments/edit/:assessmentId' component={() => <AddEditAssessment addType= "Physical"/>} />}
                        { assessmentsAccess?.updateAccess && <Route exact path='/psychological-assessments/edit/:assessmentId' component={() => <AddEditAssessment addType= "Psychological"/>} />}

                        { videosAccess?.viewAccess && <Route exact path='/physical-videos' component={() => <VideosManagement type = "Physical"/>} />}
                        { videosAccess?.viewAccess && <Route exact path='/psychological-videos' component={() => <VideosManagement type = "Psychological"/>} />}
                        { videosAccess?.viewAccess  && <Route exact path='/videos/view/:videoId' component={ViewVideo} />}
                        { videosAccess?.addAccess && <Route exact path='/psychological-videos/add-new' component={() => <AddEditVideo addType = "Psychological"/> } />}
                        { videosAccess?.addAccess && <Route exact path='/physical-videos/add-new' component={() => <AddEditVideo addType= "Physical"/>} />}
                        { videosAccess?.updateAccess && <Route exact path='/physical-videos/edit/:videoId' component={() => <AddEditVideo addType = "Physical"/>} />}
                        { videosAccess?.updateAccess && <Route exact path='/psychological-videos/edit/:videoId' component={() => <AddEditVideo addType= "Psychological"/>}  />}


                        { questionsAccess?.viewAccess  && <Route exact path='/questions/view/:questionId' component={ViewQuestion} />}
                        { questionsAccess?.addAccess && <Route exact path='/questions/add-new' component={AddEditQuestion} />}
                        { questionsAccess?.updateAccess && <Route exact path='/questions/edit/:questionId' component={AddEditQuestion} />}

                        { trainersAccess?.viewAccess  && <Route exact path='/physical-trainers' component={() => <TrainersManagement type="Physical" />} />}
                        { trainersAccess?.viewAccess  && <Route exact path='/psychological-trainers' component={() => <TrainersManagement type="Psychological" />} />}
                        { trainersAccess?.viewAccess  && <Route exact path='/trainers/view/:trainerId' component={ViewTrainer} />}
                        { trainersAccess?.addAccess && <Route exact path='/physical-trainers/add-new' component={() => <AddEditTrainer addType = "Physical"/>} />}
                        { trainersAccess?.addAccess && <Route exact path='/psychological-trainers/add-new' component={() => <AddEditTrainer addType = "Psychological"/>} />}
                        { trainersAccess?.updateAccess && <Route exact path='/physical-trainers/edit/:trainerId' component={() => <AddEditTrainer addType = "Physical"/>} />}
                        { trainersAccess?.updateAccess && <Route exact path='/psychological-trainers/edit/:trainerId' component={() => <AddEditTrainer addType = "Psychological"/>} />}
                        
                        {notificationAccess?.viewAccess && <Route exact path='/notifications' component={Notifications} />}
                        <Route to="*" component={PageNotFound}/>
                    </Switch>
                </div>
            </div>
        </div>
    )
}

export default OrganizationRoutes;