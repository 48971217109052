import ApiInstance from "../config/intercepter";
import Api from "../config/api";
import { resHandle } from "../helper";

// view Role
export function allTrainers() {
  return ApiInstance.get(`${Api.viewTrainers}`);
}

export function trainerListApiFun(pageNo, pageLimit, sKey, filter, sort, type) {
  return ApiInstance.get(`${Api.listTrainers}?page=${pageNo}&limit=${pageLimit}&sKey=${sKey}&sortValue=${filter}&dir=${sort}&type=${type}`);
}

export function addTrainerApiFun(payload) {
  return ApiInstance.post(`${Api.addTrainer}`, payload);
}

export function deleteTrainerApiFun(payload) {
  return ApiInstance.delete(`${Api.deleteTrainer}/${payload}`);
}

export function viewTrainerApiFun(payload) {
  return ApiInstance.get(`${Api.viewTrainer}/${payload}`);
}


export function updateTrainerApiFun(id, payload) {
  return ApiInstance.put(`${Api.updateTrainer}/${id}`, payload);
}

export function updateTrainerStatus(payload) {
  return ApiInstance.put(`${Api.updateTrainerStatus}`, payload);
}