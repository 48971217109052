import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Loader } from '../components/util/Svg';
import { Modal, Switch } from 'antd';
import { useForm } from 'react-hook-form';
import { changePasswordService } from '../services/authService';

const { confirm } = Modal;

const ChangePassword = props => {
    const password = useRef({});
    let history = useHistory()
    const [apiData, setApiData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [isValidForm, setIsValidForm] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm();
    password.current = watch("password", "");

    // organization status
    const changePassword = (data) => {
        let params = {
            oldPass: data.oldPassword,
            newPass: data.password,
        }
        changePasswordService(params)
        .then(res => {
            if (res?.statusCode === 1) {
                setErrorMessage(res?.responseData?.message);
            }
            else {
                setErrorMessage(res?.error?.responseMessage);
                setIsValidForm(false);
            }
            setLoader(false);
        })
        .catch(err => {
            console.log("error", err);
            setLoader(false);
        })
    }


    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Change Password</h2>
                    </div>
                </div>
                <div className="card  px-3 py-4">
                    <div className={`form-message ${isValidForm ? "text-success" : "text-danger"}`}>{errorMessage}</div>
                    <form onSubmit={handleSubmit(changePassword)}>
                        <div className="form-group mb-4 col-md-5">
                            <label>Old Password</label>
                            <input
                                {...register('oldPassword', { required: true })}
                                className={`form-control ${errors.oldPassword ? 'is-invalid' : ''}`}
                                placeholder="Old password"
                                type="password"
                            />
                            {errors.oldPassword && errors.oldPassword.type === "required" && <p className="input-error">Enter old password</p>}
                        </div>

                        <div className="form-group mb-4 col-md-5">
                            <label>New Password</label>
                            <input
                                {...register('password', { required: true })}
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                placeholder="New password"
                                type="password"
                            />
                            {errors.password && errors.password.type === "required" && <p className="input-error">Password is required.</p>}
                        </div>

                        <div className="form-group col-md-5">
                            <label>Confirm Password</label>
                            <input
                                {...register('confirmPassword', {
                                    required: true,
                                    validate: value =>
                                        value === password.current || "The passwords do not match"
                                })}
                                className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                placeholder="Confirm password"
                                type="password"
                            />
                            {errors.confirmPassword && errors.confirmPassword.type === "required" && <p className="input-error">Confirm Password is required.</p>}
                            {errors.confirmPassword && <p className="input-error">{errors.confirmPassword.message}</p>}

                        </div>
                        <div className="mt-3">
                            <button type="submit" className="btn btn-primary rounded-pill pl-5 pr-5">Change Password</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default ChangePassword;