import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Loader } from '../../components/util/Svg';
import { dashboardApiFun } from '../../services/dashboardService';

const Dashboard = props => {
    let history = useHistory()
    const [apiData, setApiData] = useState({})
    const [loader, setLoader] = useState(false);
    const users = useSelector(state => state.users?.user?.org?.roles ?? [])
    const dashboardAccess = users.find(role => role.sectionName === 'Dashboard');
    const usersAccess = users.find(role => role.sectionName === 'Users');
    const orgsAccess = users.find(role => role.sectionName === 'Organization Management');
    const contentAccess = users.find(role => role.sectionName === 'Content');
    useEffect(() => {
        setLoader(true)
        dashboardApiFun()
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiData(res?.responseData);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                else if (res?.error?.responseMessage === "Unauthorized Access") {
                    localStorage.clear();
                    props.history.push('/login');
                }
                setLoader(false)
            })
            .catch(err => {
                setLoader(false)
            })
    }, [])

    return (
        <div className="content-route">
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="page-header">
                <h2 className="page-title">Dashboard</h2>
            </div>
            <div className="row">
                <div className="col-lg-3 mb-4">
                    <div className="card custom-card text-center p-5 h-100">
                        <i className="fa fa-users fa-3x"></i>
                        <p className="text">
                            Users
                        </p>
                        <h4 className="count">
                            {
                                usersAccess?.viewAccess === true ?
                                    apiData?.users ?? "0" :
                                    "N/A"
                            }
                        </h4>
                    </div>
                </div>


                <div className="col-lg-3 mb-4">
                    <div className="card text-center p-5 h-100">
                        <i className="fa fa-sitemap fa-3x"></i>
                        <p className="">
                            Organisation
                        </p>
                        <div className="">
                            {
                               orgsAccess?.viewAccess === true ?
                                    <>
                                        <h5 className='d-block fw-bold'>
                                            {apiData?.organization ?? "0"}
                                        </h5>

                                        <div className='d-flex justify-content-between flex-column flex-lg-row flex-md-row'>
                                            <p className='d-block fw-bold'>
                                                Active: {apiData?.orgActive ?? "0"}
                                            </p>
                                            <p className='d-block fw-bold'>
                                                Inactive: {apiData?.orgDeactive ?? "0"}
                                            </p>
                                        </div>
                                    </>
                                    : "N/A"
                            }
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 mb-4">
                    <div className="card text-center p-5 h-100">
                        <i className="fa fa-users fa-3x"></i>
                        <p className="">
                            Organization Users
                        </p>
                        <h4 className="">
                            {
                                orgsAccess?.viewAccess === true ?
                                    apiData?.employees ?? "0" : "N/A"
                            }
                        </h4>
                    </div>
                </div>

                <div className="col-lg-3 mb-4">
                    <div className="card text-center p-5 h-100">
                        <i className="fas fa-video fa-3x"></i>
                        <p className="">
                            Waiting Content
                        </p>
                        <h4 className="">
                            {
                               contentAccess?.viewAccess === true ?
                                    apiData?.videos ?? "0" : "N/A"
                            }
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Dashboard;