import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Loader } from '../../components/util/Svg';
import { Modal, notification } from 'antd';
import Moment from 'react-moment';
import { ExclamationCircleOutlined, LoadingOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Pagination, Select } from 'antd';
import { contentListOrg, deleteContentOrg, orgStatusApiOrg, sendForApproval } from '../../services/organization/content';

const { Option } = Select;
const { confirm } = Modal;

const Content = props => {

    let history = useHistory()
    const [apiData, setApiData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [pageLimit, setPageLimit] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const [sortValue, setSortValue] = useState(1);
    const [searchKey, setSearchKey] = useState("");
    const [searchStatus, setSearchStatus] = useState(false);
    const [byAccenDecc, setByAccenDecc] = useState(0);
    const [sortByTitle, setSortByTitle] = useState(false);
    const [sortByFormat, setSortByFormat] = useState(false);
    const [sortByDate, setSortByDate] = useState(false);


    useEffect(() => {
        listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
    }, [])

    // list content
    const listContent = async (pageNo, pageLimit, searchKey, sortValue, byAccenDecc) => {
        
        contentListOrg(pageNo, pageLimit, searchKey, sortValue, byAccenDecc)
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiData(res?.responseData);
                    setPageNo(pageNo)
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false);
                setSearchStatus(false);
            })
            .catch(err => {
                console.log("content error", err);
                setLoader(false)
                setSearchStatus(false);
            })
            
    }

    // organization status
    const organizationStatus = (status, id) => {
        let params = {
            contentId: id,
            isActive: status.target.checked === true ? 1 : 0,
        }
        orgStatusApiOrg(params)
            .then(res => {
                if (res?.statusCode === 1) {
                    listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    // delete content
    const handleDelete = (id) => {
        confirm({
            title: 'Do you want to delete this content?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setLoader(true)
                deleteContentOrg(id)
                    .then(res => {
                        if (res?.statusCode === 1) {
                            if (apiData?.content.length === 1) {
                                let newPageNo = pageNo - 1;
                                listContent(newPageNo, pageLimit, searchKey, sortValue, byAccenDecc);
                            } else {
                                listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
                            }
                        }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                            localStorage.clear()
                            history.push("/login");
                        }
                        setLoader(false)
                    })
                    .catch(err => {
                        console.log("error", err);
                        setLoader(false)
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    // 
    const editContent = (id, content) => {
        history.push(`/organization-content/edit/${id}`, content, "Edit")
    }

    // handle pagination
    const handlePagination = async page => {
        await listContent(page, pageLimit, searchKey, sortValue, byAccenDecc);
        // setPageNo(page);
    };

    const handleSearch = searchEvent => {
        setPageNo(1);
        setSearchStatus(true)
        let searchKey = searchEvent.target.value;
        setSearchKey(searchKey);
        if (searchKey.length > 1) {
            listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
        } else {
            listContent(pageNo, pageLimit, '', sortValue, byAccenDecc);
        }
    }


    const handleSort = (dir, field, byTitle, format, byDate) => {
        setLoader(true)
        setPageNo(1)
        setSortByTitle(byTitle);
        setSortByDate(byDate);
        setSortByFormat(format);
        setByAccenDecc(dir)
        setSortValue(field)
        listContent(pageNo, pageLimit, searchKey, field, dir);
    }

    const sendForApprovalFun = (type, id) => {
        let param = {
            contentId: id,
            status: 3,
        }
        sendForApproval(param)
            .then((res) => {
                if (res?.statusCode === 1) {
                    openNotificationWithIcon(type, res?.responseData?.message);
                    listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
            })
            .catch((err) => {
                console.log('err', err);
            })
    }

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: 'Notification',
            description:
                msg
        });
    };


    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Travel Tips</h2>
                    </div>
                </div>
                <div className="card  px-3 pb-3">
                    <div className="my-4">
                        <div className="row justify-content-between ">
                            <div className="col-md-5">
                                <div className="search">
                                    {
                                        searchStatus ?
                                            <span className="search-spiner d-flex align-items-center">
                                                <LoadingOutlined style={{ color: "#00BFFF" }} />
                                            </span>
                                            : ""
                                    }
                                    <input className="form-control" type="text" placeholder="Search..." onChange={(e) => handleSearch(e)} />
                                </div>
                            </div>
                            <div className="col-md-6 text-end d-flex align-items-center justify-content-end">
                                <Link to="/organization-content/add" className="btn btn-primary">Add New</Link>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Title</span>
                                            {/* {
                                                sortByTitle === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "2", false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "2", true, false, false)} />
                                            } */}
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Category</span>
                                            {/* {
                                                sortByFormat === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "3", false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "3", false, true, false)} />
                                            } */}
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Created At</span>
                                            {/* {
                                                sortByDate === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "1", false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "1", false, false, true)} />
                                            } */}
                                        </div>
                                    </th>
                                    <th>Module</th>
                                    <th>Active/Inactive</th>
                                    <th>Block/Unblock</th>
                                    <th colSpan='3'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    apiData?.content?.map((content, index) => (
                                        <tr key={index}>
                                            <td>{pageLimit *(pageNo -1) +(index+1)}</td>
                                            <td>{content?.title}</td>
                                            <td>{content?.contentFormat === 0 ? "Video" : content?.contentFormat === 1 ? "Document" : "Image"}</td>
                                            <td>
                                                <Moment format="DD/MM/YYYY">
                                                    {content?.created}
                                                </Moment>
                                            </td>
                                            <td>{content?.contentSection === 0 ? "Travel tips info" : '-'}</td>
                                            <td>
                                                {
                                                    content?.status === 0 ?
                                                        <button className="btn btn-success" onClick={() => sendForApprovalFun('success', content?._id)}>Send for Approval</button> :
                                                        <div className="form-check form-switch">
                                                            <input disabled={content?.status === 2 || content?.status === 3 ? true : false} checked={content?.isActive ? true : false} onChange={(e) => organizationStatus(e, content?._id)} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                            {/* <label className="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label> */}
                                                        </div>
                                                }
                                            </td>
                                            <td>

                                                <span className={`badge p-1 
                                                ${content?.status === 0 ? "bg-secondary text-light" :
                                                        content?.status === 1 ? "text-light bg-success" :
                                                            content?.status === 2 ? "text-light bg-danger" :
                                                                content?.status === 3 ? "text-light bg-info" : ""
                                                    }`
                                                }>
                                                    {content?.status === 0 ? "No Request send" :
                                                        content?.status === 1 ? "Verified" :
                                                            content?.status === 2 ? "Declined" :
                                                                content?.status === 3 ? "Waiting for Approval" :
                                                                    ""}
                                                </span>
                                            </td>

                                            <td>

                                                <i className="cursor-pointer fa fa-trash text-danger" onClick={() => handleDelete(content?._id)}></i>
                                            </td>
                                            <td>
                                                <i className="cursor-pointer fa fa-edit text-primary" onClick={() => editContent(content?._id, content)}></i>
                                            </td>
                                            <td>
                                                <Link to={`/organization-content/view/${content?._id}`}>
                                                    <i className="cursor-pointer fa fa-eye text-info"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="text-center">
                        <Pagination showSizeChanger={false} current={pageNo} onChange={handlePagination} total={apiData.total ? apiData.total : 1} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Content;