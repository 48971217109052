import React, { useEffect, useState } from "react";
import { Loader } from "../../components/util/Svg";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  addQuestionApiFun,
  updateQuestionApiFun,
  viewQuestionApiFun,
  addQuestionApiFunSecondary,
} from "../../services/questions";
import { allAssessments } from "../../services/assessments";
import { useSelector } from "react-redux";

const AddEditQuestion = (props) => {
  const orgId = useSelector((state) => state.users?.user?.org?._id ?? "");
  const { questionId } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  let history = useHistory();
  const [loader, setLoader] = useState(false);
  const [assessmentsOptions, setAssessmentsOptions] = useState([]);

  const questionType = watch("input_type");

  useEffect(() => {
    if (questionId) {
      viewquestionApi();
    } else {
      allAssessments()
        .then((res) => {
          if (res?.statusCode === 1) {
            setAssessmentsOptions(
              res?.responseData
                .map((assRes) => {
                  return {
                    label: assRes?.title,
                    value: assRes?._id,
                    orgId: assRes.organizationId,
                    status: assRes?.status, // Assuming the status field is part of the response
                  };
                })
                .filter((ass) => ass.orgId == orgId && ass.status === 1) // Added status filter
            );
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  }, []);
  

  const viewquestionApi = () => {
    setLoader(true);
    Promise.all([viewQuestionApiFun(questionId), allAssessments()])
      .then((res) => {
        if (res[0]?.statusCode === 1) {
          const data = res[0]?.responseData?.question;
          setValue("assessmentId", data?.assessmentId);
          setValue("input_type", data?.input_type);
          setValue("question", data?.question);
          setValue("max_score", data?.max_score);
          setValue("min_score", data?.min_score);
        } else if (
          res[0]?.error?.errorCode === 46 ||
          res[0]?.error?.errorCode === 2
        ) {
          localStorage.clear();
          history.push("/login");
        }
        if (res[1]?.statusCode === 1) {
          setAssessmentsOptions(
            res[1]?.responseData
              .map((assRes) => {
                return {
                  label: assRes?.title,
                  value: assRes?._id,
                  orgId: assRes.organizationId,
                  status: assRes?.status,
                };
              })
              .filter((ass) => ass.orgId == orgId && ass.status === 1)
          );
        } else if (
          res[1]?.error?.errorCode === 46 ||
          res[1]?.error?.errorCode === 2
        ) {
          localStorage.clear();
          history.push("/login");
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const onSubmit = (data) => {
    if (!questionId) {
      setLoader(true);
      addQuestionApiFun(data)
        .then((res) => {
          if (res?.statusCode === 1) {
            history.goBack();
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          }
          setLoader(false);
        })
        .catch((err) => {
          console.log("error", err);
          setLoader(false);
        });
    } else {
      updateQuestionApiFun(questionId, data)
        .then((res) => {
          if (res?.statusCode === 1) {
            history.goBack();
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          }
          setLoader(false);
        })
        .catch((err) => {
          console.log("error", err);
          setLoader(false);
        });
    }
  };

  return (
    <>
      {loader ? (
        <div className="loader_lg">
          <Loader />
        </div>
      ) : (
        ""
      )}
      <div className="content-route">
        <div className="card mb-3">
          <div className="page-header">
            <h2 className="page-title mb-0">
              Question / {questionId ? "Edit" : "Add New"}
            </h2>
          </div>
        </div>
        <form className="card px-3 pb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="my-4">
            <div className="row justify-content-between flex-column">
              <div className="col-md-5">
                <div className="mb-4">
                  <label>Question</label>
                  <input
                    {...register("question", { required: true })}
                    className={`form-control ${
                      errors.question ? "is-invalid" : ""
                    } `}
                    type="text"
                    placeholder="Question"
                  />
                  {errors.question && errors.question.type === "required" && (
                    <p className="input-error">Question is required.</p>
                  )}
                </div>
              </div>

              <div className="col-md-5">
                <div className="mb-4">
                  <label>Question type</label>
                  <select
                    className={`form-control ${
                      errors.input_type ? "is-invalid" : ""
                    } `}
                    {...register("input_type", { required: true })}
                  >
                    <option value="">
                      Select
                    </option>
                    <option value="slider">Slider</option>
                    <option value="text">Text</option>
                  </select>
                  {errors.input_type &&
                    errors.input_type.type === "required" && (
                      <p className="input-error">Question type is required.</p>
                    )}
                </div>
              </div>

              <div className="col-md-5">
                <div className="mb-4">
                  <label>Assessment</label>
                  <select
                    className={`form-control ${
                      errors.assessmentId ? "is-invalid" : ""
                    } `}
                    {...register("assessmentId")}
                    value={watch("assessmentId") || ""}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {assessmentsOptions.map((cat, index) => (
                      <option key={`${index}-${cat.value}`} value={cat.value}>
                        {cat?.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {questionType === "slider" && (
                <>
                  <div className="col-md-5">
                    <div className="mb-4">
                      <label>Minimum Score</label>
                      <input
                        {...register("min_score", { required: true })}
                        className={`form-control ${
                          errors.min_score ? "is-invalid" : ""
                        } `}
                        type="number"
                        placeholder="Minimum Score"
                      />
                      {errors.min_score &&
                        errors.min_score.type === "required" && (
                          <p className="input-error">
                            Minimum score is required for slider questions.
                          </p>
                        )}
                    </div>
                  </div>

                  <div className="col-md-5">
                    <div className="mb-4">
                      <label>Maximum Score</label>
                      <input
                        {...register("max_score", { required: true })}
                        className={`form-control ${
                          errors.max_score ? "is-invalid" : ""
                        } `}
                        type="number"
                        placeholder="Maximum Score"
                      />
                      {errors.max_score &&
                        errors.max_score.type === "required" && (
                          <p className="input-error">
                            Maximum score is required for slider questions.
                          </p>
                        )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEditQuestion;
