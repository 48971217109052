import ApiInstance from "./../config/intercepter";
import Api from "./../config/api";
import { resHandle } from "../helper";

// view Role
export function roleListApiFun(pageNo,pageLimit,sKey, filter, sort) {
  // ?page=${payload.page}&limit=${payload.limit}
  return ApiInstance.get(`${Api.listRole}?page=${pageNo}&limit=${pageLimit}&sKey=${sKey}&sortValue=${filter}&dir=${sort}`);
}

export function addRoleApiFun(payload) {
  return ApiInstance.post(`${Api.addRole}`, payload);
}

export function deleteRoleApiFun(payload) {
  return ApiInstance.post(`${Api.deleteRole}?roleId=${payload}`);
}

export function viewRoleListApiFun(payload) {
  return ApiInstance.get(`${Api.viewRole}?roleId=${payload}`);
}

export function editRoleApiFun(payload,id) {
  return ApiInstance.post(`${Api.editRole}?roleId=${id}`, payload);
}

export function roleStatusApiFunction(payload) {
  return ApiInstance.put(`${Api.roleStatus}`, payload);
}