import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import LogoImg from '../../assets/img/logo.svg';
import { Loader } from '../../components/util/Svg';
import { organizationResetPasswordApiFun } from '../../services/organizationService';

const ResetPassword = () => {
    let history = useHistory();
    const [loader, setLoader] = useState('');
    const [isValidForm, setIsValidForm] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const password = useRef({});
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    password.current = watch("password", "");

    const onSubmit = (data) => {
        setLoader(true);
        let token = history.location.search.split("=")[1];
        let params = {
            password: data.password,
            token: token
        };
        organizationResetPasswordApiFun(params)
            .then(res => {
                if (res?.statusCode === 1) {
                    setErrorMessage(res?.responseData?.message);
                    setTimeout(() => {
                        history.push('/login');
                    }, 2000);
                } else {
                    setErrorMessage(res?.error?.responseMessage);
                    setIsValidForm(false);
                }
                setLoader(false);
            })
            .catch(err => {
                console.log("error", err);
                setLoader(false);
            });
    };

    return (
        <div className="d-flex justify-content-between align-items-center auth_wrapper">
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-4 card p-5">
                        <div className="logo-wrapper">
                            <img src={LogoImg} alt="Whoop" className="auth_logo" />
                        </div>
                        <div className={`form-message text-center ${isValidForm ? "text-success" : "text-danger"}`}>{errorMessage}</div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <label>New Password</label>
                                <input
                                    {...register('password', { required: true })}
                                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                    placeholder="New password"
                                    type="password"
                                />
                                {errors.password && errors.password.type === "required" && <p className="input-error">Password is required.</p>}
                            </div>

                            <div className="form-group">
                                <label>Confirm Password</label>
                                <input
                                    {...register('confirmPassword', {
                                        required: true,
                                        validate: value =>
                                            value === password.current || "The passwords do not match"
                                    })}
                                    className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                    placeholder="Confirm password"
                                    type="password"
                                />
                                {errors.confirmPassword && errors.confirmPassword.type === "required" && <p className="input-error">Confirm Password is required.</p>}
                                {errors.confirmPassword && <p className="input-error">{errors.confirmPassword.message}</p>}
                            </div>
                            <div className="text-center mt-3">
                                <button type="submit" className="btn btn-primary rounded-pill pl-5 pr-5">Submit</button>
                            </div>
                        </form>
                        <div className="d-flex justify-content-center mt-4">
                            <small>
                                <Link to="/login">Back to login</Link>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;
