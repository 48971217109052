import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Modal, Switch } from 'antd';
import { useForm } from 'react-hook-form';
// import { changePasswordService } from '../services/authService';
import { Loader } from '../util/Svg';
import { useDispatch, useSelector } from 'react-redux';
import { organizationProfileService } from '../../services/authService';
import { userDetailAction } from '../../_actions';

const { confirm } = Modal;

const Profile = props => {
    let history = useHistory();
    let { userId } = useParams();
    const dispatch = useDispatch();
    const users = useSelector(state => state.users)
    const organization = users?.user?.org
    const organizationId = users?.user?.org?._id ?? ''
    const [apiData, setApiData] = useState([]);
    const [loader, setLoader] = useState(false);
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    // organization status
    const handleProfile = (data) => {
        let admins = {
            firstName: data?.adminFirstName,
            lastName: data?.adminLastName,
            email: data?.adminEmail,
            phone: data?.adminPhone
        }
        let adminData = JSON.stringify(admins)
        let params = {
            organizationId: userId,
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            orgName: data?.orgName,
            phone: data?.orgPhone,
            adminDetails: adminData
        }

        organizationProfileService(params)
            .then(res => {
                if (res?.statusCode === 1) {
                    dispatch(userDetailAction(userId))
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                else {
                }
                setLoader(false);
            })
            .catch(err => {
                console.log("error", err);
                setLoader(false);
            })
    }

    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Update Profile</h2>
                    </div>
                </div>
                <div className="card  px-3 py-4">
                    {/* <div className={`form-message ${isValidForm ? "text-success" : "text-danger"}`}>{errorMessage}</div> */}
                    <form onSubmit={handleSubmit(handleProfile)}>
                        {
                            organization?.type == 1 || organization?.type == 2 || organization?.type == 0 ?
                                <>
                                    <div className="form-group mb-4 col-md-5">
                                        <label>FirstName</label>
                                        <input
                                            {...register('firstName', { required: true })}
                                            className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                                            placeholder="Full Name"
                                            defaultValue={organization?.firstName}
                                        />
                                        {errors.firstName && errors.firstName.type === "required" && <p className="input-error">Firstname is required</p>}
                                    </div>

                                    <div className="form-group mb-4 col-md-5">
                                        <label>LastName</label>
                                        <input
                                            {...register('lastName', { required: true })}
                                            className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                                            placeholder="Full Name"
                                           defaultValue={organization?.lastName}
                                        />
                                        {errors.lastName && errors.lastName.type === "required" && <p className="input-error">Lastname is required</p>}
                                    </div>
                                </>
                                :
                                <>
                                    <div className="form-group mb-4 col-md-5">
                                        <label>Organisation Name</label>
                                        <input
                                            {...register('orgName', { required: true })}
                                            className={`form-control ${errors.orgName ? 'is-invalid' : ''}`}
                                            placeholder="Full Name"
                                            defaultValue={organization?.orgName}
                                        />
                                        {errors.orgName && errors.orgName.type === "required" && <p className="input-error">Firstname is required</p>}
                                    </div>
                                    <div className="form-group mb-4 col-md-5">
                                        <label>Orgnization Contact No</label>
                                        <input
                                            {...register('orgPhone', { required: true })}
                                            className={`form-control ${errors.orgPhone ? 'is-invalid' : ''}`}
                                            placeholder="Organization Contact No."
                                            defaultValue={organization?.phone}
                                        />
                                        {errors.orgPhone && errors.orgPhone.type === "required" && <p className="input-error">Organization phone is required</p>}
                                    </div>
                                </>
                        }
                        <div className="form-group mb-4 col-md-5">
                            <label>Email</label>
                            <input
                                {...register('email', { required: true })}
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                placeholder="Email"
                                defaultValue={organization?.email}
                                disabled
                            />
                            {errors.email && errors.email.type === "required" && <p className="input-error">Email is required.</p>}
                        </div>

                        {
                            organization?.type == 3 ?
                                <>
                                    <h4 className="mt-5">Organisation Admin</h4>
                                    <hr />
                                    <div className="form-group mb-4 col-md-5">
                                        <label>Admin FirstName</label>
                                        <input
                                            {...register('adminFirstName', { required: true })}
                                            className={`form-control ${errors.adminFirstName ? 'is-invalid' : ''}`}
                                            placeholder="Full Name"
                                            defaultValue={organization?.admin?.firstName}
                                        />
                                        {errors.adminFirstName && errors.adminFirstName.type === "required" && <p className="input-error">Admin firstname is required</p>}
                                    </div>

                                    <div className="form-group mb-4 col-md-5">
                                        <label>Admin LastName</label>
                                        <input
                                            {...register('adminLastName', { required: true })}
                                            className={`form-control ${errors.AdminLastName ? 'is-invalid' : ''}`}
                                            placeholder="Full Name"
                                            defaultValue={organization?.admin?.lastName}
                                        />
                                        {errors.AdminLastName && errors.AdminLastName.type === "required" && <p className="input-error">Admin lastname is required</p>}
                                    </div>

                                    <div className="form-group mb-4 col-md-5">
                                        <label>Admin Contact No</label>
                                        <input
                                            {...register('adminPhone', { required: true })}
                                            className={`form-control ${errors.adminPhone ? 'is-invalid' : ''}`}
                                            placeholder="Full Name"
                                            defaultValue={organization?.admin?.phone}
                                        />
                                        {errors.adminPhone && errors.adminPhone.type === "required" && <p className="input-error">Admin phone is required</p>}
                                    </div>

                                    <div className="form-group mb-4 col-md-5">
                                        <label>Admin Email</label>
                                        <input
                                            {...register('adminEmail', { required: true })}
                                            className={`form-control ${errors.adminEmail ? 'is-invalid' : ''}`}
                                            placeholder="Email"
                                            defaultValue={organization?.admin?.email}
                                            disabled
                                        />
                                        {errors.adminEmail && errors.adminEmail.type === "required" && <p className="input-error">Admin email is required.</p>}
                                    </div>
                                </> : null
                        }


                        <div className="mt-3">
                            <button type="submit" className="btn btn-primary rounded-pill ps-5 pe-5">Update Profile</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default Profile;