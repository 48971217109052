import React, { useEffect, useState } from "react";
import { Loader } from "../../components/util/Svg";
import { useForm } from "react-hook-form";
import {
  addAdminApiFun,
  roleListAdmiApiFun,
  updateAdminListApiFun,
} from "../../services/adminService";
import { organizationListApiFun } from "../../services/organizationService";
import { useHistory, useParams } from "react-router-dom";
import { Modal } from "antd";

const AddEditAdmin = (props) => {
  const { adminId } = useParams();
  let history = useHistory();
  const [loader, setLoader] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [orgList, setOrgList] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: props?.history?.location?.state?.firstName
        ? props?.history?.location?.state?.firstName
        : "",
      lastName: props?.history?.location?.state?.lastName
        ? props?.history?.location?.state?.lastName
        : "",
      email: props?.history?.location?.state?.email
        ? props?.history?.location?.state?.email
        : "",
      rolename: props?.history?.location?.state?.roleName
        ? props?.history?.location?.state?.roleId
        : "",
      organization: props?.history?.location?.state?.organizationId || "",
      organizationName: props?.history?.location?.state?.organizationName || "",
    },
  });

  const onSubmit = (data) => {
    setLoader(true);
    if (!adminId) {
      let params = {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        roleId: data.rolename,
      };
      addAdminApiFun(params)
        .then((res) => {
          if (res?.statusCode === 1) {
            history.push("/administration");
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          } else {
            openNotificationError(res?.error?.responseMessage);
          }
          setLoader(false);
        })
        .catch((err) => {
          console.log("error", err);
          setLoader(false);
        });
    } else {
      let params = {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        roleId: data.rolename,
        adminId: adminId,
      };

      updateAdminListApiFun(params, adminId)
        .then((res) => {
          if (res?.statusCode === 1) {
            history.push("/administration");
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          } else if (res?.statusCode === 0) {
            openNotificationError(res?.error?.errors?.message);
          }
        })
        .catch((err) => {
          console.log("error", err);
          setLoader(false);
        });
    }
  };

  // error Notification
  const openNotificationError = (message) => {
    Modal.error({
      title: "Error",
      content: message,
    });
  };

  // list role
  useEffect(() => {
    listRole();
    setValue("object", { firstName: "test" });
  }, []);

  const listRole = () => {
    setLoader(true);
    roleListAdmiApiFun()
      .then((res) => {
        if (res?.statusCode === 1) {
          // Filter out the role with the specific ID
          const filteredRoles = res?.responseData?.roles?.filter(
            (role) => role._id !== "616d4368d7d71139f649441c"
          );
          
          setRoleList(filteredRoles);
          organizationList(); // Call organizationList() here
        } else if (
          res?.error?.errorCode === 46 ||
          res?.error?.errorCode === 2
        ) {
          localStorage.clear();
          history.push("/login");
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log("Role error", err);
        setLoader(false);
      });
  };
  

  const organizationList = () => {
    setLoader(true);
    organizationListApiFun(1, 0, "", "", 0)
      .then((res) => {
        if (res?.statusCode === 1) {
          setOrgList(res?.responseData?.orgs);
        } else if (
          res?.error?.errorCode === 46 ||
          res?.error?.errorCode === 2
        ) {
          localStorage.clear();
          history.push("/login");
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log("Role error", err);
        setLoader(false);
      });
  };

  return (
    <>
      {loader ? (
        <div className="loader_lg">
          <Loader />
        </div>
      ) : (
        ""
      )}
      <div className="content-route">
        <div className="card mb-3">
          <div className="page-header">
            <h2 className="page-title mb-0">
              Dashboard Admin / {adminId ? "Edit" : "Add"} Admin
            </h2>
          </div>
        </div>
        <form className="card px-3 pb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="my-4">
            <div className="row justify-content-between flex-column">
              <div className="col-md-5">
                <div className="mb-4">
                  <label>First Name</label>
                  <input
                    {...register("firstName", { required: true })}
                    // defaultValue={adminId && props?.history?.location?.state?.name}
                    className={`form-control ${
                      errors.firstName ? "is-invalid" : ""
                    } `}
                    type="text"
                    placeholder="Enter Admin Name"
                  />
                  {errors.firstName && errors.firstName.type === "required" && (
                    <p className="input-error">Firstname is required.</p>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="mb-4">
                  <label>Last Name</label>
                  <input
                    {...register("lastName", { required: true })}
                    // defaultValue={adminId && props?.history?.location?.state?.name}
                    className={`form-control ${
                      errors.lastName ? "is-invalid" : ""
                    } `}
                    type="text"
                    placeholder="Enter Admin Name"
                  />
                  {errors.lastName && errors.lastName.type === "required" && (
                    <p className="input-error">Lastname is required.</p>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="mb-4">
                  <label>Email</label>
                  <input
                    // defaultValue={adminId && props?.history?.location?.state?.email}
                    {...register("email", {
                      required: true,
                      pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                    })}
                    className={`form-control ${
                      errors.email ? "is-invalid" : ""
                    } `}
                    type="text"
                    placeholder="Enter Email"
                  />
                  {errors.email && errors.email.type === "required" && (
                    <p className="input-error">Email is required.</p>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <p className="input-error">Invalid email address.</p>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="mb-4">
                  <label>Rolename</label>
                  <select
                    className={`form-control ${
                      errors.rolename ? "is-invalid" : ""
                    } `}
                    {...register("rolename", { required: true })}
                    // defaultValue={props?.history?.location?.state?.roleId}
                  >
                    <option value="" disabled selected>
                      Select Rolename
                    </option>
                    {roleList.map((role, index) => (
                      <option
                        key={index}
                        selected={
                          role?._id === props?.history?.location?.state?.roleId
                            ? true
                            : false
                        }
                        value={
                          role?._id === props?.history?.location?.state?.roleId
                            ? props?.history?.location?.state?.roleId
                            : role?._id
                        }
                      >
                        {role?.roleName ===
                        props?.history?.location?.state?.name
                          ? props?.history?.location?.state?.name
                          : role?.roleName}
                      </option>
                    ))}
                  </select>
                  {errors.rolename && errors.rolename.type === "required" && (
                    <p className="input-error">Rolename is required.</p>
                  )}
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEditAdmin;
