import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Loader } from "../../components/util/Svg";
import { contentListApiFun } from "../../services/contentService";
import Moment from "react-moment";
import { Pagination, Select, Radio } from "antd";
import { useSelector } from "react-redux";
import { updateVideoStatusFun } from "../../services/videos";
import { updateAssessmentStatus } from "../../services/assessments";
import { updateTrainerStatus } from "../../services/trainers";
import { statusFunction } from "../../services/contentService";
const { Option } = Select;
const options = [
  { label: "Declined", value: 2 },
  { label: "Approved", value: 1 },
];

const ContentManagement = () => {
  const [apiData, setApiData] = useState([]); // Holds all types of content combined
  const [loader, setLoader] = useState(true);
  const [pageLimit, setPageLimit] = useState(7); // Pagination limit
  const [pageNo, setPageNo] = useState(1); // Current page
  const [searchKey, setSearchKey] = useState(""); // Search key
  const contentAccess = useSelector((state) =>
    state.users?.user?.org?.roles.find((role) => role.sectionName === "Content")
  );

  const history = useHistory();
  useEffect(() => {
    listContent(1, 100, "", "", 0, ""); // Fetch data on load
  }, []);

  // Fetch all data from API
  const listContent = (
    pageNo,
    pageLimit,
    searchKey,
    sortValue,
    byAccenDecc,
    selectType
  ) => {
    contentListApiFun(
      pageNo,
      pageLimit,
      searchKey,
      sortValue,
      byAccenDecc,
      selectType
    )
      .then((res) => {
        if (res?.statusCode === 1) {
          // Combine all content types into one array
          const combinedData = [...res.responseData.contents];
          setApiData(combinedData);
        } else if (
          res?.error?.errorCode === 46 ||
          res?.error?.errorCode === 2
        ) {
          localStorage.clear();
          history.push("/login");
        }
        setLoader(false);
      })
      .catch((err) => {
        console.error("Error fetching content:", err);
        setLoader(false);
      });
  };

  // Handle status change (Approval/Declined)
  const onChangeStatusRest = (id, status, type) => {
    if (type === "trainer") {
      let params = {
        trainerId: id,
        status: status,
      };
      updateTrainerStatus(params)
        .then((res) => {
          if (res?.statusCode === 1) {
            listContent(1, 100, "", "", 0, "");
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (type === "video") {
      let params = {
        trainerId: id,
        status: status,
      };
      updateVideoStatusFun(id, params)
        .then((res) => {
          console.log(res);
          if (res?.statusCode === 1) {
            listContent(1, 100, "", "", 0, "");
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    if (type === "assessment") {
      let params = {
        assessmentId: id,
        status: status,
      };
      updateAssessmentStatus(params)
        .then((res) => {
          if (res?.statusCode === 1) {
            listContent(1, 100, "", "", 0, "");
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let params = {
        contentId: id,
        status: status,
      };
      statusFunction(params)
        .then((res) => {
          if (res?.statusCode === 1) {
            listContent(1, 100, "", "", 0, "");
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Handle pagination
  const handlePagination = (page) => {
    setPageNo(page); // Set the current page
  };

  // Handle search
  const handleSearch = (searchEvent) => {
    setSearchKey(searchEvent.target.value.toLowerCase());
    setPageNo(1); // Reset to the first page after searching
  };

  // Apply search and pagination on the data
  const getFilteredData = () => {
    let filteredData = apiData;
    if (searchKey) {
      filteredData = apiData.filter(
        (item) =>
          item.title?.toLowerCase().includes(searchKey) ||
          item.orgName?.toLowerCase().includes(searchKey)
      );
    }

    const startIndex = (pageNo - 1) * pageLimit;
    const endIndex = startIndex + pageLimit;

    return filteredData.slice(startIndex, endIndex);
  };

  // Get the total count of filtered data for pagination
  const getTotalCount = () => {
    return apiData.filter(
      (item) =>
        item.title?.toLowerCase().includes(searchKey) ||
        item.orgName?.toLowerCase().includes(searchKey)
    ).length;
  };

  return (
    <>
      {loader ? (
        <div className="loader_lg">
          <Loader />
        </div>
      ) : (
        <div className="content-route">
          <div className="card mb-3">
            <div className="page-header">
              <h2 className="page-title mb-0">Content Approval</h2>
            </div>
          </div>

          <div className="card px-3 pb-3">
            <div className="my-4">
              <div className="row justify-content-between">
                <div className="col-md-5">
                  <div className="search">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search by title or org name..."
                      onChange={handleSearch}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Created At</th>
                    <th>Content Type</th>
                    <th>Organisation Name</th>
                    <th>Block/Unblock</th>
                    {contentAccess?.viewAccess && <th>View Content</th>}
                  </tr>
                </thead>
                <tbody>
                  {getFilteredData().map((item, index) => (
                    <tr key={item._id}>
                      <td>{pageLimit * (pageNo - 1) + (index + 1)}</td>
                      <td>{item?.title || "N/A"}</td>
                      <td>
                        <Moment format="DD/MM/YYYY">{item?.created}</Moment>
                      </td>
                      <td>
                        {item?.type === "content"
                          ? "Travel Tips"
                          : item?.type.charAt(0).toUpperCase() +
                            item?.type.slice(1)}
                      </td>

                      <td>{item?.orgName || "N/A"}</td>
                      <td>
                        <Radio.Group
                          options={options}
                          onChange={(e) =>
                            onChangeStatusRest(
                              item._id,
                              e.target.value,
                              item.type
                            )
                          }
                          value={item?.status}
                          optionType="button"
                        />
                      </td>
                      {contentAccess?.viewAccess && (
                        <td>
                          {item.type === "content" && (
                            <Link to={`/content-management/view/${item._id}`}>
                              <i className="cursor-pointer fa fa-eye text-info"></i>
                            </Link>
                          )}
                          {item.type === "assessment" && (
                            <Link to={`/assessments/view/${item._id}`}>
                              <i className="cursor-pointer fa fa-eye text-info"></i>
                            </Link>
                          )}
                          {item.type === "video" && (
                            <Link to={`/videos/view/${item._id}`}>
                              <i className="cursor-pointer fa fa-eye text-info"></i>
                            </Link>
                          )}
                          {item.type === "trainer" && (
                            <Link to={`/trainers/view/${item._id}`}>
                              <i className="cursor-pointer fa fa-eye text-info"></i>
                            </Link>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="text-center">
              <Pagination
                current={pageNo}
                total={getTotalCount()}
                pageSize={pageLimit}
                onChange={handlePagination}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContentManagement;
