import setting from "./settings";

export default (() => {
  return {
    // Basic Auth
    'AUTH': setting.api.AUTH,

    // Auth
    login: `${setting.api.url}admin/login`,
    logout: `${setting.api.url}admin/logout`,
    forgotPassword: `${setting.api.url}admin/forget-password`,
    resetPassword: `${setting.api.url}admin/reset-password`,
    changePassword: `${setting.api.url}organization/change/password`,
    updateProfile: `${setting.api.url}organization`,

    // Dashboard
    dashboard: `${setting.api.url}admin/Dashboard`,

    // Section
    addSection: `${setting.api.url}admin/add-section`,
    sectionList: `${setting.api.url}admin/get-all-sections`,

    // Role
    listRole: `${setting.api.url}role/list`,
    addRole: `${setting.api.url}role/createMany`,
    deleteRole: `${setting.api.url}role/delete`,
    viewRole: `${setting.api.url}role/viewOne`,
    editRole: `${setting.api.url}role/edit`,
    roleStatus: `${setting.api.url}role/status`,

    // Administration
    listAdmin: `${setting.api.url}admin/admins-list`,
    addAdmin: `${setting.api.url}admin/create-admin`,
    deleteAdmin: `${setting.api.url}admin/delete-admin`,
    viewAdmin: `${setting.api.url}admin/view-admin`,
    updateAdmin: `${setting.api.url}admin/update-admin`,
    adminStatus: `${setting.api.url}admin/status`,
    adminRoleList: `${setting.api.url}role/admin/list`,

    // content management
    listContent: `${setting.api.url}content/list`,
    listAdminContent : `${setting.api.url}content/listAdmin`,
    addContent: `${setting.api.url}content`,
    deleteContent: `${setting.api.url}content`,
    viewContent: `${setting.api.url}content`,
    updateContent: `${setting.api.url}content`,
    organizationStatus: `${setting.api.url}content/org/status`,
    individualStatus: `${setting.api.url}content/individual/status`,
    contentStatus: `${setting.api.url}content/status`,

    // categories
    listCategories: `${setting.api.url}categories/list`,
    viewCategories: `${setting.api.url}categories/view2`,
    addCategorie: `${setting.api.url}categories`,
    deleteCategorie: `${setting.api.url}categories`,
    viewCategorie: `${setting.api.url}categories`,
    updateCategorie: `${setting.api.url}categories`,

    // trainers
    listTrainers: `${setting.api.url}trainers/list`,
    viewTrainers: `${setting.api.url}trainers/view`,
    addTrainer: `${setting.api.url}trainers`,
    deleteTrainer: `${setting.api.url}trainers`,
    viewTrainer: `${setting.api.url}trainers`,
    updateTrainer: `${setting.api.url}trainers`,
    updateTrainerStatus: `${setting.api.url}trainers/updateStatus`,

    // videos
    listVideos: `${setting.api.url}videos/list`,
    listVideosSecondary: `${setting.api.url}videos/list/secondary`,
    addVideo: `${setting.api.url}videos`,
    addVideoSecondary : `${setting.api.url}videos/secondary`,
    deleteVideo: `${setting.api.url}videos`,
    viewVideo: `${setting.api.url}videos`,
    updateVideo: `${setting.api.url}videos`,
    updateVideoStatus: `${setting.api.url}videos/updateStatus`,

    // questions
    listQuestions: `${setting.api.url}questions/list`,
    listQuestionsSecondary: `${setting.api.url}questions/list/secondary`,
    addQuestion: `${setting.api.url}questions`,
    addQuestionSecondary: `${setting.api.url}questions/secondary`,
    deleteQuestion: `${setting.api.url}questions`,
    viewQuestion: `${setting.api.url}questions`,
    updateQuestion: `${setting.api.url}questions`,

    // assessments
    listAssessments: `${setting.api.url}assessments/list`,
    listAssessmentsSecondary: `${setting.api.url}assessments/list/secondary`,
    viewAssessments: `${setting.api.url}assessments/view2`,
    addAssessment: `${setting.api.url}assessments`,
    addAssessmentSecondary: `${setting.api.url}assessments/secondary`,
    deleteAssessment: `${setting.api.url}assessments`,
    viewAssessment: `${setting.api.url}assessments`,
    updateAssessment: `${setting.api.url}assessments`,
    updateAssessmentStatus: `${setting.api.url}assessments/updateStatus`,

    // questionsUsers
    listQuestionsUsers: `${setting.api.url}questionsUsers/list`,
    addQuestionsUser: `${setting.api.url}questionsUsers`,
    deleteQuestionsUser: `${setting.api.url}questionsUsers`,
    viewQuestionsUser: `${setting.api.url}questionsUsers`,
    updateQuestionsUser: `${setting.api.url}questionsUsers`,

    // organization
    organizationLogin: `${setting.api.url}organization/login`,
    organizationForgotPassword: `${setting.api.url}organization/forgot/password`,
    organizationResetPassword: `${setting.api.url}organization/reset/password`,
    listOrganization: `${setting.api.url}organization/list`,
    addOrganization: `${setting.api.url}organization`,
    updateOrganization: `${setting.api.url}organization`,
    viewOrganization: `${setting.api.url}organization`,
    orgStatus: `${setting.api.url}organization/status`,
    deleteOrganization: `${setting.api.url}organization`,
    setOrganizationPassword: `${setting.api.url}organization/set/password`,
    notification: `${setting.api.url}notification/org`,
    notificationCount: `${setting.api.url}notification/org/unread/count`,
    updateOrganizationCrisisLine: `${setting.api.url}organization/updateCrisisLine`,
    updateOrganizationWelcomeMessage: `${setting.api.url}organization/updateWelcomeMessage`,
    updateMainMessages :  `${setting.api.url}organization/updateMainMessages`,
    // userList
    listUser: `${setting.api.url}user/all/list`,
    blockUnblockUser: `${setting.api.url}user/status`,
    deleteUser: `${setting.api.url}user/delete/by/admin`,

    

    // disease
    ListDisease: `${setting.api.url}disease/list`,
    AddDisease: `${setting.api.url}disease`,
    UpdateDisease: `${setting.api.url}disease`,
    ViewDisease: `${setting.api.url}disease`,
    DeleteDisease: `${setting.api.url}disease`,
    DiseaseStatus: `${setting.api.url}disease/enable/disable`,

    // ================================== Organization panel ===================================
    // organization Dashboard
    organizationDashboard: `${setting.api.url}organization/dashboard`,

    // Employee
    employeeList: `${setting.api.url}user/employee/list`,
    employeeStatus: `${setting.api.url}user/employee/Status`,

    // Code
    codeList: `${setting.api.url}code/list1`,
    orgCodeList :`${setting.api.url}code/orgCodeList`, 
    codeStatus: `${setting.api.url}code/status`,
    codeGenerate: `${setting.api.url}code`,
    codeGenerate1: `${setting.api.url}code/gen-code`,
    deleteCode : `${setting.api.url}code`,
    deletedSelectedCodes :`${setting.api.url}code`,
    // content
    listOrgContent: `${setting.api.url}content/org/list`,
    addOrgContent: `${setting.api.url}content`,
    deleteOrgContent: `${setting.api.url}content`,
    viewOrgContent: `${setting.api.url}content`,
    updateOrgContent: `${setting.api.url}content`,
    orgContentStatus: `${setting.api.url}content/active/status`,
    orgContentSendApproval: `${setting.api.url}content/approval/status`,

    //email notifications
    listEmails : `${setting.api.url}emails/list`,
    listEmailsBasedOnOrg: `${setting.api.url}emails/list/basedOnOrg`,
    updateEmails : `${setting.api.url}emails`,
  };
})();
