import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/util/Svg';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { addContentApiFun, updateContentApiFun, viewContentApiFun, viewContentApiFunAdmin } from '../../services/contentService';
import { addCategorieApiFun, allCategories, updateCategorieApiFun, viewCategorieApiFun } from '../../services/categories';
import moment from 'moment';

const AddEditCategory = props => {
    const { categoryId } = useParams()
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
        }
    });
    let history = useHistory()
    const [loader, setLoader] = useState(false);
    const [ImageUrl, setImageUrl] = useState([]);
    const [categoriesParentOptions, setCategoriesParentOptions] = useState([]);
    const [url, setURL] = useState("");
    const [url2, setURL2] = useState("");
    const [parentId, setParentId] = useState("");
    const [pictureType, setPictureType] = useState('');
    const [fileData, setFileData] = useState(null);
    const [fileData2, setFileData2] = useState(null);
    const [thumbnail, setThumbnail] = useState('');    
    useEffect(() => {
        if (categoryId) {
            viewCategoryApi();
        }else{
            setLoader(true)
            allCategories().then(res => {
                if (res?.statusCode === 1) {
                    setCategoriesParentOptions(res?.responseData.map(catRes=> {
                        return ({label: catRes?.name, value: catRes?._id })
                    }));
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false)
            })
            .catch(err => {
                setLoader(false)
            })
        }
    }, [])


    const viewCategoryApi = () => {
        setLoader(true)
        Promise.all([viewCategorieApiFun(categoryId),allCategories()])
            .then(res => {
                if (res[0]?.statusCode === 1) {
                    const data = res[0].responseData.category;
                    setURL(data?.icon_url);
                    setURL2(data?.path_url);
                    setValue("name", data?.name);
                    setValue("description", data?.description);
                    setValue("parentId", data?.parentId);
                    setValue("position", data?.position);
                }else if (res[0]?.error?.errorCode === 46 || res[0]?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                if (res[1]?.statusCode === 1) {
                    setCategoriesParentOptions(res[1].responseData.map(catRes=> ({label: catRes?.name, value: catRes?._id }))
                    .filter(cat => cat.value != categoryId ));
                }else if (res[1]?.error?.errorCode === 46 || res[1]?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false)
            })
            .catch(err => {
                setLoader(false)
            })
    }


    // add edit content
    const onSubmit = (data) => {
        if (!categoryId) {
            setLoader(true);
            let categoryFormData = new FormData();
            let mediaData = data.category[0]
            let mediaData2 = data.category2[0]
            if(mediaData){
                setImageUrl(URL.createObjectURL(mediaData))
                categoryFormData.append('icon', mediaData);
            }
            if(mediaData2){
                setImageUrl(URL.createObjectURL(mediaData2))
                categoryFormData.append('path', mediaData2);
            }
            categoryFormData.append('name', data?.name);
            categoryFormData.append('position', data?.position);
            categoryFormData.append('parentId', data?.parentId);
            categoryFormData.append('description', data?.description);
            categoryFormData.append('created', moment().format("DD-MM-YY HH:mm:ss"));
            addCategorieApiFun(categoryFormData)
                .then(res => {

                    if (res?.statusCode === 1) {
                        history.push('/title-editors');
                    }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                        localStorage.clear()
                        history.push("/login");
                    }
                    setLoader(false);
                })
                .catch(err => {
                    console.log("error", err);
                    setLoader(false);
                })
        } else {
            let mediaData = data?.category[0];
            let mediaData2 = data?.category2[0];
            let categoryFormData = new FormData();
            if (mediaData !== undefined) {
                setImageUrl(URL.createObjectURL(mediaData))
                categoryFormData.append('icon', mediaData);
            }
            if (mediaData2 !== undefined) {
                setImageUrl(URL.createObjectURL(mediaData2))
                categoryFormData.append('path', mediaData2);
            }
            if(data?.name){
                categoryFormData.append('name', data?.name);
            }
            if(data?.parentId){
                categoryFormData.append('parentId', data?.parentId);
            }

            if(data?.position){
                categoryFormData.append('position', data?.position);
            }
            
            if(data?.description){
                categoryFormData.append('description', data?.description);
            }

            categoryFormData.append('updated', moment().format("DD-MM-YY HH:mm:ss"));

            updateCategorieApiFun(categoryId, categoryFormData)
                .then(res => {

                    if (res?.statusCode === 1) {
                        history.push('/title-editors');
                    }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                        localStorage.clear()
                        history.push("/login");
                    }
                    setLoader(false);
                })
                .catch(err => {
                    console.log("error", err);
                    setLoader(false);
                })
        }

    }



    const handleChange = (e) => {
        if (e.target.files[0]) {
            let mediaType = e.target.files[0]?.type?.split("/")[0] === "application" ? "document" : e.target.files[0]?.type?.split("/")[0]
            setValue("contentFormat", mediaType)
            setPictureType(mediaType);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setFileData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const handleChange2 = (e) => {
        if (e.target.files[0]) {
            let mediaType = e.target.files[0]?.type?.split("/")[0] === "application" ? "document" : e.target.files[0]?.type?.split("/")[0]
            setValue("contentFormat", mediaType)
            setPictureType(mediaType);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setFileData2(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const handleInputChanges = (event) => {
        const value = event.target.value;
        setValue("parentId", value);
      };

    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Title Editor / {categoryId ? "Edit" : "Add New"}</h2>
                    </div>
                </div>
                <form className="card px-3 pb-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="my-4">
                        <div className="row justify-content-between flex-column">
                            <div className="col-md-5">
                                <div className="mb-4">
                                    <label>Name</label>
                                    <input
                                        {...register('name', { required: true })}
                                        className={`form-control ${errors.name ? 'is-invalid' : ''} `} type="text" placeholder="Title Editor Name" />
                                    {errors.name && errors.name.type === "required" && <p className="input-error">Name is required.</p>}
                                </div>
                            </div>

                            <div className="col-md-5">
                                <div className="mb-4">
                                    <label>Description</label>
                                    <textarea
                                        {...register('description', { required: false })}
                                        className={`form-control ${errors.description ? 'is-invalid' : ''} `} type="text" placeholder="Description..." />
                                    {errors.description && errors.description.type === "required" && <p className="input-error">Description is required.</p>}
                                </div>
                            </div>
                            

                            <div className="col-md-5">
                                <div className="mb-4">
                                    <label>Parent Title Editor</label>
                                    <select className={`form-control ${errors.parentId ? 'is-invalid' : ''} `}
                                        {...register('parentId', { required: false })}
                                        value={watch('parentId') || ''}

                                    >
                                        <option value="" disabled selected>Select </option>
                                        {categoriesParentOptions.map((cat, index) => <option key={`${index}-${cat.value}`} value={cat.value} >{cat?.label}</option>)}
                                    </select>
                                    {/* {errors.parentId && errors.parentId.type === "required" && <p className="input-error">Parent Category is required.</p>} */}
                                </div>
                            </div>

                            <div className="col-md-5">
                                <div className="mb-4">
                                    <label>Position</label>
                                    <input
                                        {...register('position', { required: false })}
                                        className={`form-control ${errors.position ? 'is-invalid' : ''} `} type="text" placeholder="Position Number" />
                                    {errors.position && errors.position.type === "required" && <p className="input-error">Position is required.</p>}
                                </div>
                            </div>

                            <div className="col-md-5">
                                <div className="mb-4">
                                    <div className="media-preview mb-4">
                                        {
                                            <div className="image-preview">
                                                {
                                                    fileData ? 
                                                        <img src={fileData?.split(":")[0] == "data" ? fileData : `${url}${fileData}`} className="w-100" />  :
                                                            url ? <img src={url} className="w-100" /> : null
                                                        // <img src={fileData} className="w-100" />
                                                }
                                               
                                            </div>
                                        }
                                    </div>
                                    {
                                        categoryId ?
                                            <>
                                                <label>Edit Icon</label>
                                                <input
                                                    {...register('category', { required: false })}
                                                    onChange={handleChange}
                                                    className={`form-control`} type="file" placeholder="Enter Email" />
                                            </> :
                                            <>
                                                <label>Upload Icon</label>
                                                <input
                                                    {...register('category', { required: false })}
                                                    onChange={handleChange}
                                                    className={`form-control ${errors.category ? 'is-invalid' : ''} `} type="file" placeholder="Enter Email" />
                                                {/* {errors.category && errors.category.type === "required" && <p className="input-error">Media is required.</p>} */}
                                            </>
                                    }

                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="mb-4">
                                    <div className="media-preview mb-4">
                                        {
                                            <div className="image-preview">
                                                {
                                                    fileData2 ? 
                                                        <img src={fileData2?.split(":")[0] == "data" ? fileData2 : `${url2}${fileData2}`} className="w-100" />  :
                                                            url2 ? <img src={url2} className="w-100" /> : null
                                                        // <img src={fileData} className="w-100" />
                                                }
                                               
                                            </div>
                                        }
                                    </div>
                                    {
                                        categoryId ?
                                            <>
                                                <label>Edit Media</label>
                                                <input
                                                    {...register('category2', { required: false })}
                                                    onChange={handleChange2}
                                                    className={`form-control`} type="file" placeholder="" />
                                            </> :
                                            <>
                                                <label>Upload Media</label>
                                                <input
                                                    {...register('category2', { required: false })}
                                                    onChange={handleChange2}
                                                    className={`form-control ${errors.category2 ? 'is-invalid' : ''} `} type="file" placeholder="" />
                                                {/* {errors.category2 && errors.category2.type === "required" && <p className="input-error">Media is required.</p>} */}
                                            </>
                                    }

                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddEditCategory


