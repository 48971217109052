import ApiInstance from "./../config/intercepter";
import Api from "./../config/api";
import { resHandle } from "../helper";

// view Role
export function adminListApiFun(pageNo, pageLimit, sKey, filter, sort) {
  return ApiInstance.get(`${Api.listAdmin}?page=${pageNo}&limit=${pageLimit}&sKey=${sKey}&sortValue=${filter}&dir=${sort}`);
}

export function addAdminApiFun(payload) {
  return ApiInstance.post(`${Api.addAdmin}`, payload);
}

export function deleteAdminApiFun(payload) {
  return ApiInstance.post(`${Api.deleteAdmin}`, payload);
}

export function viewAdminApiFun(payload) {
  return ApiInstance.get(`${Api.viewAdmin}?adminId=${payload}`);
}

export function updateAdminListApiFun(payload, id) {
  return ApiInstance.post(`${Api.updateAdmin}?adminId=${id}`, payload);
}

// role list
export function roleListAdmiApiFun() {
  return ApiInstance.get(`${Api.adminRoleList}`);
}

export function adminStatusApiFunction(payload) {
  return ApiInstance.put(`${Api.adminStatus}`, payload);
}