import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Loader } from '../../components/util/Svg';
import { contentListAdminApiFun, deleteContentApiFun, individualStatusApiFunction, orgStatusApiFunction, statusFunction } from '../../services/contentService';
import Moment from 'react-moment';
import { ExclamationCircleOutlined, LoadingOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Pagination, Select, Radio, Modal } from 'antd';
import { useSelector } from 'react-redux';
const { Option } = Select;
const { confirm } = Modal;
const options = [
    { label: 'Declined', value: 2 },
    { label: 'Approved', value: 1 },
];

const TravelTipsInfo = props => {
    const access = useSelector(state => state.users?.user?.org?.roles ?? [])
    const travelTipsAccess = access.find((role) => role.sectionName === "Content");
    let history = useHistory()
    const [apiData, setApiData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [pageLimit, setPageLimit] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const [sortValue, setSortValue] = useState('');
    const [searchKey, setSearchKey] = useState("");
    const [searchStatus, setSearchStatus] = useState(false);
    const [byAccenDecc, setByAccenDecc] = useState(0);
    const [sortByTitle, setSortByTitle] = useState(false);
    const [sortByFormat, setSortByFormat] = useState(false);
    const [sortByDate, setSortByDate] = useState(false);
    const [selectType, setSellectType] = useState("0");
    const [status, setStatus] = useState(null);
    const contentAccess = access.find(role => role.sectionName === 'Content');

    useEffect(() => {
        listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
    }, [])

    // list content
    const listContent = (pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType) => {
        contentListAdminApiFun(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType)
            .then(res => {
                if (res?.statusCode === 1) {
                  const filteredContents = res.responseData.content?.filter(item => item.status === 1) || [];

                  setApiData({
                      contents: filteredContents,
                      total: filteredContents.length  // Update total based on the filtered content
                  });
                    setPageNo(pageNo);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false);
                setSearchStatus(false);
            })
            .catch(err => {
                console.log("content error", err);
                setLoader(false)
                setSearchStatus(false);
            })
    }

    // organization status
    const organizationStatus = (status, id) => {
        let params = {
            contentId: id,
            isActiveOrg: status.target.checked === true ? 1 : 0,
        }
        orgStatusApiFunction(params)
            .then(res => {
                if (res?.statusCode === 1) {
                    listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    // individual status
    const individualStatus = (status, id) => {
        let params = {
            contentId: id,
            isActiveIvd: status.target.checked === true ? 1 : 0,
        }
        individualStatusApiFunction(params)
            .then(res => {
                if (res?.statusCode === 1) {
                    listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    // delete content
    const handleDelete = (id) => {
        confirm({
            title: 'Do you Want to delete this Admin?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setLoader(true)
                deleteContentApiFun(id)
                    .then(res => {
                        if (res?.statusCode === 1) {
                            if (apiData?.length === 1) {
                                let newPageNo = pageNo - 1;
                                listContent(newPageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
                            } else {
                                listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
                            }
                        }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                            localStorage.clear()
                            history.push("/login");
                        }
                        setLoader(false)
                    })
                    .catch(err => {
                        console.log("error", err);
                        setLoader(false)
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    // 
    const editContent = (id, content) => {
        history.push(`/travel-tips-info/edit/${id}`, content, "Edit")
    }

    // handle pagination
    const handlePagination = page => {
        listContent(page, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
        
    };

    const handleSearch = searchEvent => {
        setPageNo(1);
        setSearchStatus(true)
        let searchKey = searchEvent.target.value;
        setSearchKey(searchKey);
        if (searchKey.length > 1) {
            listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
        } else {
            listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
        }
    }


    // const handleSort = (dir, field, byTitle, format, byDate) => {
    //     setLoader(true)
    //     setPageNo(1)
    //     setSortByTitle(byTitle);
    //     setSortByDate(byDate);
    //     setSortByFormat(format);
    //     setByAccenDecc(dir)
    //     setSortValue(field)
    //     listContent(pageNo, pageLimit, searchKey, field, dir, selectType);
    // }

    const handleType = (value) => {
        setSellectType(value);
        listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, value);
    }

    const onChangeStatus = (event, id) => {
        let params = {
            contentId: id,
            status: event.target.value,
        }
        statusFunction(params)
            .then(res => {
                if (res?.statusCode === 1) {
                    listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };

  


    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Travel Tips</h2>
                    </div>
                </div>
                <div className="card  px-3 pb-3">
                    <div className="my-4">
                        <div className="row justify-content-between ">
                            <div className="col-md-5">
                                <div className="search">
                                    {
                                        searchStatus ?
                                            <span className="search-spiner d-flex align-items-center">
                                                <LoadingOutlined style={{ color: "#00BFFF" }} />
                                            </span>
                                            : ""
                                    }
                                    <input className="form-control" type="text" placeholder="Search..." onChange={(e) => handleSearch(e)} />
                                </div>
                            </div>

                            <div className="col-md-5 text-end d-flex align-items-center justify-content-end">
                            <div className="d-flex align-items-center me-3">
                                    <Select className="text-start" defaultValue="0" style={{ width: 120 }} onChange={handleType}>
                                        <Option value="0">Admin</Option>
                                        <Option value="1">Organisation</Option>
                                    </Select>
                                </div>
                                {
                                    travelTipsAccess?.addAccess &&
                                    <Link to="/travel-tips-info/add-new" className="btn btn-primary ml-3">Add New</Link>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Title</span>
                                            {/* {
                                                sortByTitle === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "2", false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "2", true, false, false)} />
                                            } */}
                                        </div>
                                    </th>
                                    {/* <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Content Format</span>
                                            {
                                                sortByFormat === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "3", false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "3", false, true, false)} />
                                            }
                                        </div>
                                    </th> */}
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Created At</span>
                                            {/* {
                                                sortByDate === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "1", false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "1", false, false, true)} />
                                            } */}
                                        </div>
                                    </th>
                                    <th>Content Type</th>
                                    <th>Organisation Name</th>
                                    <th>Organisation Status</th>
                                    <th>Individual Status</th>
                                    {
                                        contentAccess?.viewAccess &&
                                        <th colSpan='3'>Action</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
    {/* Render Contents */}
    {
        apiData?.contents?.map((content, index) => (
            <tr key={`content-${content._id}`}>
                <td>{pageLimit * (pageNo - 1) + (index + 1)}</td>
                <td>{content?.title}</td>
                <td>
                    <Moment format="DD/MM/YYYY">
                        {content?.created}
                    </Moment>
                </td>
                <td>{content?.contentSection === 0 ? "Travel tips info" : "-"}</td>
                <td>{content?.org?.orgName}</td>
                <td>
                    {
                        content?.status === 1 ?
                            <div className="form-check form-switch">
                                <input checked={content?.isActiveOrg ? true : false} onChange={(e) => organizationStatus(e, content?._id)} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                            </div>
                            : "N/A"
                    }
                </td>
                
                <td>
                    {
                        content?.status === 1 ?
                            <div className="form-check form-switch">
                                <input checked={content?.isActiveIvd ? true : false} onChange={(e) => individualStatus(e, content?._id)} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                            </div> :
                            "N/A"
                    }
                </td>
                {contentAccess?.deleteAccess && (
                      <td>
                          <i
                            className="cursor-pointer fa fa-trash text-danger"
                            onClick={() => handleDelete(content?._id)}
                          ></i>
                      </td>
                    )}

                    {contentAccess?.updateAccess && (
                      <td>

                          <Link to={`/travel-tips-info/edit/${content?._id}`}>
                            <i className="cursor-pointer fa fa-edit text-primary"></i>
                          </Link>
                      </td>
                    )}

                    {contentAccess?.viewAccess && (
                      <td>
                        <Link to={`/travel-tips-info/view/${content?._id}`}>
                          <i className="cursor-pointer fa fa-eye text-info"></i>
                        </Link>
                      </td>
                    )}
            </tr>
            
        ))
        
    }
    
</tbody>

                        </table>
                    </div>
                    <div className="text-center">
                        <Pagination showSizeChanger={false} current={pageNo} onChange={handlePagination} total={apiData.total ? apiData.total : 1} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default TravelTipsInfo;