import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Checkbox, Modal } from "antd";
import {
  addRoleApiFun,
  editRoleApiFun,
  viewRoleListApiFun,
} from "../../services/roleService";
import { Loader } from "../../components/util/Svg";
import { useParams } from "react-router-dom";
import { RadiusBottomrightOutlined } from "@ant-design/icons";

const CheckboxGroup = Checkbox.Group;
const dashboardOptions = ["View"];
const roleOptions = ["View", "Add", "Edit", "Delete"];
const adminOptions = ["View", "Add", "Edit", "Delete"];
const organizationOptions = ["View", "Add", "Edit", "Delete"];
const usersOptions = ["View"];
const settingsOptions = ["View", "Add", "Edit", "Delete"];
const contentOptions = ["View", "Add", "Edit", "Delete"];
const assessmentOptions = ["View", "Add", "Edit", "Delete"];
const videosOptions = ["View", "Add", "Edit", "Delete"];
const categoriesOptions = ["View", "Add", "Edit", "Delete"];
const questionOptions = ["View", "Add", "Edit", "Delete"];
const usersAnswersOptions = ["View"];
const trainersOptions = ["View", "Add", "Edit", "Delete"];
const travelDiseaseOptions = ["View", "Add", "Edit", "Delete"];
const notificationOptions = ["View", "Add", "Edit", "Delete"];

const defaultDashboardChecked = [];
const defaultChecked = ["", "", "", ""];
const defaultContent = ["", "", "", ""];
const defaultAssessment = ["", "", "", ""];
const defaultVideos = ["", "", "", ""];
const defaultCategories = ["", "", "", ""];
const defaultQuestions = ["", "", "", ""];
const defaultUsersAnswers = ["", "", "", ""];
const defaultTrainers = ["", "", "", ""];
const defaultTravelDisease = ["", "", "", ""];

const AddNewRole = () => {
  const { roleId } = useParams();
  const [roleMessageError, setRoleMessageError] = useState(false);
  const [apiData, setApiData] = useState();

  useEffect(() => {
    if (roleId !== "" || roleId !== undefined) {
      viewRole();
    }
  }, []);

  const viewRole = () => {
    setLoader(true);
    viewRoleListApiFun(roleId)
      .then((res) => {
        if (res?.statusCode === 1) {
          setApiData(res?.responseData);
          let section = res?.responseData?.result?.sections;
          let roleName = res?.responseData?.result?.result?.roleName;
          for (let index = 0; index < section.length; index++) {
            const setFrefilled = (access) => {
              if (section[index]?.addAccess === true) {
                access.push("Add");
              }
              if (section[index]?.updateAccess === true) {
                access.push("Edit");
              }
              if (section[index]?.viewAccess === true) {
                access.push("View");
              }
              if (section[index]?.deleteAccess === true) {
                access.push("Delete");
              }
            };

            const prefilled = (access, inter, all) => {
              if (access.length < 4 && access.length > 0) {
                inter(true);
              } else {
                inter(false);
              }
              if (access.length === 4) {
                all(true);
              } else {
                all(false);
              }
            };
            if (index === 0) {
              let access = [];
              setFrefilled(access);
              setCheckedDashboard(access);
              prefilled(
                access,
                setIndeterminateDashboard,
                setCheckAllDashboard
              );
            }
            if (index === 1) {
              let access = [];
              setFrefilled(access);
              setCheckedRole(access);
              prefilled(access, setIndeterminateRole, setCheckAllRole);
            }
            if (index === 2) {
              let access = [];
              setFrefilled(access);
              setCheckedAdmin(access);
              prefilled(access, setIndeterminateAdmin, setCheckAllAdmin);
            }
            if (index === 3) {
              let access = [];
              setFrefilled(access);
              setCheckedOrganization(access);
              prefilled(
                access,
                setIndeterminateOrganization,
                setCheckAllOrganization
              );
            }
            if (index === 4) {
              let access = [];
              setFrefilled(access);
              setCheckedUsers(access);
              prefilled(access, setIndeterminateUsers, setCheckAllUsers);
            }
            if (index === 5) {
              let access = [];
              setFrefilled(access);
              setCheckedSettings(access);
              prefilled(access, setIndeterminateSettings, setCheckAllSettings);
            }
            if (index === 6) {
              let access = [];
              setFrefilled(access);
              setCheckedContent(access);
              prefilled(access, setIndeterminateContent, setCheckAllContent);
            }
            if (index === 7) {
              let access = [];
              setFrefilled(access);
              setCheckedVideos(access);
              prefilled(access, setIndeterminateVideos, setCheckAllVideos);
            }
            if (index === 8) {
              let access = [];
              setFrefilled(access);
              setCheckedCategories(access);
              prefilled(
                access,
                setIndeterminateCategories,
                setCheckAllCategories
              );
            }
            if (index === 9) {
              let access = [];
              setFrefilled(access);
              setCheckedAssessment(access);
              prefilled(
                access,
                setIndeterminateAssessment,
                setCheckAllAssessment
              );
            }
            if (index === 10) {
              let access = [];
              setFrefilled(access);
              setCheckedQuestions(access);
              prefilled(
                access,
                setIndeterminateQuestions,
                setCheckAllQuestions
              );
            }
            if (index === 11) {
              let access = [];
              setFrefilled(access);
              setCheckedUsersAnswers(access);
              prefilled(
                access,
                setIndeterminateUsersAnswers,
                setCheckAllUsersAnswers
              );
            }
            if (index === 12) {
              let access = [];
              setFrefilled(access);
              setCheckedTrainers(access);
              prefilled(access, setIndeterminateTrainers, setCheckAllTrainers);
            }
            if (index === 13) {
              let access = [];
              setFrefilled(access);
              setCheckedTravelDisease(access);
              prefilled(
                access,
                setIndeterminateTravelDisease,
                setCheckAllTravelDisease
              );
            }
            if (index === 14) {
              let access = [];
              setFrefilled(access);
              setCheckedNotification(access);
              prefilled(
                access,
                setIndeterminateTravelDisease,
                setCheckAllNotification
              );
            }
          }
          setRoleName(roleName);
        } else if (
          res?.error?.errorCode === 46 ||
          res?.error?.errorCode === 2
        ) {
          localStorage.clear();
          history.push("/login");
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log("Role error", err);
        setLoader(false);
      });
  };

  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const [roleName, setRoleName] = useState("");

  const [checkedDashboard, setCheckedDashboard] = useState();
  const [indeterminateDashboard, setIndeterminateDashboard] = useState(false);
  const [checkAllDashboard, setCheckAllDashboard] = useState(false);

  const [checkedRole, setCheckedRole] = useState(defaultChecked);
  const [indeterminateRole, setIndeterminateRole] = useState(false);
  const [checkAllRole, setCheckAllRole] = useState(false);

  const [checkedAdmin, setCheckedAdmin] = useState(defaultChecked);
  const [indeterminateAdmin, setIndeterminateAdmin] = useState(false);
  const [checkAllAdmin, setCheckAllAdmin] = useState(false);

  const [checkedOrganization, setCheckedOrganization] =
    useState(defaultChecked);
  const [indeterminateOrganization, setIndeterminateOrganization] =
    useState(false);
  const [checkAllOrganization, setCheckAllOrganization] = useState(false);

  const [checkedUsers, setCheckedUsers] = useState(defaultChecked);
  const [indeterminateUsers, setIndeterminateUsers] = useState(false);
  const [checkAllUsers, setCheckAllUsers] = useState(false);

  const [checkedSettings, setCheckedSettings] = useState(defaultChecked);
  const [indeterminateSettings, setIndeterminateSettings] = useState(false);
  const [checkAllSettings, setCheckAllSettings] = useState(false);

  const [checkedContent, setCheckedContent] = useState(defaultContent);
  const [indeterminateContent, setIndeterminateContent] = useState(false);
  const [checkAllContent, setCheckAllContent] = useState(false);

  const [checkedAssessment, setCheckedAssessment] = useState(defaultAssessment);
  const [indeterminateAssessment, setIndeterminateAssessment] = useState(false);
  const [checkAllAssessment, setCheckAllAssessment] = useState(false);

  const [checkedVideos, setCheckedVideos] = useState(defaultVideos);
  const [indeterminateVideos, setIndeterminateVideos] = useState(false);
  const [checkAllVideos, setCheckAllVideos] = useState(false);

  const [checkedCategories, setCheckedCategories] = useState(defaultCategories);
  const [indeterminateCategories, setIndeterminateCategories] = useState(false);
  const [checkAllCategories, setCheckAllCategories] = useState(false);

  const [checkedQuestions, setCheckedQuestions] = useState(defaultQuestions);
  const [indeterminateQuestions, setIndeterminateQuestions] = useState(false);
  const [checkAllQuestions, setCheckAllQuestions] = useState(false);

  const [checkedUsersAnswers, setCheckedUsersAnswers] =
    useState(defaultUsersAnswers);
  const [indeterminateUsersAnswers, setIndeterminateUsersAnswers] =
    useState(false);
  const [checkAllUsersAnswers, setCheckAllUsersAnswers] = useState(false);

  const [checkedTrainers, setCheckedTrainers] = useState(defaultTrainers);
  const [indeterminateTrainers, setIndeterminateTrainers] = useState(false);
  const [checkAllTrainers, setCheckAllTrainers] = useState(false);

  const [checkedTravelDisease, setCheckedTravelDisease] =
    useState(defaultTravelDisease);
  const [indeterminateTravelDisease, setIndeterminateTravelDisease] =
    useState(false);
  const [checkAllTravelDisease, setCheckAllTravelDisease] = useState(false);

  const [checkedNotification, setCheckedNotification] =
    useState(defaultChecked);
  const [indeterminateNotification, setIndeterminateNotification] =
    useState(false);
  const [checkAllNotification, setCheckAllNotification] = useState(false);

  // dashboard function
  const onChangeDashboard = (list) => {
    setIndeterminateDashboard(
      !!list.length && list.length < dashboardOptions.length
    );
    setCheckAllDashboard(list.length === dashboardOptions.length);
    setCheckedDashboard(list);
  };

  const onCheckAllDashboard = (e) => {
    setCheckedDashboard(e.target.checked ? dashboardOptions : []);
    setIndeterminateDashboard(false);
    setCheckAllDashboard(e.target.checked);
  };

  // admin function
  const onChangeAdmin = (list) => {
    setCheckedAdmin(list);
    setIndeterminateAdmin(!!list.length && list.length < adminOptions.length);
    setCheckAllAdmin(list.length === adminOptions.length);
  };

  const onCheckAllAdmin = (e) => {
    setCheckedAdmin(e.target.checked ? adminOptions : []);
    setIndeterminateAdmin(false);
    setCheckAllAdmin(e.target.checked);
  };

  // role function
  const onChangeRole = (list) => {
    setCheckedRole(list);
    setIndeterminateRole(!!list.length && list.length < roleOptions.length);
    setCheckAllRole(list.length === roleOptions.length);
  };

  const onCheckAllRole = (e) => {
    setCheckedRole(e.target.checked ? roleOptions : []);
    setIndeterminateRole(false);
    setCheckAllRole(e.target.checked);
  };

  // organization function
  const onChangeOrganization = (list) => {
    setCheckedOrganization(list);
    setIndeterminateOrganization(
      !!list.length && list.length < organizationOptions.length
    );
    setCheckAllOrganization(list.length === organizationOptions.length);
  };

  const onCheckAllOrganization = (e) => {
    setCheckedOrganization(e.target.checked ? organizationOptions : []);
    setIndeterminateOrganization(false);
    setCheckAllOrganization(e.target.checked);
  };

  // users function
  const onChangeUsers = (list) => {
    setCheckedUsers(list);
    setIndeterminateUsers(!!list.length && list.length < usersOptions.length);
    setCheckAllUsers(list.length === usersOptions.length);
  };

  const onCheckAllUsers = (e) => {
    setCheckedUsers(e.target.checked ? usersOptions : []);
    setIndeterminateUsers(false);
    setCheckAllUsers(e.target.checked);
  };

  // Settings function
  const onChangeSettings = (list) => {
    setCheckedSettings(list);
    setIndeterminateSettings(
      !!list.length && list.length < settingsOptions.length
    );
    setCheckAllSettings(list.length === settingsOptions.length);
  };

  const onCheckAllSettings = (e) => {
    setCheckedSettings(e.target.checked ? settingsOptions : []);
    setIndeterminateSettings(false);
    setCheckAllSettings(e.target.checked);
  };

  // Content function
  const onChangeContent = (list) => {
    setCheckedContent(list);
    setIndeterminateContent(
      !!list.length && list.length < contentOptions.length
    );
    setCheckAllContent(list.length === contentOptions.length);
  };

  const onCheckAllContent = (e) => {
    setCheckedContent(e.target.checked ? contentOptions : []);
    setIndeterminateContent(false);
    setCheckAllContent(e.target.checked);
  };

  // Assessments function
  const onChangeAssessment = (list) => {
    setCheckedAssessment(list);
    setIndeterminateAssessment(
      !!list.length && list.length < assessmentOptions.length
    );
    setCheckAllAssessment(list.length === assessmentOptions.length);
  };

  const onCheckAllAssessment = (e) => {
    setCheckedAssessment(e.target.checked ? assessmentOptions : []);
    setIndeterminateAssessment(false);
    setCheckAllAssessment(e.target.checked);
  };

  // Videos function
  const onChangeVideos = (list) => {
    setCheckedVideos(list);
    setIndeterminateVideos(!!list.length && list.length < videosOptions.length);
    setCheckAllVideos(list.length === videosOptions.length);
  };

  const onCheckAllVideos = (e) => {
    setCheckedVideos(e.target.checked ? videosOptions : []);
    setIndeterminateVideos(false);
    setCheckAllVideos(e.target.checked);
  };

  // Categories function
  const onChangeCategories = (list) => {
    setCheckedCategories(list);
    setIndeterminateCategories(
      !!list.length && list.length < categoriesOptions.length
    );
    setCheckAllCategories(list.length === categoriesOptions.length);
  };

  const onCheckAllCategories = (e) => {
    setCheckedCategories(e.target.checked ? categoriesOptions : []);
    setIndeterminateCategories(false);
    setCheckAllCategories(e.target.checked);
  };

  // Questions function
  const onChangeQuestions = (list) => {
    setCheckedQuestions(list);
    setIndeterminateQuestions(
      !!list.length && list.length < questionOptions.length
    );
    setCheckAllQuestions(list.length === questionOptions.length);
  };

  const onCheckAllQuestions = (e) => {
    setCheckedQuestions(e.target.checked ? questionOptions : []);
    setIndeterminateQuestions(false);
    setCheckAllQuestions(e.target.checked);
  };

  // UsersAnswers function
  const onChangeUsersAnswers = (list) => {
    setCheckedUsersAnswers(list);
    setIndeterminateUsersAnswers(
      !!list.length && list.length < usersAnswersOptions.length
    );
    setCheckAllUsersAnswers(list.length === usersAnswersOptions.length);
  };

  const onCheckAllUsersAnswers = (e) => {
    setCheckedUsersAnswers(e.target.checked ? usersAnswersOptions : []);
    setIndeterminateUsersAnswers(false);
    setCheckAllUsersAnswers(e.target.checked);
  };

  // trainers function
  const onChangeTrainers = (list) => {
    setCheckedTrainers(list);
    setIndeterminateTrainers(
      !!list.length && list.length < trainersOptions.length
    );
    setCheckAllTrainers(list.length === trainersOptions.length);
  };

  const onCheckAllTrainers = (e) => {
    setCheckedTrainers(e.target.checked ? trainersOptions : []);
    setIndeterminateTrainers(false);
    setCheckAllTrainers(e.target.checked);
  };

  // Travel Disease function
  const onChangeTravelDisease = (list) => {
    setCheckedTravelDisease(list);
    setIndeterminateTravelDisease(
      !!list.length && list.length < travelDiseaseOptions.length
    );
    setCheckAllTravelDisease(list.length === travelDiseaseOptions.length);
  };

  const onCheckAllTravelDisease = (e) => {
    setCheckedTravelDisease(e.target.checked ? travelDiseaseOptions : []);
    setIndeterminateTravelDisease(false);
    setCheckAllTravelDisease(e.target.checked);
  };

  // Notification Management function
  const onChangeNotification = (list) => {
    setCheckedNotification(list);
    setIndeterminateNotification(
      !!list.length && list.length < notificationOptions.length
    );
    setCheckAllNotification(list.length === notificationOptions.length);
  };

  const onCheckAllNotification = (e) => {
    setCheckedNotification(e.target.checked ? notificationOptions : []);
    setIndeterminateNotification(false);
    setCheckAllNotification(e.target.checked);
  };

  const onSubmit = (data) => {
    setLoader(true);
    data.preventDefault();

    let params = {
      roleName: roleName,
      // role:roleId,
      insertArray: [
        {
          sectionName: "Dashboard",
          viewAccess: checkedDashboard?.find((element) => element === "View")
            ? true
            : false,
        },
        {
          sectionName: "Role Management",
          viewAccess: checkedRole?.find((element) => element === "View")
            ? true
            : false,
          addAccess: checkedRole?.find((element) => element === "Add")
            ? true
            : false,
          updateAccess: checkedRole?.find((element) => element === "Edit")
            ? true
            : false,
          deleteAccess: checkedRole?.find((element) => element === "Delete")
            ? true
            : false,
        },
        {
          sectionName: "Administration Management",
          viewAccess: checkedAdmin?.find((element) => element === "View")
            ? true
            : false,
          addAccess: checkedAdmin?.find((element) => element === "Add")
            ? true
            : false,
          updateAccess: checkedAdmin?.find((element) => element === "Edit")
            ? true
            : false,
          deleteAccess: checkedAdmin?.find((element) => element === "Delete")
            ? true
            : false,
        },
        {
          sectionName: "Organization Management",
          viewAccess: checkedOrganization?.find((element) => element === "View")
            ? true
            : false,
          addAccess: checkedOrganization?.find((element) => element === "Add")
            ? true
            : false,
          updateAccess: checkedOrganization?.find(
            (element) => element === "Edit"
          )
            ? true
            : false,
          deleteAccess: checkedOrganization?.find(
            (element) => element === "Delete"
          )
            ? true
            : false,
        },
        {
          sectionName: "Users",
          viewAccess: checkedUsers?.find((element) => element === "View")
            ? true
            : false,
        },
        {
          sectionName: "Settings",
          viewAccess: checkedSettings?.find((element) => element === "View")
            ? true
            : false,
          addAccess: checkedSettings?.find((element) => element === "Add")
            ? true
            : false,
          updateAccess: checkedSettings?.find((element) => element === "Edit")
            ? true
            : false,
          deleteAccess: checkedSettings?.find((element) => element === "Delete")
            ? true
            : false,
        },
        {
          sectionName: "Content",
          viewAccess: checkedContent?.find((element) => element === "View")
            ? true
            : false,
          addAccess: checkedContent?.find((element) => element === "Add")
            ? true
            : false,
          updateAccess: checkedContent?.find((element) => element === "Edit")
            ? true
            : false,
          deleteAccess: checkedContent?.find((element) => element === "Delete")
            ? true
            : false,
        },
        {
          sectionName: "Videos",
          viewAccess: checkedVideos?.find((element) => element === "View")
            ? true
            : false,
          addAccess: checkedVideos?.find((element) => element === "Add")
            ? true
            : false,
          updateAccess: checkedVideos?.find((element) => element === "Edit")
            ? true
            : false,
          deleteAccess: checkedVideos?.find((element) => element === "Delete")
            ? true
            : false,
        },
        {
          sectionName: "Categories",
          viewAccess: checkedCategories?.find((element) => element === "View")
            ? true
            : false,
          addAccess: checkedCategories?.find((element) => element === "Add")
            ? true
            : false,
          updateAccess: checkedCategories?.find((element) => element === "Edit")
            ? true
            : false,
          deleteAccess: checkedCategories?.find(
            (element) => element === "Delete"
          )
            ? true
            : false,
        },
        {
          sectionName: "Assessments",
          viewAccess: checkedAssessment?.find((element) => element === "View")
            ? true
            : false,
          addAccess: checkedAssessment?.find((element) => element === "Add")
            ? true
            : false,
          updateAccess: checkedAssessment?.find((element) => element === "Edit")
            ? true
            : false,
          deleteAccess: checkedAssessment?.find(
            (element) => element === "Delete"
          )
            ? true
            : false,
        },
        {
          sectionName: "Questions",
          viewAccess: checkedQuestions?.find((element) => element === "View")
            ? true
            : false,
          addAccess: checkedQuestions?.find((element) => element === "Add")
            ? true
            : false,
          updateAccess: checkedQuestions?.find((element) => element === "Edit")
            ? true
            : false,
          deleteAccess: checkedQuestions?.find(
            (element) => element === "Delete"
          )
            ? true
            : false,
        },
        {
          sectionName: "UsersAnswers",
          viewAccess: checkedUsersAnswers?.find((element) => element === "View")
            ? true
            : false,
          addAccess: checkedUsersAnswers?.find((element) => element === "Add")
            ? true
            : false,
          updateAccess: checkedUsersAnswers?.find(
            (element) => element === "Edit"
          )
            ? true
            : false,
          deleteAccess: checkedUsersAnswers?.find(
            (element) => element === "Delete"
          )
            ? true
            : false,
        },
        {
          sectionName: "Trainers",
          viewAccess: checkedTrainers?.find((element) => element === "View")
            ? true
            : false,
          addAccess: checkedTrainers?.find((element) => element === "Add")
            ? true
            : false,
          updateAccess: checkedTrainers?.find((element) => element === "Edit")
            ? true
            : false,
          deleteAccess: checkedTrainers?.find((element) => element === "Delete")
            ? true
            : false,
        },
        {
          sectionName: "Travel Disease Management",
          viewAccess: checkedTravelDisease?.find(
            (element) => element === "View"
          )
            ? true
            : false,
          addAccess: checkedTravelDisease?.find((element) => element === "Add")
            ? true
            : false,
          updateAccess: checkedTravelDisease?.find(
            (element) => element === "Edit"
          )
            ? true
            : false,
          deleteAccess: checkedTravelDisease?.find(
            (element) => element === "Delete"
          )
            ? true
            : false,
        },
        {
          sectionName: "Notification Management",
          viewAccess: checkedNotification?.find((element) => element === "View")
            ? true
            : false,
          addAccess: checkedNotification?.find((element) => element === "Add")
            ? true
            : false,
          updateAccess: checkedNotification?.find(
            (element) => element === "Edit"
          )
            ? true
            : false,
          deleteAccess: checkedNotification?.find(
            (element) => element === "Delete"
          )
            ? true
            : false,
        },
      ],
    };

    if (
      indeterminateDashboard ||
      indeterminateRole ||
      indeterminateAdmin ||
      indeterminateOrganization ||
      indeterminateUsers ||
      indeterminateSettings ||
      indeterminateContent ||
      indeterminateAssessment ||
      indeterminateVideos ||
      checkAllVideos ||
      indeterminateCategories ||
      checkAllCategories ||
      indeterminateQuestions ||
      checkAllQuestions ||
      indeterminateUsersAnswers ||
      checkAllUsersAnswers ||
      checkAllAssessment ||
      checkAllDashboard ||
      checkAllOrganization ||
      checkAllRole ||
      checkAllAdmin ||
      checkAllUsers ||
      checkAllSettings ||
      indeterminateTrainers ||
      checkAllTrainers ||
      checkAllContent ||
      indeterminateTravelDisease ||
      checkAllTravelDisease
    ) {
      if (roleId) {
        // edit role
        editRoleApiFun(params, roleId)
          .then((res) => {
            if (res?.statusCode === 1) {
              history.push("/role-management");
            } else if (
              res?.error?.errorCode === 46 ||
              res?.error?.errorCode === 2
            ) {
              localStorage.clear();
              history.push("/login");
            } else {
              openNotificationError(res?.error?.responseMessage);
            }
            setLoader(false);
          })
          .catch((err) => {
            setLoader(false);
            console.log("error", err);
          });
      } else {
        // add role
        addRoleApiFun(params)
          .then((res) => {
            if (res?.statusCode === 1) {
              history.push("/role-management");
            } else if (
              res?.error?.errorCode === 46 ||
              res?.error?.errorCode === 2
            ) {
              localStorage.clear();
              history.push("/login");
            } else if (res?.statusCode === 0) {
              openNotificationError(res?.error?.responseMessage);
            }
            setLoader(false);
          })
          .catch((err) => {
            setLoader(false);
            console.log("error", err);
          });
      }
    } else {
      openNotificationError("Please select at least one section");
    }
  };

  const handleRoleName = (e) => {
    setRoleName(e.target.value);
  };

  const openNotificationError = (message) => {
    Modal.error({
      title: "Error",
      content: message,
    });
  };

  return (
    <>
      <div className="content-route">
        <div className="card mb-3">
          <div className="page-header">
            <h2 className="page-title mb-0">Role Management / Add New Role</h2>
          </div>
        </div>
        <form className="card px-3 pb-4" onSubmit={onSubmit}>
          <div className="my-4 ">
            <div className="row justify-content-between ">
              <div className="col-md-5">
                <div className="search">
                  <label>Rolename</label>
                  <input
                    required={true}
                    className="form-control"
                    onChange={handleRoleName}
                    value={roleName}
                    type="text"
                    placeholder="Enter Rolename..."
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Section</th>
                  <th>Previlages</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Checkbox
                      indeterminate={indeterminateRole}
                      onChange={onCheckAllRole}
                      checked={checkAllRole}
                    ></Checkbox>
                  </td>
                  <td>Role Management</td>
                  <td>
                    <CheckboxGroup
                      name={`role_${roleOptions}`}
                      options={roleOptions}
                      value={checkedRole}
                      onChange={onChangeRole}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Checkbox
                      indeterminate={indeterminateAdmin}
                      onChange={onCheckAllAdmin}
                      checked={checkAllAdmin}
                    ></Checkbox>
                  </td>
                  <td>Administration Management</td>
                  <td>
                    <CheckboxGroup
                      name={`admin_${adminOptions}`}
                      options={adminOptions}
                      value={checkedAdmin}
                      onChange={onChangeAdmin}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Checkbox
                      indeterminate={indeterminateOrganization}
                      onChange={onCheckAllOrganization}
                      checked={checkAllOrganization}
                    ></Checkbox>
                  </td>
                  <td>Organisation Management</td>
                  <td>
                    <CheckboxGroup
                      name={`organization_${organizationOptions}`}
                      options={organizationOptions}
                      value={checkedOrganization}
                      onChange={onChangeOrganization}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Checkbox
                      indeterminate={indeterminateUsers}
                      onChange={onCheckAllUsers}
                      checked={checkAllUsers}
                    ></Checkbox>
                  </td>
                  <td>Users</td>
                  <td>
                    <CheckboxGroup
                      name={`users_${usersOptions}`}
                      options={usersOptions}
                      value={checkedUsers}
                      onChange={onChangeUsers}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Checkbox
                      indeterminate={indeterminateSettings}
                      onChange={onCheckAllSettings}
                      checked={checkAllSettings}
                    ></Checkbox>
                  </td>
                  <td>Settings</td>
                  <td>
                    <CheckboxGroup
                      name={`settings_${settingsOptions}`}
                      options={settingsOptions}
                      value={checkedSettings}
                      onChange={onChangeSettings}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Checkbox
                      indeterminate={indeterminateContent}
                      onChange={onCheckAllContent}
                      checked={checkAllContent}
                    ></Checkbox>
                  </td>
                  <td>Content</td>
                  <td>
                    <CheckboxGroup
                      name={`content_${contentOptions}`}
                      options={contentOptions}
                      value={checkedContent}
                      onChange={onChangeContent}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Checkbox
                      indeterminate={indeterminateVideos}
                      onChange={onCheckAllVideos}
                      checked={checkAllVideos}
                    ></Checkbox>
                  </td>
                  <td>Physical & Psychological Videos</td>
                  <td>
                    <CheckboxGroup
                      name={`content_${videosOptions}`}
                      options={videosOptions}
                      value={checkedVideos}
                      onChange={onChangeVideos}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Checkbox
                      indeterminate={indeterminateCategories}
                      onChange={onCheckAllCategories}
                      checked={checkAllCategories}
                    ></Checkbox>
                  </td>
                  <td>Categories</td>
                  <td>
                    <CheckboxGroup
                      name={`content_${categoriesOptions}`}
                      options={categoriesOptions}
                      value={checkedCategories}
                      onChange={onChangeCategories}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Checkbox
                      indeterminate={indeterminateAssessment}
                      onChange={onCheckAllAssessment}
                      checked={checkAllAssessment}
                    ></Checkbox>
                  </td>
                  <td>Physical & Psychological Assessments</td>
                  <td>
                    <CheckboxGroup
                      name={`content_${assessmentOptions}`}
                      options={assessmentOptions}
                      value={checkedAssessment}
                      onChange={onChangeAssessment}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Checkbox
                      indeterminate={indeterminateQuestions}
                      onChange={onCheckAllQuestions}
                      checked={checkAllQuestions}
                    ></Checkbox>
                  </td>
                  <td>Questions</td>
                  <td>
                    <CheckboxGroup
                      name={`content_${questionOptions}`}
                      options={questionOptions}
                      value={checkedQuestions}
                      onChange={onChangeQuestions}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Checkbox
                      indeterminate={indeterminateUsersAnswers}
                      onChange={onCheckAllUsersAnswers}
                      checked={checkAllUsersAnswers}
                    ></Checkbox>
                  </td>
                  <td>Users Answers</td>
                  <td>
                    <CheckboxGroup
                      name={`content_${usersAnswersOptions}`}
                      options={usersAnswersOptions}
                      value={checkedUsersAnswers}
                      onChange={onChangeUsersAnswers}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Checkbox
                      indeterminate={indeterminateTrainers}
                      onChange={onCheckAllTrainers}
                      checked={checkAllTrainers}
                    ></Checkbox>
                  </td>
                  <td>Online Professionals</td>
                  <td>
                    <CheckboxGroup
                      name={`content_${trainersOptions}`}
                      options={trainersOptions}
                      value={checkedTrainers}
                      onChange={onChangeTrainers}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Checkbox
                      indeterminate={indeterminateTravelDisease}
                      onChange={onCheckAllTravelDisease}
                      checked={checkAllTravelDisease}
                    ></Checkbox>
                  </td>
                  <td>Travel Disease Management</td>
                  <td>
                    <CheckboxGroup
                      name={`content_${travelDiseaseOptions}`}
                      options={travelDiseaseOptions}
                      value={checkedTravelDisease}
                      onChange={onChangeTravelDisease}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Checkbox
                      indeterminate={indeterminateNotification}
                      onChange={onCheckAllNotification}
                      checked={checkAllNotification}
                    ></Checkbox>
                  </td>
                  <td>Notification Management</td>
                  <td>
                    <CheckboxGroup
                      name={`notification_${notificationOptions}`}
                      options={notificationOptions}
                      value={checkedNotification}
                      onChange={onChangeNotification}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default AddNewRole;
