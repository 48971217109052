import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Loader } from "../../components/util/Svg";
import Moment from "react-moment";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Pagination, Modal } from "antd";
import { useSelector } from "react-redux";
import {
  trainerListApiFun,
  deleteTrainerApiFun,
  updateTrainerStatus
} from "../../services/trainers";
import { categorieListApiFun } from "../../services/categories";
const { confirm } = Modal;

const TrainersManagement = (props) => {
  const access = useSelector((state) => state.users?.user?.org?.roles ?? []);
  const trainersAccess = access.find((role) => role.sectionName === "Trainers");
  let history = useHistory();
  const [apiData, setApiData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [sortValue, setSortValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [searchStatus, setSearchStatus] = useState(false);
  const [byAccenDecc, setByAccenDecc] = useState(0);
  const [sortByTitle, setSortByTitle] = useState(false);
  const [sortByFormat, setSortByFormat] = useState(false);
  const [sortByDate, setSortByDate] = useState(false);
  const [selectType, setSellectType] = useState(0);
  const type = props.type;
  const physicalId = "64a94c735a2d760019ab6071";
  const psychologicalId = "64a94c7a5a2d760019ab607d";
  const idByType = type === "Physical" ? physicalId : psychologicalId;

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      setLoader(true);
      try {
        await listContent(
          pageNo,
          pageLimit,
          searchKey,
          sortValue,
          byAccenDecc,
          selectType
        );
      } finally {
        if (isMounted) setLoader(false);
      }
    };
    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  const sendForApprovalFun = (id) => {
    let params = {
        trainerId: id,
        status: 3,
    }
    updateTrainerStatus(params)
        .then((res) => {

            if (res?.statusCode === 1) {
                listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
            }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                localStorage.clear()
                history.push("/login");
            }
        })
        .catch((err) => {
            console.log('err', err);
        })
}

  const listContent = async (
    pageNo,
    pageLimit,
    searchKey,
    sortValue,
    byAccenDecc,
    selectType
  ) => {
    try {
      const res = await trainerListApiFun(
        pageNo,
        pageLimit,
        searchKey,
        sortValue,
        byAccenDecc,
        selectType
      );
      if (res?.statusCode === 1) {
        const catRes = await categorieListApiFun(1, 100, "", "", 0, 0);
        if (catRes?.statusCode === 1) {
          const allCategories = catRes?.responseData?.categories;
          const mainCategory = allCategories?.find(
            (cat) => cat.name === type || cat._id === idByType
          );
          const gatherSubCategories = (categoryId, categories) => {
            let subCategories = categories.filter(
              (cat) => cat.parent?._id === categoryId
            );
            subCategories.forEach((subCat) => {
              subCategories = subCategories.concat(
                gatherSubCategories(subCat._id, categories)
              );
            });
            return subCategories;
          };

          if (mainCategory) {
            const allRelatedCategories = [mainCategory].concat(
              gatherSubCategories(mainCategory._id, allCategories)
            );
            const categoryIds = allRelatedCategories.map((cat) => cat._id);
            const filteredData = res?.responseData.trainers.filter((item) => {
              const isCategoryMatch = categoryIds.includes(item.category._id);
              const isStatusMatch = trainersAccess?.roleName === 'SuperAdmin' ? item.status === 1 : true;
              return isCategoryMatch && isStatusMatch;
            });
            
            setApiData(filteredData);
            
          } else {
            setApiData([]);
          }
        } else {
          console.error("Error fetching categories");
          setApiData([]);
        }
      } else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
        localStorage.clear();
        history.push("/login");
      }
    } catch (err) {
      console.error("Error fetching trainers", err);
      setApiData([]);
    } finally {
      setLoader(false);
      setSearchStatus(false);
    }
  };

  const handleDelete = (id) => {
    confirm({
      title: "Do you Want to delete this Admin?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        setLoader(true);
        deleteTrainerApiFun(id)
          .then((res) => {
            if (res?.statusCode === 1) {
              if (apiData.length === 1) {
                let newPageNo = pageNo - 1;
                listContent(
                  newPageNo,
                  pageLimit,
                  searchKey,
                  sortValue,
                  byAccenDecc,
                  selectType
                );
              } else {
                listContent(
                  pageNo,
                  pageLimit,
                  searchKey,
                  sortValue,
                  byAccenDecc,
                  selectType
                );
              }
            } else if (
              res?.error?.errorCode === 46 ||
              res?.error?.errorCode === 2
            ) {
              localStorage.clear();
              history.push("/login");
            }
            setLoader(false);
          })
          .catch((err) => {
            console.log("error", err);
            setLoader(false);
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const editContent = (id, trainers) => {
    const basePath = type === 'Physical' ? '/physical-trainers/edit/' : '/psychological-trainers/edit/';
    history.push(`${basePath}${id}`, trainers, "Edit");
  };

  const handlePagination = (page) => {
    listContent(page, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
  };

  const handleSearch = (searchEvent) => {
    setPageNo(1);
    setSearchStatus(true);
    let searchKey = searchEvent.target.value;
    setSearchKey(searchKey);
    listContent(
      pageNo,
      pageLimit,
      searchKey,
      sortValue,
      byAccenDecc,
      selectType
    );
  };

  const handleSort = (dir, field, byTitle, format, byDate) => {
    setLoader(true);
    setPageNo(1);
    setSortByTitle(byTitle);
    setSortByDate(byDate);
    setSortByFormat(format);
    setByAccenDecc(dir);
    setSortValue(field);
    listContent(pageNo, pageLimit, searchKey, field, dir, selectType);
  };

  const handleType = (value) => {
    setSellectType(value);
    listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, value);
  };

  return (
    <>
      {loader ? (
        <div className="loader_lg">
          <Loader />
        </div>
      ) : (
        ""
      )}
      <div className="content-route">
        <div className="card mb-3">
          <div className="page-header">
            <h2 className="page-title mb-0">Online Professionals</h2>
          </div>
        </div>
        <div className="card  px-3 pb-3">
          <div className="my-4">
            <div className="row justify-content-between ">
              <div className="col-md-5">
                <div className="search">
                  {searchStatus ? (
                    <span className="search-spiner d-flex align-items-center">
                      <LoadingOutlined style={{ color: "#00BFFF" }} />
                    </span>
                  ) : (
                    ""
                  )}
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </div>
              {trainersAccess?.addAccess && (
                <div className="col-md-5 text-end d-flex align-items-center justify-content-end">
                  <Link
                    to={
                      type === "Physical"
                        ? "/physical-trainers/add-new"
                        : "/psychological-trainers/add-new"
                    }
                    className="btn btn-primary ml-3"
                  >
                    Add New
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Name</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Email</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Phone Number</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Organization Name</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Facebook</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Instagram</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Language</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Timezone</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Category</span>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Created At</span>
                    </div>
                  </th>
                  {trainersAccess?.roleName != "SuperAdmin" && (
                    <th>Approval Status</th>
                  )}
                  <th colSpan="3">Action</th>
                </tr>
              </thead>
              <tbody>
                {apiData?.map((trainer, index) => (
                  <tr key={trainer._id}>
                    <td>{pageLimit * (pageNo - 1) + (index + 1)}</td>
                    <td>{trainer?.firstName} {trainer?.lastName}</td>
                    <td>{trainer?.email}</td>
                    <td>{trainer?.phone}</td>
                    <td>
                      {trainer?.organizationName &&
                      trainer.organizationName.trim() !== ""
                        ? trainer.organizationName
                        : "Not assigned to an org"}
                    </td>
                    <td>
                      {trainer?.facebook ? (
                        <a href={trainer?.facebook} target="_blank">
                          View Facebook
                        </a>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      {trainer?.instagram ? (
                        <a href={trainer?.instagram} target="_blank">
                          View Instagram
                        </a>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>{trainer?.languages}</td>
                    <td>{trainer?.timezone}</td>
                    <td>{trainer?.category?.name}</td>
                    <td>
                      <Moment format="DD/MM/YYYY">{trainer?.created}</Moment>
                    </td>
                    {trainersAccess?.roleName != "SuperAdmin" && (
                      <td>
                        {trainer?.status === 0 ? (
                          <button
                            className="btn btn-success"
                            onClick={() =>
                              sendForApprovalFun(trainer?._id)
                            }
                          >
                            Send for Approval
                          </button>
                        ) : (
                          <div className="form-check form-switch">
                            <input
                              disabled={
                                trainer?.status === 2 || trainer?.status === 3
                                  ? true
                                  : false
                              }
                              checked={trainer?.isActive ? true : false}
                            //   onChange={(e) =>
                            //     organizationStatus(e, assessment?._id)
                            //   }
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                            />
                            {/* <label className="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label> */}
                          </div>
                        )}
                      </td>
                    )}
                    {trainersAccess?.roleName != "SuperAdmin" && (
                      <td>
                        <span
                          className={`badge p-1 
                                                ${
                                                  trainer?.status === 0
                                                    ? "bg-secondary text-light"
                                                    : trainer?.status === 1
                                                    ? "text-light bg-success"
                                                    : trainer?.status === 2
                                                    ? "text-light bg-danger"
                                                    : trainer?.status === 3
                                                    ? "text-light bg-info"
                                                    : ""
                                                }`}
                        >
                          {trainer?.status === 0
                            ? "No Request send"
                            : trainer?.status === 1
                            ? "Verified"
                            : trainer?.status === 2
                            ? "Declined"
                            : trainer?.status === 3
                            ? "Waiting for Approval"
                            : ""}
                        </span>
                      </td>
                    )}
                    {trainersAccess?.deleteAccess && (
                      <td>
                        <i
                          className="cursor-pointer fa fa-trash text-danger"
                          onClick={() => handleDelete(trainer?._id)}
                        ></i>
                      </td>
                    )}
                    {trainersAccess?.updateAccess && (
                      <td>
                        <i
                          className="cursor-pointer fa fa-edit text-primary"
                          onClick={() => editContent(trainer?._id, trainer)}
                        ></i>
                      </td>
                    )}
                    {trainersAccess?.viewAccess && (
                      <td>
                        <Link to={`/trainers/view/${trainer?._id}`}>
                          <i className="cursor-pointer fa fa-eye text-info"></i>
                        </Link>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="text-center">
            <Pagination
              showSizeChanger={false}
              current={pageNo}
              onChange={handlePagination}
              total={apiData.length}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainersManagement;
