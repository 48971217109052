import ApiInstance from "../../config/intercepter";
import Api from "../../config/api";


export function EmployeeListService(pageNo, pageLimit, sKey, filter, sort) {
    return ApiInstance.get(`${Api.employeeList}?page=${pageNo}&limit=${pageLimit}&sKey=${sKey}&sortValue=${filter}&dir=${sort}`);
}

export function employeeStatusService(payload) {
    return ApiInstance.put(`${Api.employeeStatus}`, payload);
}