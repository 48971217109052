import ApiInstance from "../config/intercepter";
import Api from "../config/api";
import { resHandle } from "../helper";

// view Role
export function allAssessments() {
  return ApiInstance.get(`${Api.viewAssessments}`);
}

export function assessmentListApiFun(params) {
  return ApiInstance.get(`${Api.listAssessments}?page=${params.pageNo}&limit=${params.pageLimit}&searchKey=${params.searchKey}&sortValue=${params.sortValue}&dir=${params.byAccenDecc}&type=${params.selectType}`);
}

export function assessmentListApiFunSecondary(params) {
  return ApiInstance.get(`${Api.listAssessmentsSecondary}?page=${params.pageNo}&limit=${params.pageLimit}&searchKey=${params.searchKey}&sortValue=${params.sortValue}&dir=${params.byAccenDecc}&type=${params.selectType}&secondaryAdmin=${params.secondaryAdmin}`);
}

export function addAssessmentApiFun(payload) {
  return ApiInstance.post(`${Api.addAssessment}`, payload);
}

export function addAssessmentApiFunSecondary(payload) {
  return ApiInstance.post(`${Api.addAssessmentSecondary}`, payload);
}

export function deleteAssessmentApiFun(payload) {
  return ApiInstance.delete(`${Api.deleteAssessment}/${payload}`);
}

export function viewAssessmentApiFun(payload) {
  return ApiInstance.get(`${Api.viewAssessment}/${payload}`);
}


export function updateAssessmentApiFun(id, payload) {
  return ApiInstance.put(`${Api.updateAssessment}/${id}`, payload);
}

export function updateAssessmentStatus(payload) {
  return ApiInstance.put(`${Api.updateAssessmentStatus}`, payload);
}