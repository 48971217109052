import ApiInstance from "./../config/intercepter";
import Api from "./../config/api";
import { resHandle } from "../helper";
import axios from "axios";
import settings from "../config/settings";

// login
export function organizationLoginApiFun(payload) {
  return ApiInstance.post(`${Api.organizationLogin}`, payload);
}

// forgot password
export function organizationForgotPasswordApiFun(payload) {
  return ApiInstance.post(`${Api.organizationForgotPassword}`, payload);
}

// reset Password
export function organizationResetPasswordApiFun(payload) {
  return ApiInstance.post(`${Api.organizationResetPassword}`, payload);
}

// view Role
export function organizationListApiFun(pageNo, pageLimit, sKey, filter, sort, type) {
  return ApiInstance.get(`${Api.listOrganization}?page=${pageNo}&limit=${pageLimit}&sKey=${sKey}&sortValue=${filter}&dir=${sort}`);
}

export function addOrganizationApiFun(payload) {
  return ApiInstance.post(`${Api.addOrganization}`, payload);
}

export function deleteOrganization(payload) {
  return ApiInstance.delete(`${Api.deleteOrganization}/${payload}`);
}

export function viewOrganizationApiFun(payload) {
  return ApiInstance.get(`${Api.viewOrganization}/${payload}`);
}

export function setOrganizationPasswordApiFun(password, token) {
  const headers = {
    'accessToken': token,
    'Authorization': settings.api.AUTH
  }
  return axios.post(`${Api.setOrganizationPassword}`, password, {
    headers: headers
  });
}

export function updateOrganizationApiFun(payload) {
  return ApiInstance.put(`${Api.updateOrganization}`, payload);
}

export function updateOrganizationCrisisLineFun(payload) {
  return ApiInstance.put(`${Api.updateOrganizationCrisisLine}`, payload);
}

export function updateOrganizationWelcomeMessage(payload) {
  return ApiInstance.put(`${Api.updateOrganizationWelcomeMessage}`, payload);
}

export function updateMainMessages(payload) {
  return ApiInstance.put(`${Api.updateMainMessages}`, payload);
}

export function organizationStatusApiFunction(payload) {
  return ApiInstance.put(`${Api.orgStatus}`, payload);
}

