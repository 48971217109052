import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Menu, Dropdown, Badge } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { clearToken } from "../../helper/uitility";
import { Loader } from "../../components/util/Svg";
import { DownOutlined, BellOutlined } from '@ant-design/icons';
import { userDetailAction } from "../../_actions";
import { notificationCountService, notificationService } from "../../services/authService";

const { confirm } = Modal;


const Header = ({ }) => {
    const dispatch = useDispatch();
    const [apiData, setApiData] = useState(0);
    const [apiDataList, setApiDataList] = useState([]);
    const authState = useSelector(state => state.authentication)
    const userId = authState?.user?.org?._id ?? [];
    // const userEmail = authState?.user?.org?.email ?? '';
    const { firstName, lastName, email } = authState?.user?.org;
    const userName = `${firstName} ${lastName}` ?? email ?? '';
    let history = useHistory();
    const [loader, setLoader] = useState("");
    const Logout = () => {
        setLoader(true);
        clearToken();
        history.push("/login");
        setLoader(false);
    };

    const handleUserDetail = () => {
        setLoader(true);
        dispatch(userDetailAction(userId))
            .then((data) => {
                setLoader(false);
            })
            .catch((err) => {
                console.log("error", err);
                setLoader(false);
            });
    }

    useEffect(() => {
        handleUserDetail();
    }, [])

    useEffect(() => {
        notificationCountService()
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiData(res?.responseData?.count);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
            })
            .catch((err) => {
                console.log("error notification", err)
            })

        // notification list 
        listNotification();
    }, [])

    const listNotification = () => {
        notificationService(0, 5, '', '', '')
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiDataList(res?.responseData?.notif);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false);
            })
            .catch(err => {
                console.log("notification error", err);
                setLoader(false)
            })
    }

    // handle logout
    const handleLogout = () => {
        confirm({
            title: "Do you want to logout?",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                Logout();
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    console.log("auth state", authState)
    const menu = () => {
        return (
            <Menu style={{ zIndex: 9999 }}>
                <Menu.Item key="0">
                    <Link

                        to={`/profile/${userId}`}
                    >
                        <div className="ms-auto me-4">
                            <div className="logout">
                                <i className="fas fa-user me-3"></i>
                                Profile
                            </div>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="1">
                    <Link

                        to="/change-password"
                    >
                        <div className="ms-auto me-4">
                            <div className="logout" onClick={handleLogout}>
                                <i className="fas fa-file me-3"></i>
                                Change Password
                            </div>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item key="3" >
                    <div className="ms-auto me-4">
                        <div className="logout" onClick={handleLogout}>
                            <i className="fas fa-sign-out-alt me-3"></i>
                            Logout
                        </div>
                    </div>
                </Menu.Item>
            </Menu>
        )
    }

    const NotificationList = () => {
        return (
            <Menu style={{ zIndex: 9999, }}>
                {
                    apiDataList?.map((notification, key) => (
                        <Menu.Item key={key} style={{cursor:"initial"}}>
                            {notification?.msg}
                        </Menu.Item>
                    ))
                }
                <Menu.Divider />
                <Menu.Item key="3"  >
                    <Link

                        to="/notification"
                    >
                        <div className="ms-auto">
                            <small className="logout text-center w-100 text-primary" onClick={handleLogout}>
                                See all Notification
                            </small>
                        </div>
                    </Link>
                </Menu.Item>
            </Menu>
        )
    }

    return (
        <>
            {loader ? (
                <div className="loader_lg">
                    <Loader />
                </div>
            ) : (
                ""
            )}
            <header className="header d-flex align-items-center justify-content-end">
                <div className="header-inner-wrapper ">
                    <Dropdown overlay={NotificationList} overlayStyle={{ zIndex: 9999, top: "20px" }}>
                        <a className="ant-dropdown-link d-flex align-items-center position-relative" onClick={e => e.preventDefault()}>
                            <Badge count={apiData}>
                                <BellOutlined twoToneColor="#000000" style={{ fontSize: "20px" }} />
                            </Badge>
                        </a>
                    </Dropdown>
                </div>
                <div className="header-inner-wrapper">
                    <Dropdown overlay={menu} overlayStyle={{ zIndex: 9999, top: "20px" }}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            {userName} <DownOutlined />
                        </a>
                    </Dropdown>
                </div>
            </header>
        </>
    );
};

export default Header;
