import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Loader } from '../../components/util/Svg';
import { viewRoleListApiFun } from '../../services/roleService';
import { useHistory } from 'react-router-dom';

const ViewRole = () => {
    let history = useHistory()
    const [apiData, setApiData] = useState();
    const [loader, setLoader] = useState(false);
    const { roleId } = useParams()

    useEffect(() => {
        viewRole();
    }, [])

    const viewRole = () => {
        setLoader(true)
        viewRoleListApiFun(roleId)
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiData(res?.responseData);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false)
            })
            .catch(err => {
                console.log("Role error", err);
                setLoader(false)
            })
    }

    // let { result, sections } = apiData?.result

    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Role : {apiData?.result?.result?.roleName}</h2>
                    </div>
                </div>
                <div className="card  px-3">
                    <div className="table-responsive mt-4">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th rowSpan="2">Section Name</th>
                                    <th colSpan="4">Previlages</th>
                                </tr>
                                <tr>
                                    <th>View</th>
                                    <th>Add</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {apiData?.result?.sections.map((section, index) => (
                                    <tr key={index}>
                                        <td>{section?.sectionName}</td>
                                        <td>{section?.viewAccess ? <CheckOutlined className="text-success" /> : <CloseOutlined className="text-danger" />}</td>
                                        <td>{section?.addAccess ? <CheckOutlined className="text-success" /> : <CloseOutlined className="text-danger" />}</td>
                                        <td>{section?.updateAccess ? <CheckOutlined className="text-success" /> : <CloseOutlined className="text-danger" />}</td>
                                        <td>{section?.deleteAccess ? <CheckOutlined className="text-success" /> : <CloseOutlined className="text-danger" />}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewRole;