import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Modal, Switch } from "antd";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import Moment from "react-moment";
import { Loader } from "../../components/util/Svg";
import {
  deleteRoleApiFun,
  roleListApiFun,
  roleStatusApiFunction,
} from "../../services/roleService";
import { Pagination, Select } from "antd";
import { useSelector } from "react-redux";

const { Option } = Select;
const { confirm } = Modal;

const RoleManagement = (props) => {
  const history = useHistory();
  const access = useSelector((state) => state.users?.user?.org?.roles ?? []);
  const accessAllCheck =
    access[1]?.updateAccess + access[1]?.deleteAccess + access[1]?.viewAccess;
    const roleAccess = access.find(role => role.sectionName === 'Role Management');
  const [apiData, setApiData] = useState({});
  const [loader, setLoader] = useState(true);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [sortValue, setSortValue] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [searchStatus, setSearchStatus] = useState(false);
  const [sortByName, setSortByName] = useState(false);
  const [sortByDate, setSortByDate] = useState(false);
  const [byAccenDecc, setByAccenDecc] = useState("1");
  useEffect(() => {
    listRole(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
  }, []);

  const listRole = (pageNo, pageLimit, searchKey, sortValue, byAccenDecc) => {
    roleListApiFun(pageNo, pageLimit, searchKey, sortValue, byAccenDecc)
      .then((res) => {
        if (res?.statusCode === 1) {
          const filteredRoles = res?.responseData?.roles?.filter(
            (role) => role._id !== "616d4368d7d71139f649441c"
          );
          setApiData({ roles: filteredRoles, total: res.responseData.total });
          setPageNo(pageNo);
        } else if (
          res?.error?.errorCode === 46 ||
          res?.error?.errorCode === 2
        ) {
          localStorage.clear();
          history.push("/login");
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log("Role error", err);
        setLoader(false);
        setSearchStatus(false);
      });
  };

  // delete role
  const handleDelete = (id) => {
    confirm({
      title: "Do you Want to delete this role?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        setLoader(true);
        deleteRoleApiFun(id)
          .then((res) => {
            if (res?.statusCode === 1) {
              if (apiData?.roles.length === 1) {
                let newPageNo = pageNo - 1;
                listRole(
                  newPageNo,
                  pageLimit,
                  searchKey,
                  sortValue,
                  byAccenDecc
                );
              } else {
                listRole(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
              }
              // setErrorMessage()
            } else if (
              res?.error?.errorCode === 46 ||
              res?.error?.errorCode === 2
            ) {
              localStorage.clear();
              history.push("/login");
            }
            setLoader(false);
          })
          .catch((err) => {
            console.log("Role error", err);
            setLoader(false);
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const activeInactiveRole = (status, id) => {
    let params = {
      roleId: id,
      status: status.target.checked === true ? 1 : 0,
    };
    setLoader(true);
    roleStatusApiFunction(params)
      .then((res) => {
        if (res?.statusCode === 1) {
          listRole(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
        } else if (
          res?.error?.errorCode === 46 ||
          res?.error?.errorCode === 2
        ) {
          localStorage.clear();
          history.push("/login");
        }
        setLoader(false);
      })
      .then((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const handlePagination = (page) => {
    setLoader(true);
    listRole(page, pageLimit, searchKey, sortValue, byAccenDecc);
  };

  const handleSearch = (searchEvent) => {
    setPageNo(1);
    setSearchStatus(true);
    let searchKey = searchEvent.target.value;
    setSearchKey(searchKey);
    if (searchKey.length > 1) {
      listRole(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
    } else {
      listRole(pageNo, pageLimit, "", sortValue, byAccenDecc);
    }
  };

  const handleSort = (dir, field, byName, byDate) => {
    setLoader(true);
    setPageNo(1);
    setSortByName(byName);
    setSortByDate(byDate);
    setByAccenDecc(dir);
    setSortValue(field);
    listRole(pageNo, pageLimit, searchKey, field, dir);
  };

  return (
    <>
      {loader ? (
        <div className="loader_lg">
          <Loader />
        </div>
      ) : (
        ""
      )}
      <div className="content-route">
        <div className="card mb-3">
          <div className="page-header">
            <h2 className="page-title mb-0">Role Management</h2>
          </div>
        </div>
        <div className="card  px-3  pb-3">
          <div className="my-4">
            <div className="row justify-content-between ">
              <div className="col-md-5">
                <div className="search">
                  {searchStatus ? (
                    <span className="search-spiner d-flex align-items-center">
                      <LoadingOutlined style={{ color: "#00BFFF" }} />
                    </span>
                  ) : (
                    ""
                  )}
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </div>
              {roleAccess?.addAccess && (
                <div className="col-md-6 text-end d-flex align-items-center justify-content-end">
                  <Link
                    to="/role-management/add-new-role"
                    className="btn btn-primary"
                  >
                    Add New
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Role Name</span>
                      {/* {
                                                sortByName === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "2", false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "2", true, false)} />
                                            } */}
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Created At</span>
                      {/* {
                                                sortByDate === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "1", false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "1", false, true)} />
                                            } */}
                    </div>
                  </th>
                  <th>Active/Inactive</th>
                  {accessAllCheck > 0 && <th colSpan="3">Action</th>}
                </tr>
              </thead>
              <tbody>
                {apiData?.roles?.map((role, index) => (
                  <tr key={index}>
                    <td>{pageLimit * (pageNo - 1) + (index + 1)}</td>
                    <td>{role?.roleName} </td>
                    <td>
                      <Moment format="DD/MM/YYYY">{role?.created}</Moment>
                    </td>
                    <td>
                      <div className="form-check form-switch">
                        <input
                          checked={role?.status ? true : false}
                          onChange={(e) => activeInactiveRole(e, role?._id)}
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          disabled={role?.roleName === "SuperAdmin"} // Disable if roleName is "SuperAdmin"
                        />
                        {/* <label className="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label> */}
                      </div>
                      {/* <Switch defaultChecked={role?.status ? true : false} onChange={(e) => activeInactiveRole(e, role?._id)} /> */}
                    </td>

                    {roleAccess?.deleteAccess && (
                      <td>
                        {role?.roleName === "SuperAdmin" ? (
                          <i className="fa fa-trash text-muted"></i> // Gray and non-clickable
                        ) : (
                          <i
                            className="cursor-pointer fa fa-trash text-danger"
                            onClick={() => handleDelete(role?._id)}
                          ></i> // Red and clickable
                        )}
                      </td>
                    )}

                    {roleAccess?.updateAccess && (
                      <td>
                        {role?.roleName === "SuperAdmin" ? (
                          <i className="fa fa-edit text-muted"></i> // Gray and non-clickable
                        ) : (
                          <Link to={`/role-management/edit-role/${role?._id}`}>
                            <i className="cursor-pointer fa fa-edit text-primary"></i>
                          </Link>
                        )}
                      </td>
                    )}

                    {roleAccess?.viewAccess && (
                      <td>
                        <Link to={`/role-management/view-role/${role?._id}`}>
                          <i className="cursor-pointer fa fa-eye text-info"></i>
                        </Link>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="text-center">
            <Pagination
              showSizeChanger={false}
              current={pageNo}
              onChange={handlePagination}
              total={apiData.total ? apiData.total : 1}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default RoleManagement;
