import React, { useEffect, useState } from 'react';
import { Route, Redirect, Link, useHistory, useParams } from 'react-router-dom';
import { Loader } from '../../components/util/Svg';
import { adminListApiFun, adminStatusApiFunction, deleteAdminApiFun } from '../../services/adminService';
import Moment from 'react-moment';
import { Modal, Switch } from 'antd';
import { ExclamationCircleOutlined, LoadingOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Pagination, Select } from 'antd';
import { useSelector } from 'react-redux';

const { Option } = Select;
const { confirm } = Modal;

const Administration = props => {
    const access = useSelector(state => state.users?.user?.org?.roles ?? [])
    const accessAllCheck = access[2]?.updateAccess + access[2]?.deleteAccess + access[2]?.viewAccess
    const adminAccess = access.find(role => role.sectionName === 'Administration Management');
    let history = useHistory()
    const [apiData, setApiData] = useState({});
    const [loader, setLoader] = useState(true);
    const [pageLimit, setPageLimit] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const [sortValue, setSortValue] = useState("1");
    const [searchKey, setSearchKey] = useState("");
    const [searchStatus, setSearchStatus] = useState(false);
    const [byAccenDecc, setByAccenDecc] = useState("");
    const [sortByName, setSortByName] = useState(false);
    const [sortByEmail, setSortByEmail] = useState(false);
    const [sortByDate, setSortByDate] = useState(false);
    const { id } = useParams()
    useEffect(() => {
        listAdmin(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
    }, [])

    const listAdmin = (pageNo, pageLimit, searchKey, sortValue, byAccenDecc) => {
        // setLoader(true)
        adminListApiFun(pageNo, pageLimit, searchKey, sortValue, byAccenDecc)
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiData(res?.responseData);
                    setPageNo(pageNo);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false)
                setSearchStatus(false);
            })
            .catch(err => {
                console.log("Role error", err);
                setLoader(false)
                setSearchStatus(false);
            })
    }

    // delete role
    const handleDelete = (id) => {
        confirm({
            title: 'Do you Want to delete this Admin?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setLoader(true)
                deleteAdminApiFun({ adminId: id })
                    .then(res => {
                        if (res?.statusCode === 1) {
                            listAdmin(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
                            if (apiData?.admins.length === 1) {
                                let newPageNo = pageNo - 1;
                                listAdmin(newPageNo, pageLimit, searchKey, sortValue, byAccenDecc);
                            } else {
                                listAdmin(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
                            }
                        }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                            localStorage.clear()
                            history.push("/login");
                        }
                        setLoader(false)
                    })
                    .catch(err => {
                        console.log("error", err);
                        setLoader(false)
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const editAdmin = (id, admin) => {
        history.push(`/administration/edit-admin/${id}`, admin, "Edit")
    }

    // admin status
    const activeInactiveAdmin = (status, id) => {
        
        let params = {
            adminId: id,
            status: status.target.checked === true ? 1 : 0,
        }
        adminStatusApiFunction(params)
            .then(res => {
                if (res?.statusCode === 1) {
                    listAdmin(pageNo, pageLimit, "", sortValue, byAccenDecc);
                    
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    // pagination
    const handlePagination = page => {
        listAdmin(page, pageLimit, searchKey, sortValue, byAccenDecc);
    };

    const handleSearch = searchEvent => {
        setPageNo(1);
        setSearchStatus(true)
        let searchKey = searchEvent.target.value;
        setSearchKey(searchKey);
        if (searchKey.length > 1) {
            listAdmin(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
        } else {
            listAdmin(pageNo, pageLimit, '', sortValue, byAccenDecc);
        }
    }

    const handleSort = (dir, field, byName, byEmail, byDate) => {
        setLoader(true)
        setPageNo(1)
        setSortByName(byName);
        setSortByEmail(byEmail);
        setSortByDate(byDate);
        setByAccenDecc(dir)
        setSortValue(field)
        listAdmin(pageNo, pageLimit, searchKey, field, dir);
    }

    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Dashboard Admin</h2>
                    </div>
                </div>
                <div className="card  px-3  pb-3">
                    <div className="my-4">
                        <div className="row justify-content-between ">
                            <div className="col-md-5">
                                <div className="search">
                                    {
                                        searchStatus ?
                                            <span className="search-spiner d-flex align-items-center">
                                                <LoadingOutlined style={{ color: "#00BFFF" }} />
                                            </span>
                                            : ""
                                    }
                                    <input className="form-control" type="text" placeholder="Search..." onChange={(e) => handleSearch(e)} />
                                </div>
                            </div>
                            {
                                adminAccess?.addAccess &&
                                <div className="col-md-6 text-end d-flex align-items-center justify-content-end">
                                    <Link to="/administration/add-admin" className="btn btn-primary">Add New</Link>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Name</span>
                                            {/* {
                                                sortByName === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "2", false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "2", true, false, false)} />
                                            } */}
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Email</span>
                                            {/* {
                                                sortByEmail === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "3", false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "3", true, true, false)} />
                                            } */}
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Created At</span>
                                            {/* {
                                                sortByDate === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "1", false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "1", true, true, true)} />
                                            } */}
                                        </div>
                                    </th>
                                    <th>Role</th>
                                    <th>Block/Unblock</th>
                                    {
                                        adminAccess?.viewAccess || adminAccess?.deleteAccess || adminAccess?.updateAccess &&
                                        <th colSpan='3'>Action</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    apiData?.admins?.map((admin, index) => (
                                        <tr disabled={admin?.role?.status === 0 ? false : true} key={index} className={admin?.role?.status === 0 ? "disabled-row" : ""}>
                                            <td>{pageLimit *(pageNo -1) +(index+1)}</td>
                                            <td>{`${admin?.firstName} ${admin?.lastName}`}</td>
                                            <td>{admin?.email}</td>
                                            <td>
                                                <Moment format="DD/MM/YYYY">
                                                    {admin?.created}
                                                </Moment>
                                            </td>
                                            <td>{admin?.roleName}</td>
                                            <td>
                                                <div className="form-check form-switch">
                                                    <input disabled={admin?.role?.status === 1 ? false : true} checked={admin?.status ? true : false} onChange={(e) => activeInactiveAdmin(e, admin?._id)} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                    {/* <label className="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label> */}
                                                </div>
                                                {/* <Switch defaultChecked={admin?.status ? true : false} onChange={(e) => activeInactiveAdmin(e, admin?._id)} /> */}
                                            </td>
                                            {
                                                adminAccess?.deleteAccess &&
                                                <td>
                                                    {admin?.role?.status === 0 ? "N/A" :
                                                        <i className="cursor-pointer fa fa-trash text-danger" onClick={() => handleDelete(admin?._id)}></i>
                                                    }
                                                </td>
                                            }

                                            {
                                                adminAccess?.updateAccess &&
                                                <td>
                                                    {admin?.role?.status === 0 ? "N/A" :
                                                        <i className="cursor-pointer fa fa-edit text-primary" onClick={() => editAdmin(admin?._id, admin)}></i>
                                                    }
                                                </td>
                                            }

                                            {
                                                adminAccess?.viewAccess &&
                                                <td>
                                                    {admin?.role?.status === 0 ? "N/A" :
                                                        <Link to={`/administration/view/${admin?._id}`}>
                                                            <i className="cursor-pointer fa fa-eye text-info"></i>
                                                        </Link>
                                                    }
                                                </td>
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="text-center">
                        <Pagination showSizeChanger={false} current={pageNo} onChange={handlePagination} total={apiData.total ? apiData.total : 1} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Administration;