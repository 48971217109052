import React, { useEffect, useState } from "react";
import { Loader } from "../../components/util/Svg";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { allCategories } from "../../services/categories";
import { categorieListApiFun } from "../../services/categories";
import {
  addVideoApiFun,
  addVideoApiFunSecondary,
  updateVideoApiFun,
  viewVideoApiFun,
} from "../../services/videos";
import { useSelector } from "react-redux";
import { Modal } from "antd";
const AddEditVideo = (props) => {
  const { videoId } = useParams();
  const { confirm } = Modal;
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  let history = useHistory();
  const [loader, setLoader] = useState(false);
  const [ImageUrl, setImageUrl] = useState([]);
  const [categoriesParentOptions, setCategoriesParentOptions] = useState([]);
  const [url, setURL] = useState("");
  const [pictureType, setPictureType] = useState("");
  const [fileData, setFileData] = useState(null);
  const [thumbnail, setThumbnail] = useState("");
  const secondaryAdmin = useSelector(
    (state) => state.users?.user?.org?.admin._id ?? ""
  );
  const type = props.addType;

  const filterCategoriesByType = (allCategories, type) => {
    // Define the specific subcategories to return based on the type
    const physicalSubCategories = ["cardio", "strength", "flexibility", "dance"];
    const psychologicalSubCategories = [
      "pre-departure preparation",
      "regulating emotions",
      "relationships & connection",
      "dealing with trauma"
    ];
  
    // Find the main parent category based on the type
    const parentCategory = allCategories.find(
      (cat) => cat.name.toLowerCase() === type.toLowerCase() && !cat.parent
    );
  
    // Function to recursively gather all subcategories under the parent category
    const gatherSubCategories = (categoryId, categories) => {
      let subCategories = categories.filter(
        (cat) => cat.parent?._id === categoryId
      );
      subCategories.forEach((subCat) => {
        subCategories = subCategories.concat(
          gatherSubCategories(subCat._id, categories)
        );
      });
      return subCategories;
    };
  
    if (parentCategory) {
      // Gather all related subcategories
      const allRelatedSubCategories = gatherSubCategories(
        parentCategory._id,
        allCategories
      );
  
      // Filter the subcategories based on the type
      let filteredSubCategories = [];
      if (type.toLowerCase() === "physical") {
        filteredSubCategories = allRelatedSubCategories.filter((cat) =>
          physicalSubCategories.includes(cat.name.toLowerCase())
        );
      } else if (type.toLowerCase() === "psychological") {
        filteredSubCategories = allRelatedSubCategories.filter((cat) =>
          psychologicalSubCategories.includes(cat.name.toLowerCase())
        );
      }
  
      // Map to the required format
      return filteredSubCategories.map((cat) => ({
        label: cat.name,
        value: cat._id,
      }));
    }
  
    // Return an empty array if no matching parent category is found
    return [];
  };
  

  useEffect(() => {
    if (videoId) {
      viewVideoApi();
    } else {
      setLoader(true);
      categorieListApiFun(1, 100, "", "", 0, 0)
        .then((res) => {
          if (res?.statusCode === 1) {
            const allCategories = res?.responseData?.categories;
            const filteredCategories = filterCategoriesByType(
              allCategories,
              type
            );

            // Set the filtered categories as options
            setCategoriesParentOptions(filteredCategories);
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  }, [videoId, type]);

  const viewVideoApi = () => {
    setLoader(true);
    Promise.all([
      viewVideoApiFun(videoId),
      categorieListApiFun(1, 100, "", "", 0, 0),
    ])
      .then((res) => {
        if (res[0]?.statusCode === 1) {
          const data = res[0].responseData.video;
          setFileData(data?.path_url);
          setValue("title", data?.title);
          setValue("description", data?.description);
          setValue("categoryId", data?.categoryId);
          setThumbnail(data?.th_media);
        } else if (
          res[0]?.error?.errorCode === 46 ||
          res[0]?.error?.errorCode === 2
        ) {
          localStorage.clear();
          history.push("/login");
        }

        if (res[1]?.statusCode === 1) {
          const allCategories = res[1]?.responseData?.categories;
          // Ensure type is correctly applied here
          const filteredCategories = filterCategoriesByType(allCategories, type);

          // Set the filtered categories as options
          setCategoriesParentOptions(filteredCategories);

      }  else if (
          res[1]?.error?.errorCode === 46 ||
          res[1]?.error?.errorCode === 2
        ) {
          localStorage.clear();
          history.push("/login");
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  // add edit content
  const onSubmit = (data) => {
    if (!videoId) {
      setLoader(true);
      let videoFormData = new FormData();
      let mediaData = data.video[0];
      if (mediaData) {
        setImageUrl(URL.createObjectURL(mediaData));
        videoFormData.append("media", mediaData);
      }

      videoFormData.append("title", data?.title);
      videoFormData.append("categoryId", data?.categoryId);
      videoFormData.append("description", data?.description);
      addVideoApiFun(videoFormData)
        .then((res) => {
          if (res?.statusCode === 1) {
            history.goBack();
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          }
          setLoader(false);
        })
        .catch((err) => {
          console.log("error", err);
          setLoader(false);
        });
    } else {
      let mediaData = data?.video[0];
      let videoFormData = new FormData();
      if (mediaData !== undefined) {
        // setImageUrl(URL.createObjectURL(mediaData));
        videoFormData.append("media", mediaData);
      }
      if (data?.title) {
        videoFormData.append("title", data?.title);
      }
      if (data?.categoryId) {
        videoFormData.append("categoryId", data?.categoryId);
      }

      if (data?.description) {
        videoFormData.append("description", data?.description);
      }

      updateVideoApiFun(videoId, videoFormData)
        .then((res) => {
          if (res?.statusCode === 1) {
            history.goBack();
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          }
          setLoader(false);
        })
        .catch((err) => {
          console.log("error", err);
          setLoader(false);
        });
    }
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
        const file = e.target.files[0];
        const fileType = file.type.split("/")[0];

        // Ensure the file is a video
        if (fileType !== "video") {
          Modal.error({
            title: 'Invalid File Type',
            content: 'Please upload a valid video file.',
        });
            e.target.value = null; // Clear the file input
            return;
        }

        setFileData(null);
        setValue("contentFormat", fileType); // Assuming contentFormat is used for video
        setPictureType(fileType);

        const reader = new FileReader();
        reader.addEventListener("load", () => {
            setFileData(reader.result);
        });
        reader.readAsDataURL(file);
    }
};


  return (
    <>
      {loader ? (
        <div className="loader_lg">
          <Loader />
        </div>
      ) : (
        ""
      )}
      <div className="content-route">
        <div className="card mb-3">
          <div className="page-header">
            <h2 className="page-title mb-0">
              Video / {videoId ? "Edit" : "Add New"}
            </h2>
          </div>
        </div>
        <form className="card px-3 pb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="my-4">
            <div className="row justify-content-between flex-column">
              <div className="col-md-5">
                <div className="mb-4">
                  <label>Title</label>
                  <input
                    {...register("title", { required: true })}
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    } `}
                    type="text"
                    placeholder="Video Title"
                  />
                  {errors.name && errors.name.type === "required" && (
                    <p className="input-error">Title is required.</p>
                  )}
                </div>
              </div>

              <div className="col-md-5">
                <div className="mb-4">
                  <label>Description</label>
                  <textarea
                    {...register("description", { required: false })}
                    className={`form-control ${
                      errors.description ? "is-invalid" : ""
                    } `}
                    type="text"
                    placeholder="Description..."
                  />
                  {errors.description &&
                    errors.description.type === "required" && (
                      <p className="input-error">Description is required.</p>
                    )}
                </div>
              </div>

              <div className="col-md-5">
                <div className="mb-4">
                  <label>Category</label>
                  <select
                    className={`form-control ${
                      errors.categoryId ? "is-invalid" : ""
                    } `}
                    {...register("categoryId", { required: false })}
                    value={watch("categoryId") || ""}
                  >
                    <option value="" disabled selected>
                      Select{" "}
                    </option>
                    {categoriesParentOptions.map((cat, index) => (
                      <option key={`${index}-${cat.value}`} value={cat.value}>
                        {cat?.label}
                      </option>
                    ))}
                  </select>
                  {/* {errors.parentId && errors.parentId.type === "required" && <p className="input-error">Parent Category is required.</p>} */}
                </div>
              </div>
              <div className="col-md-5">
                <div className="mb-4">
                  <div className="media-preview mb-4">
                    {
                      <div className="image-preview">
                        {fileData ? (
                          <video
                            controls
                            className="video-preview"
                            poster={thumbnail}
                          >
                            <source src={fileData} id="video_here"></source>
                          </video>
                        ) : null}
                      </div>
                    }
                  </div>
                  {videoId ? (
                    <>
                      <label>Edit Media</label>
                      <input
                        {...register("video", { required: false })}
                        onChange={handleChange}
                        className={`form-control`}
                        type="file"
                        placeholder="Enter Email"
                      />
                    </>
                  ) : (
                    <>
    <label>Upload Media</label>
    <input
        {...register("video", { required: false })}
        onChange={handleChange}
        className={`form-control ${
            errors.video ? "is-invalid" : ""
        } `}
        type="file"
        placeholder="Enter Email"
    />
    {/* {errors.category && errors.category.type === "required" && <p className="input-error">Media is required.</p>} */}
</>

                  )}
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEditVideo;
