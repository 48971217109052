import Routes from "../adminPanel/Routes/Routes";
import OrganizationRoutes from "../organizationPanel/OrganizationRoute/OrganizationRoutes";

const LayoutWrapper = () => {

    let userType = localStorage.getItem("userType");
    return (
        <>
            {
                userType == "2" || userType == "3" ?
                    <OrganizationRoutes /> :
                    <Routes />
            }
        </>
    );
}

export default LayoutWrapper;