import {
  CodepenOutlined,
  DashboardOutlined,
  FileSyncOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SendOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  LineChartOutlined,
  NotificationOutlined,
  CodeOutlined
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Loader } from "../../components/util/Svg";
import LogoImg from "../../assets/img/logo.svg";
import Accordion from "react-bootstrap/Accordion";
import { useSelector } from "react-redux";
const Sidebar = ({ sidebarState, sidebarFun }) => {
  const [loader, setLoader] = useState(false);
  const access = useSelector(state => state.users?.user?.org?.roles ?? [])
  const trainersAccess = access.find(role => role.sectionName === 'Trainers');
  return (
    <>
      {loader ? (
        <div className="loader_lg">
          <Loader />
        </div>
      ) : (
        ""
      )}
      <div className={`sidebar ${sidebarState ? "hide" : ""}`}>
        <div className="logo-wrapper d-flex align-items-center">
          <img src={LogoImg} className="logo-img" />
          <h3 className="logo-text">Travel Companion</h3>
        </div>
        <div className="menu-toggle-icons">
          {sidebarState ? (
            <MenuUnfoldOutlined className="toggle-icon" onClick={sidebarFun} />
          ) : (
            <MenuFoldOutlined className="toggle-icon" onClick={sidebarFun} />
          )}
        </div>
        <div className="sidebar-scroll">
          <ul className="list-unstyled items">
            <li className="item">
              <NavLink className="nav-item" to="/organization-dashboard">
                <span className="menu-icon d-flex align-items-center">
                  <DashboardOutlined />
                </span>
                <span className="menu_text">Dashboard</span>
              </NavLink>
            </li>
            <li className="item">
              <NavLink className="nav-item" to="/employee">
                <span className="menu-icon d-flex align-items-center">
                  <UsergroupAddOutlined />
                </span>
                <span className="menu_text">Employee List</span>
              </NavLink>
            </li>
            <li className="item">
              <NavLink className="nav-item" to="/codes">
                <span className="menu-icon d-flex align-items-center">
                  <CodeOutlined />
                </span>
                <span className="menu_text">Your Codes</span>
              </NavLink>
            </li>
            <Accordion>
              <Accordion.Item
                eventKey="1"
                className="thc-sidebar__accordion--item"
              >
                <Accordion.Header> Physical </Accordion.Header>
                <Accordion.Body>
                  <li className="item">
                    <NavLink className="nav-item" to="/physical-videos">
                      <span className="menu-icon">
                        <i className="fa fa-video" />
                      </span>
                      <span className="menu_text">Videos</span>
                    </NavLink>
                  </li>

                  <li className="item">
                    <NavLink className="nav-item" to="/physical-assessments">
                      <span className="menu-icon">
                        <i className="fa fa-table"></i>
                      </span>
                      <span className="menu_text">Assessments</span>
                    </NavLink>
                  </li>

                  {trainersAccess?.viewAccess && (
                      <li className="item">
                        <NavLink
                          className="nav-item"
                          to="/physical-trainers"
                        >
                          <span className="menu-icon">
                            <i className="fa fa-users"></i>
                          </span>
                          <span className="menu_text">
                            Online Professionals
                          </span>
                        </NavLink>
                      </li>
                    )}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item
                eventKey="2"
                className="thc-sidebar__accordion--item"
              >
                <Accordion.Header> Psychological </Accordion.Header>
                <Accordion.Body>
                  <li className="item">
                    <NavLink className="nav-item" to="/psychological-videos">
                      <span className="menu-icon">
                        <i className="fa fa-video" />
                      </span>
                      <span className="menu_text">Videos</span>
                    </NavLink>
                  </li>

                  <li className="item">
                    <NavLink
                      className="nav-item"
                      to="/psychological-assessments"
                    >
                      <span className="menu-icon">
                        <i className="fa fa-table"></i>
                      </span>
                      <span className="menu_text">Assessments</span>
                    </NavLink>
                  </li>

                  {trainersAccess?.viewAccess && (
                      <li className="item">
                        <NavLink
                          className="nav-item"
                          to="/psychological-trainers"
                        >
                          <span className="menu-icon">
                            <i className="fa fa-users"></i>
                          </span>
                          <span className="menu_text">
                            Online Professionals
                          </span>
                        </NavLink>
                      </li>
                    )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <li className="item">
              <NavLink className="nav-item" to="/organization-content">
                <span className="menu-icon d-flex align-items-center">
                  <FileSyncOutlined />
                </span>
                <span className="menu_text">Travel Tips</span>
              </NavLink>
            </li>

            {/* <li className="item">
              <NavLink className="nav-item" to="/trainers">
                <span className="menu-icon">
                  <i className="fa fa-users"></i>
                </span>
                <span className="menu_text"> Online Professionals </span>
              </NavLink>
            </li> */}

            <li className="item">
              <NavLink className="nav-item" to="/crisis-line">
                <span className="menu-icon d-flex align-items-center">
                  <LineChartOutlined />
                </span>
                <span className="menu_text">Crisis Line</span>
              </NavLink>
            </li>

            <li className="item">
              <NavLink className="nav-item" to="/notifications">
                <span className="menu-icon d-flex align-items-center">
                  <NotificationOutlined />
                </span>
                <span className="menu_text">Notifications</span>
              </NavLink>
            </li>

            
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
