import { organizationUserDetailService } from '../services/authService';
import { userConstants } from '../_constants';
// import { alertActions } from './';


export function userDetailAction(payload) {
    return dispatch => {
        dispatch(request(payload));
        return organizationUserDetailService(payload)
            .then(
                data => {
                    if (data?.statusCode === 1) {
                        dispatch(success(data?.responseData));
                    }else{
                        dispatch(failure(data.error));
                    }
                    return data;
                },
                error => {
                    if (error?.statusCode === 0) {
                        dispatch(failure(error));
                        console.log("error---->", error)
                    }
                    return error;
                }
            )
        
    };

    function request(data) { return { type: userConstants.GETALL_REQUEST, data } }
    function success(data) { return { type: userConstants.GETALL_SUCCESS, data } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

