import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Loader } from '../../components/util/Svg';
import { viewQuestionApiFun } from '../../services/questions';


const ViewQuestion = (props) => {
    let history = useHistory()
    const [apiData, setApiData] = useState();
    // const [mediaURL, setMediaURL] = useState();
    const [loader, setLoader] = useState(false);
    const { questionId } = useParams()
    // const [thumbnail, setThumbnail] = useState('');

    useEffect(() => {
        if(questionId){
            viewQuestionApi();
        }
    }, [])

    const viewQuestionApi = () => {
        setLoader(true)
        viewQuestionApiFun(questionId)
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiData(res.responseData.question);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false)
            })
            .catch(err => {
                setLoader(false)
            })
    }


    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Question / Details</h2>
                    </div>
                </div>
                <div className="card  px-3">
                    <div className="my-4">
                        <div className="row justify-content-between ">
                            <div className="col-md-8">
                                <div className="detailed">
                                    <p className="">
                                        <span className="label d-block">Question: </span>
                                        <span className="value fw-bold">{apiData?.question}</span>
                                    </p>
                                    <p className="">
                                        <span className="label d-block">Question Type: </span>
                                        <span className="value fw-bold">{apiData?.input_type}</span>
                                    </p>
                                    <p className="">
                                        <span className="label d-block">Assessment: </span>
                                        <span className="value fw-bold">{apiData?.assessment?.title}</span>

                                    </p>
                                    <p className="">
                                        <span className="label d-block">Max Score : </span>
                                        <span className="value fw-bold">{apiData?.min_score}</span>

                                    </p>
                                    <p className="">
                                        <span className="label d-block">Min Score : </span>
                                        <span className="value fw-bold">{apiData?.max_score}</span>

                                    </p>
                                    <p className="">
                                        <span className="label d-block">Created At: </span>
                                        <span className="value fw-bold">
                                            <Moment format="DD/MM/YYYY">
                                                {apiData?.updated}
                                            </Moment>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewQuestion;