import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Loader } from '../../components/util/Svg';
import { viewContentOrg } from '../../services/organization/content';


const ViewContent = (props) => {
    let history = useHistory()
    const [apiData, setApiData] = useState();
    const [mediaURL, setMediaURL] = useState();
    const [loader, setLoader] = useState(false);
    const { contentId } = useParams()
    const [thumbnail, setThumbnail] = useState('');
    useEffect(() => {
        viewContentApi();
    }, [])

    const viewContentApi = () => {
        setLoader(true)
        viewContentOrg(contentId)
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiData(res.responseData.content);
                    setMediaURL(res?.responseData?.bP);
                    setThumbnail(`${res?.responseData?.th_bP}${res?.responseData?.content?.th_media}`);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false)
            })
            .catch(err => {
                setLoader(false)
            })
    }

    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Content / Details</h2>
                    </div>
                </div>
                <div className="card  px-3">
                    <div className="my-4">
                        <div className="row justify-content-between ">
                            <div className="col-md-8">
                                <div className="detailed">
                                    <p className="">
                                        <span className="label d-block">Title: </span>
                                        <span className="value fw-bold">{apiData?.title}</span>
                                    </p>
                                    <p className="">
                                        <span className="label d-block">Type: </span>
                                        <span className="value fw-bold">{apiData?.type === 0 ? "Admin" : "Organisation"}</span>
                                    </p>
                                    <p className="">
                                        <span className="label d-block">Module: </span>
                                        <span className="value fw-bold">{apiData?.contentSection === 0? "Section One":"Section Two"}</span>
                                    </p>
                                    <p className="">
                                        <span className="label d-block">Category: </span>
                                        <span className="value fw-bold">{apiData?.contentFormat === 0? "Video" : apiData?.contentFormat === 1? "Document" : "Image"}</span>
                                    </p>
                                    <div className="media-preview">
                                        {/* <span className="label d-block">media: </span> */}
                                        {
                                            apiData?.contentFormat ===  0 ?
                                                <video controls className="video-preview w-100" poster={thumbnail}>
                                                    {
                                                        <source src={`${mediaURL}${apiData?.media}`} id="video_here"></source>
                                                    }
                                                </video> :
                                                apiData?.contentFormat === 2 ?
                                                    <div className="image-preview">
                                                        {
                                                            <img src={`${mediaURL}${apiData?.media}`} className="w-100" />
                                                        }
                                                    </div>
                                                    :
                                                    apiData?.contentFormat === 1 ?
                                                        <div className="document-preview d-flex align-items-center justify-content-center">
                                                            <i className="fa fa-file fa-3x"></i>
                                                        </div>
                                                        : null

                                        }
                                    </div>
                                    <p className="">
                                        <span className="label d-block">Created At: </span>
                                        <span className="value fw-bold">
                                            <Moment format="DD/MM/YYYY">
                                                {apiData?.updated}
                                            </Moment>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewContent;