import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Loader } from '../../components/util/Svg';
import { viewAssessmentApiFun } from '../../services/assessments';


const ViewAssessment = (props) => {
    let history = useHistory()
    const [apiData, setApiData] = useState();
    // const [mediaURL, setMediaURL] = useState();
    const [loader, setLoader] = useState(false);
    const { assessmentId } = useParams()
    // const [thumbnail, setThumbnail] = useState('');

    useEffect(() => {
        if(assessmentId){
            viewAssessmentApi();
        }
    }, [])

    const viewAssessmentApi = () => {
        setLoader(true)
        viewAssessmentApiFun(assessmentId)
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiData(res.responseData.assessment);
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false)
            })
            .catch(err => {
                setLoader(false)
            })
    }


    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">assessment / Details</h2>
                    </div>
                </div>
                <div className="card  px-3">
                    <div className="my-4">
                        <div className="row justify-content-between ">
                            <div className="col-md-8">
                                <div className="detailed">
                                    <p className="">
                                        <span className="label d-block">Title: </span>
                                        <span className="value fw-bold">{apiData?.title}</span>
                                    </p>
                                    <p className="">
                                        <span className="label d-block">Category: </span>
                                        <span className="value fw-bold">{apiData?.category?.name}</span>
                                    </p>
                                    <p className="">
                                        <span className="label d-block">Organization: </span>
                                        <span className="value fw-bold">{`${apiData?.organization?.orgName ?? ""}`}</span>

                                    </p>
                                    <p className="">
                                        <span className="label d-block">Before Description: </span>
                                        <span className="value fw-bold">{`${apiData?.beforeDescription ?? ""}`}</span>
                                    </p>
                                    <p className="">
                                        <span className="label d-block">After Description: </span>
                                        <span className="value fw-bold">{`${apiData?.afterDescription ?? ""}`}</span>
                                    </p>
                                    <p className="">
                                        <span className="label d-block">Is Active: </span>
                                        <span className="value fw-bold">{`${apiData?.isActive ? "True" : "False"}`}</span>

                                    </p>
                                    <p className="">
                                        <span className="label d-block">Created At: </span>
                                        <span className="value fw-bold">
                                            <Moment format="DD/MM/YYYY">
                                                {apiData?.updated}
                                            </Moment>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewAssessment;