import ApiInstance from "../config/intercepter";
import Api from "../config/api";
import { resHandle } from "../helper";

// view Role
export function questionListApiFun(params) {
  return ApiInstance.get(
    `${Api.listQuestions}?page=${params.pageNo}&limit=${params.pageLimit}&sKey=${params.searchKey}&sortValue=${params.sortValue}&dir=${params.byAccenDecc}&type=${params.selectType}`
  );
}

export function questionListApiFunSecondary(params) {
  return ApiInstance.get(
    `${Api.listQuestionsSecondary}?page=${params.pageNo}&limit=${params.pageLimit}&sKey=${params.searchKey}&sortValue=${params.sortValue}&dir=${params.byAccenDecc}&type=${params.selectType}&secondaryAdmin=${params.secondaryAdmin}`
  );
}

export function addQuestionApiFun(payload) {
  return ApiInstance.post(`${Api.addQuestion}`, payload);
}

export function addQuestionApiFunSecondary(payload) {
  return ApiInstance.post(`${Api.addQuestionSecondary}`, payload);
}



export function deleteQuestionApiFun(payload) {
  return ApiInstance.delete(`${Api.deleteQuestion}/${payload}`);
}

export function viewQuestionApiFun(payload) {
  return ApiInstance.get(`${Api.viewQuestion}/${payload}`);
}


export function updateQuestionApiFun(id, payload) {
  return ApiInstance.put(`${Api.updateQuestion}/${id}`, payload);
}