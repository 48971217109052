import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Loader } from '../../components/util/Svg';
import Moment from 'react-moment';
import { ExclamationCircleOutlined, LoadingOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Pagination, Select, Radio, Modal } from 'antd';
import { useSelector } from 'react-redux';
import {questionListApiFun, deleteQuestionApiFun, questionListApiFunSecondary } from '../../services/questions';
import { allCategories } from '../../services/categories';
const { Option } = Select;

const { confirm } = Modal;
const options = [
    { label: 'Declined', value: 2 },
    { label: 'Approved', value: 1 },
];

const QuestionsManagement = props => {
    const access = useSelector(state => state.users?.user?.org?.roles ?? [])
    const questionsAccess = access.find(role => role.sectionName === 'Questions');
    let history = useHistory()
    const [apiData, setApiData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [pageLimit, setPageLimit] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const [sortValue, setSortValue] = useState('');
    const [searchKey, setSearchKey] = useState("");
    const [searchStatus, setSearchStatus] = useState(false);
    const [byAccenDecc, setByAccenDecc] = useState(0);
    const [sortByTitle, setSortByTitle] = useState(false);
    const [sortByFormat, setSortByFormat] = useState(false);
    const [sortByDate, setSortByDate] = useState(false);
    const [selectType, setSellectType] = useState(0);
    // const [status, setStatus] = useState(null);
    const secondaryAdmin = useSelector(state => state.users?.user?.org?._id ?? "");

    useEffect(() => {
        listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
    }, [])

    // list question
    const listContent = (pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType) => {
        const apiFun = secondaryAdmin ? questionListApiFunSecondary : questionListApiFun;
        const params = secondaryAdmin ? { pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType, secondaryAdmin: secondaryAdmin } : { pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType };
    
        apiFun(params)
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiData(res?.responseData);
                    setPageNo(pageNo);
                } else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear();
                    history.push("/login");
                }
                setLoader(false);
                setSearchStatus(false);
            })
            .catch(err => {
                console.log("error", err);
                setLoader(false);
                setSearchStatus(false);
            });
    };

    // // organization status
    // const organizationStatus = (status, id) => {
    //     let params = {
    //         contentId: id,
    //         isActiveOrg: status.target.checked === true ? 1 : 0,
    //     }
    //     orgStatusApiFunction(params)
    //         .then(res => {
    //             if (res?.statusCode === 1) {
    //                 listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
    //             }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
    //                 localStorage.clear()
    //                 history.push("/login");
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         })
    // }

    // individual status
    // const individualStatus = (status, id) => {
    //     let params = {
    //         contentId: id,
    //         isActiveIvd: status.target.checked === true ? 1 : 0,
    //     }
    //     individualStatusApiFunction(params)
    //         .then(res => {
    //             if (res?.statusCode === 1) {
    //                 listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
    //             }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
    //                 localStorage.clear()
    //                 history.push("/login");
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         })
    // }

    // delete question
    const handleDelete = (id) => {
        confirm({
            title: 'Do you Want to delete this Admin?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setLoader(true)
                deleteQuestionApiFun(id)
                    .then(res => {
                        if (res?.statusCode === 1) {
                            if (apiData?.questions.length === 1) {
                                let newPageNo = pageNo - 1;
                                listContent(newPageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
                            } else {
                                listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
                            }
                        }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                            localStorage.clear()
                            history.push("/login");
                        }
                        setLoader(false)
                    })
                    .catch(err => {
                        console.log("error", err);
                        setLoader(false)
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    // 
    const editContent = (id, question) => {
        history.push(`/questions/edit/${id}`, question, "Edit")
    }

    // handle pagination
    const handlePagination = page => {
        listContent(page, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
        
    };

    const handleSearch = searchEvent => {
        setPageNo(1);
        setSearchStatus(true)
        let searchKey = searchEvent.target.value;
        setSearchKey(searchKey);
        if (searchKey.length > 1) {
            listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
        } else {
            listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, selectType);
        }
    }


    const handleSort = (dir, field, byTitle, format, byDate) => {
        setLoader(true)
        setPageNo(1)
        setSortByTitle(byTitle);
        setSortByDate(byDate);
        setSortByFormat(format);
        setByAccenDecc(dir)
        setSortValue(field)
        listContent(pageNo, pageLimit, searchKey, field, dir, selectType);
    }

    const handleType = (value) => {
        setSellectType(value);
        listContent(pageNo, pageLimit, searchKey, sortValue, byAccenDecc, value);
    }


    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Assessments Questions</h2>
                    </div>
                </div>
                <div className="card  px-3 pb-3">
                    <div className="my-4">
                        <div className="row justify-content-between ">
                            <div className="col-md-5">
                                <div className="search">
                                    {
                                        searchStatus ?
                                            <span className="search-spiner d-flex align-items-center">
                                                <LoadingOutlined style={{ color: "#00BFFF" }} />
                                            </span>
                                            : ""
                                    }
                                    <input className="form-control" type="text" placeholder="Search..." onChange={(e) => handleSearch(e)} />
                                </div>
                            </div>
                            {questionsAccess?.addAccess && <div className="col-md-5 text-end d-flex align-items-center justify-content-end">

                                <Link to="/questions/add-new" className="btn btn-primary ml-3">Add New</Link>

                            </div>}
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Question</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Question Type</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Assessment</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Created At</span>
                                        </div>
                                    </th>
                                    <th colSpan='3'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    apiData?.questions?.map((question, index) => (
                                        <tr key={index}>
                                            <td>{pageLimit * (pageNo - 1) + (index + 1)}</td>
                                            <td>{question?.question}</td>
                                            <td>{question?.input_type}</td>
                                            <td>{question?.assessment?.title}</td>
                                            <td>
                                                <Moment format="DD/MM/YYYY">
                                                    {question?.created}
                                                </Moment>
                                            </td>

                                            {questionsAccess?.deleteAccess && <td>
                                                <i className="cursor-pointer fa fa-trash text-danger" onClick={() => handleDelete(question?._id)}></i>
                                            </td>}

                                           {questionsAccess?.updateAccess && <td>
                                                <i className="cursor-pointer fa fa-edit text-primary" onClick={() => editContent(question?._id, question)}></i>
                                            </td>}

                                            {questionsAccess?.viewAccess && <td>
                                                <Link to={`/questions/view/${question?._id}`}>
                                                    <i className="cursor-pointer fa fa-eye text-info"></i>
                                                </Link>
                                            </td>}
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="text-center">
                        <Pagination showSizeChanger={false} current={pageNo} onChange={handlePagination} total={apiData.total ? apiData.total : 1} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default QuestionsManagement;