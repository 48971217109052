import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/util/Svg';
import { organizationDashboard } from '../../services/organization/organizationDashboard';
import { BarChartOutlined } from '@ant-design/icons';

const OrganizationDashboard = props => {
    const [apiData, setApiData] = useState({})
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        setLoader(true)
        organizationDashboard()
            .then(res => {
                if (res?.statusCode === 1) {
                    setApiData(res?.responseData);
                }
                if(res?.error?.errorCode === 46 || res?.error?.errorCode === 2){
                    setLoader(true)
                    localStorage.clear();
                    props.history.push('/login');
                }
                setLoader(false)
                console.log("res", res)
            })
            .catch(err => {
                console.log("dashboard error", err);
                setLoader(false)
            })
    }, [])

    return (
        <div className="content-route">
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="page-header">
                <h2 className="page-title">Dashboard</h2>
            </div>
            <div className="row organization-dashboard-card">
                <div className="col-lg-6">
                    <div className="card text-center p-3 mb-3">
                        <div className="d-flex align-items-center">
                            <div className="w-50 text-center">
                                <BarChartOutlined style={{ fontSize: "40px" }} className="mb-3" />
                                <h4 className="">Total Code <br />{apiData?.totalCode ?? "0"}</h4>
                            </div>
                            <div className="w-50 border-start text-center p-2">
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <h5 className="">Unused Code</h5>
                                    </div>
                                    <div className="col-6">
                                        <h5 className="">{apiData?.unusedCode ?? "0"}</h5>
                                    </div>
                                </div>
                                <hr />
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <h5 className="">Total Used Code</h5>
                                    </div>
                                    <div className="col-6">
                                        <h5 className="">{apiData?.usedCode}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card text-center p-3 mb-3">
                        <div className="d-flex align-items-center">
                            <div className="w-50 text-center">
                                <BarChartOutlined style={{ fontSize: "40px" }} className="mb-3" />
                                <h4 className="">Total Content Added <br />{apiData?.totalContent ?? "0"}</h4>
                            </div>
                            <div className="w-50 border-start text-center p-2">
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <h5 className="">Assessment Uploaded</h5>
                                    </div>
                                    <div className="col-6 ">
                                        <h5 className="">{apiData?.asesmentUpload ?? 0}</h5>
                                    </div>
                                </div>
                                <hr />
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <h5 className="">Videos Uploaded</h5>
                                    </div>
                                    <div className="col-6">
                                        <h5 className="">{apiData?.videoUpload ?? 0}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default OrganizationDashboard;