export const development = {
  api: {
      url: 'https://devapi.travelhealth-companion.com/api/v1/',
      // mode: 'cors',
      AUTH: 'Basic dHJhdmVsX2NvbXBhbmlvbjp0cmF2ZWxfY29tcGFuaW9u'
  }
}

export const staging = {
  api: {
    url: "https://stagapi.travelhealth-companion.com/api/v1/",
    // mode: 'cors',
    AUTH: "Basic dHJhdmVsX2NvbXBhbmlvbjp0cmF2ZWxfY29tcGFuaW9u",
  },
};

export const production = {
  api: {
    url: "https://api.travelhealth-companion.com/api/v1/",
    // mode: 'cors',
    AUTH: "Basic dHJhdmVsX2NvbXBhbmlvbjp0cmF2ZWxfY29tcGFuaW9u",
  },
};
