import ApiInstance from "./../config/intercepter";
import Api from "./../config/api";


export function userListService(pageNo, pageLimit, sKey, filter, sort, genderType) {
    // ?page=${payload.page}&limit=${payload.limit}
    return ApiInstance.get(`${Api.listUser}?page=${pageNo}&limit=${pageLimit}&sKey=${sKey}&sortValue=${filter}&dir=${sort}&type=1&genderType=${genderType}`);
}

export function blockUnclockUserService(payload) {
    // ?page=${payload.page}&limit=${payload.limit}
    return ApiInstance.patch(`${Api.blockUnblockUser}`, payload);
}

export function deleteUserService(payload) {
    // ?page=${payload.page}&limit=${payload.limit}
    return ApiInstance.delete(`${Api.deleteUser}?userId=${payload}`);
}