import ApiInstance from "./../config/intercepter";
import Api from "./../config/api";
import { resHandle } from "../helper";
import axios from "axios";
import settings from "../config/settings";


// view Role
export function getAllDiseaseList(pageNo, pageLimit, sKey, filter, sort, type) {
  return ApiInstance.get(`${Api.ListDisease}?page=${pageNo}&limit=${pageLimit}&sKey=${sKey}&sortValue=${filter}&dir=${sort}`);
}

export function addDisease(payload) {
  return ApiInstance.post(`${Api.AddDisease}`, payload);
}

export function deleteDisease(payload) {
  return ApiInstance.delete(`${Api.UpdateDisease}/${payload}`);
}

export function viewDisease(payload) {
  return ApiInstance.get(`${Api.ViewDisease}/${payload}`);
}


export function updateDisease(payload) {
  return ApiInstance.put(`${Api.DeleteDisease}`, payload);
}

export function statusDisease(payload) {
  return ApiInstance.patch(`${Api.DiseaseStatus}`, payload);
}

