export function getToken(){
    let token = localStorage.getItem('token');
    if(token){
        return true;
    }
    else{
        return false;
    }
}

export function setUserDetail(userDetail){
    localStorage.setItem('userDetail', userDetail);
}

export function setUserToken(token){
    localStorage.setItem('token', token);
    
}

export function getUserToken(){
    return localStorage.getItem('token');
}

export function clearToken(){
    localStorage.clear()
}

// export function