import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { Loader } from "../util/Svg";
import LogoImg from "../../assets/img/logo.svg";
import { useSelector } from "react-redux";
import Accordion from "react-bootstrap/Accordion";

const Sidebar = ({ sidebarState, sidebarFun }) => {
  const [loader, setLoader] = useState(false);
  const users = useSelector((state) => state.users?.user?.org?.roles ?? []);
  const adminRole = useSelector(
    (state) => state.users?.user?.org?.adminRole?.roleName ?? []
  );

  const roleAccess = useMemo(
    () => users.find((role) => role.sectionName === "Role Management"),
    [users]
  );
  const adminAccess = useMemo(
    () =>
      users.find((role) => role.sectionName === "Administration Management"),
    [users]
  );
  const orgsAccess = useMemo(
    () => users.find((role) => role.sectionName === "Organization Management"),
    [users]
  );
  const contentAccess = useMemo(
    () => users.find((role) => role.sectionName === "Content"),
    [users]
  );
  const videosAccess = useMemo(
    () => users.find((role) => role.sectionName === "Videos"),
    [users]
  );
  const categoriesAccess = useMemo(
    () => users.find((role) => role.sectionName === "Categories"),
    [users]
  );
  const assessmentsAccess = useMemo(
    () => users.find((role) => role.sectionName === "Assessments"),
    [users]
  );
  const questionsAccess = useMemo(
    () => users.find((role) => role.sectionName === "Questions"),
    [users]
  );
  const trainersAccess = useMemo(
    () => users.find((role) => role.sectionName === "Trainers"),
    [users]
  );
  const usersAnswersAccess = useMemo(
    () => users.find((role) => role.sectionName === "UsersAnswers"),
    [users]
  );

  const diseaseManagement = useMemo(
    () =>
      users.find((role) => role.sectionName === "Travel Disease Management"),
    [users]
  );

  const notificationManagement = useMemo(
    () => users.find((role) => role.sectionName === "Notification Management"),
    [users]
  );

  return (
    <>
      {loader ? (
        <div className="loader_lg">
          <Loader />
        </div>
      ) : (
        ""
      )}
      <div className={`sidebar ${sidebarState ? "hide" : ""}`}>
        <div className="logo-wrapper d-flex align-items-center">
          <img src={LogoImg} className="logo-img" />
          <h3 className="logo-text">Travel Companion</h3>
        </div>
        <div className="menu-toggle-icons">
          {sidebarState ? (
            <MenuUnfoldOutlined className="toggle-icon" onClick={sidebarFun} />
          ) : (
            <MenuFoldOutlined className="toggle-icon" onClick={sidebarFun} />
          )}
        </div>
        <div className="sidebar-scroll">
          <ul className="list-unstyled items">
            <li className="item">
              <NavLink className="nav-item" to="/dashboard">
                <span className="menu-icon">
                  <i className="fa fa-home" />
                </span>
                <span className="menu_text">Dashboard</span>
              </NavLink>
            </li>
            {adminAccess?.viewAccess && (
              <li className="item">
                <NavLink className="nav-item" to="/administration">
                  <span className="menu-icon">
                    <i className="fa fa-university" />
                  </span>
                  <span className="menu_text">Dashboard Admin</span>
                </NavLink>
              </li>
            )}
            {roleAccess?.viewAccess && (
              <li className="item">
                <NavLink className="nav-item" to="/role-management">
                  <span className="menu-icon">
                    <i className="fa fa-address-book" />
                  </span>
                  <span className="menu_text">Role Management</span>
                </NavLink>
              </li>
            )}
            {orgsAccess?.viewAccess && (
              <li className="item">
                <NavLink className="nav-item" to="/organization">
                  <span className="menu-icon">
                    <i className="fa fa-handshake" />
                  </span>
                  <span className="menu_text">Organisation</span>
                </NavLink>
              </li>
            )}

            {adminRole === "SuperAdmin" && (
              <li className="item">
                <NavLink className="nav-item" to="/code">
                  <span className="menu-icon">
                    <i className="fa fa-home" />
                  </span>
                  <span className="menu_text">Codes</span>
                </NavLink>
              </li>
            )}

            {categoriesAccess?.viewAccess && (
              <li className="item">
                <NavLink className="nav-item" to="/title-editors">
                  <span className="menu-icon">
                    <i className="fa fa-square"></i>
                  </span>
                  <span className="menu_text">Title Editor</span>
                </NavLink>
              </li>
            )}

            <Accordion>
              <Accordion.Item
                eventKey="0"
                className="thc-sidebar__accordion--item"
              >
                <Accordion.Header> Travel </Accordion.Header>
                <Accordion.Body>
                  {diseaseManagement?.viewAccess && (
                    <li className="item">
                      <NavLink className="nav-item" to="/disease-management">
                        <span className="menu-icon">
                          <i
                            className="fa fa-thermometer-empty"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span className="menu_text">Disease Management</span>
                      </NavLink>
                    </li>
                  )}

                  {assessmentsAccess?.viewAccess && (
                    <li className="item">
                      <NavLink className="nav-item" to="/travel-tips-info">
                        <span className="menu-icon">
                          <i className="fa fa-search"></i>
                        </span>
                        <span className="menu_text">Travel Tips Info</span>
                      </NavLink>
                    </li>
                  )}
                </Accordion.Body>
              </Accordion.Item>

              {(videosAccess?.viewAccess || assessmentsAccess?.viewAccess) && (
                <Accordion.Item
                  eventKey="1"
                  className="thc-sidebar__accordion--item"
                >
                  <Accordion.Header> Physical </Accordion.Header>
                  <Accordion.Body>
                    {videosAccess?.viewAccess && (
                      <li className="item">
                        <NavLink className="nav-item" to="/physical-videos">
                          <span className="menu-icon">
                            <i className="fa fa-video" />
                          </span>
                          <span className="menu_text">Videos</span>
                        </NavLink>
                      </li>
                    )}

                    {assessmentsAccess?.viewAccess && (
                      <li className="item">
                        <NavLink
                          className="nav-item"
                          to="/physical-assessments"
                        >
                          <span className="menu-icon">
                            <i className="fa fa-table"></i>
                          </span>
                          <span className="menu_text">Assessments</span>
                        </NavLink>
                      </li>
                    )}

                    {trainersAccess?.viewAccess && (
                      <li className="item">
                        <NavLink className="nav-item" to="/physical-trainers">
                          <span className="menu-icon">
                            <i className="fa fa-users"></i>
                          </span>
                          <span className="menu_text">
                            Online Professionals
                          </span>
                        </NavLink>
                      </li>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              )}

              {(videosAccess?.viewAccess || assessmentsAccess?.viewAccess) && (
                <Accordion.Item
                  eventKey="2"
                  className="thc-sidebar__accordion--item"
                >
                  <Accordion.Header> Psychological </Accordion.Header>
                  <Accordion.Body>
                    {videosAccess?.viewAccess && (
                      <li className="item">
                        <NavLink
                          className="nav-item"
                          to="/psychological-videos"
                        >
                          <span className="menu-icon">
                            <i className="fa fa-video" />
                          </span>
                          <span className="menu_text">Videos</span>
                        </NavLink>
                      </li>
                    )}

                    {assessmentsAccess?.viewAccess && (
                      <li className="item">
                        <NavLink
                          className="nav-item"
                          to="/psychological-assessments"
                        >
                          <span className="menu-icon">
                            <i className="fa fa-table"></i>
                          </span>
                          <span className="menu_text">Assessments</span>
                        </NavLink>
                      </li>
                    )}

                    {trainersAccess?.viewAccess && (
                      <li className="item">
                        <NavLink
                          className="nav-item"
                          to="/psychological-trainers"
                        >
                          <span className="menu-icon">
                            <i className="fa fa-users"></i>
                          </span>
                          <span className="menu_text">
                            Online Professionals
                          </span>
                        </NavLink>
                      </li>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>

            {/* {trainersAccess?.viewAccess && (
              <li className="item">
                <NavLink className="nav-item" to="/trainers">
                  <span className="menu-icon">
                    <i className="fa fa-users"></i>
                  </span>
                  <span className="menu_text"> Online Professionals </span>
                </NavLink>
              </li>
            )} */}

            {adminRole === "SuperAdmin" && (
              <li className="item">
                <NavLink className="nav-item" to="/crisis-line">
                  <span className="menu-icon d-flex align-items-center">
                    <LineChartOutlined />
                  </span>
                  <span className="menu_text">Crisis Line</span>
                </NavLink>
              </li>
            )}

            {contentAccess?.viewAccess && (
              <li className="item">
                <NavLink className="nav-item" to="/content-management">
                  <span className="menu-icon">
                    <i className="fa fa-address-card" />
                  </span>
                  <span className="menu_text"> Content Approval </span>
                </NavLink>
              </li>
            )}

            {users?.find((user) => user.sectionName === "Users")
              ?.viewAccess && (
              <li className="item">
                <NavLink className="nav-item" to="/users">
                  <span className="menu-icon">
                    <i className="fa fa-users"></i>
                  </span>
                  <span className="menu_text">Users</span>
                </NavLink>
              </li>
            )}

            {notificationManagement?.viewAccess && (
              <li className="item">
                <NavLink className="nav-item" to="/notifications">
                  <span className="menu-icon">
                    <i className="fa fa-bell" />
                  </span>
                  <span className="menu_text">Notifications Management</span>
                </NavLink>
              </li>
            )}

            {/* {questionsAccess?.viewAccess && (
              <li className="item">
                <NavLink className="nav-item" to="/questions">
                  <span className="menu-icon">
                    <i className="fa fa-question" />
                  </span>
                  <span className="menu_text">Questions</span>
                </NavLink>
              </li>
            )} */}

            {/* Uncomment and modify as necessary when enabling UsersAnswers */}
            {/* {usersAnswersAccess?.viewAccess && (
              <li className="item">
                <NavLink className="nav-item" to="/usersAnswers">
                  <span className="menu-icon">
                    <i className="fa fa-list"></i>
                  </span>
                  <span className="menu_text">Users Answers</span>
                </NavLink>
              </li>
            )} */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
