import React, { useEffect, useState } from "react";
import { Loader } from "../../components/util/Svg";
import Moment from "react-moment";
import { useForm } from "react-hook-form";
import {
  LoadingOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Pagination, Modal } from "antd";
import {
  codeStatusService,
  deleteCode,
  orgCodeListService
} from "../../services/organization/code";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
const Code = (props) => {
  let history = useHistory();
  const [apiData, setApiData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [sortValue, setSortValue] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [searchStatus, setSearchStatus] = useState(false);
  const [byAccenDecc, setByAccenDecc] = useState("-1");
  const [sortCode, setSortCode] = useState(false);
  const [sortEmail, setSortEmail] = useState(false);
  const [sortRegister, setSortRegister] = useState(false);
  const [sortByCreated, setSortCreated] = useState(false);
  const orgName = useSelector(state => state.users?.user?.org?.orgName ?? [])
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { confirm: showConfirm } = Modal;

  useEffect(() => {
    CodeEmployee(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
  }, []);

  // list content
  const CodeEmployee = (
    pageNo,
    pageLimit,
    searchKey,
    sortValue,
    byAccenDecc
  ) => {
    orgCodeListService(pageNo, pageLimit, searchKey, sortValue, byAccenDecc)
      .then((res) => {
        if (res?.statusCode === 1) {
          setApiData(res?.responseData);
          setPageNo(pageNo);
        } else if (
          res?.error?.errorCode === 46 ||
          res?.error?.errorCode === 2
        ) {
          localStorage.clear();
          history.push("/login");
        }
        setLoader(false);
        setSearchStatus(false);
      })
      .catch((err) => {
        console.log("Code error", err);
        setLoader(false);
        setSearchStatus(false);
      });
  };

  // individual status
  const codeStatus = (status, id, isAvailable) => {
    let params = {
      codeId: id,
      status: status.target.checked === true ? 1 : 0,
    };
    if (isAvailable === 1) {
      codeStatusService(params)
        .then((res) => {
          if (res?.statusCode === 1) {
            CodeEmployee(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
          } else if (
            res?.error?.errorCode === 46 ||
            res?.error?.errorCode === 2
          ) {
            localStorage.clear();
            history.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
    }
  };

  // handle pagination
  const handlePagination = (page) => {
    CodeEmployee(page, pageLimit, searchKey, sortValue, byAccenDecc);
  };


  const codeDelete = (e, codeId) => {
    showConfirm({
      title: "Are you sure you want to delete this code?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        deleteCode(codeId)
          .then((res) => {
            if (res?.statusCode === 1) {
              Modal.success({
                title: "Code Successfully Deleted",
                content: "The code has been successfully deleted.",
              });
              CodeEmployee(
                pageNo,
                pageLimit,
                searchKey,
                sortValue,
                byAccenDecc
              );
            } else if (
              res?.error?.errorCode === 46 ||
              res?.error?.errorCode === 2
            ) {
              localStorage.clear();
              history.push("/login");
            }
          })
          .catch((err) => {
            console.error(err);
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleSearch = (searchEvent) => {
    setPageNo(1);
    setSearchStatus(true);
    let searchKey = searchEvent.target.value;
    setSearchKey(searchKey);
    if (searchKey.length > 1) {
      CodeEmployee(pageNo, pageLimit, searchKey, sortValue, byAccenDecc);
    } else {
      CodeEmployee(pageNo, pageLimit, "", sortValue, byAccenDecc);
    }
  };

  const handleSort = (dir, field, code, email, register, created) => {
    setLoader(true);
    setPageNo(1);
    setSortCode(code);
    setSortEmail(email);
    setSortRegister(register);
    setSortCreated(created);
    setByAccenDecc(dir);
    setSortValue(field);
    CodeEmployee(pageNo, pageLimit, searchKey, field, dir);
  };

  const handleSortValueChange = () => {
    const newSortDirection = byAccenDecc === "1" ? "-1" : "1";
    setByAccenDecc(newSortDirection);
    CodeEmployee(pageNo, pageLimit, searchKey, sortValue, newSortDirection);
  };



  return (
    <>
      {loader ? (
        <div className="loader_lg">
          <Loader />
        </div>
      ) : (
        ""
      )}
      <div className="content-route">
        <div className="card mb-3">
          <div className="page-header">
            <h2 className="page-title mb-0">{orgName}'s Codes</h2>
          </div>
        </div>

        <div className="card  px-3 pb-3">
          <div className="my-4">
            <div className="row justify-content-between ">
              <div className="col-md-5">
                <div className="search">
                  {searchStatus ? (
                    <span className="search-spiner d-flex align-items-center">
                      <LoadingOutlined style={{ color: "#00BFFF" }} />
                    </span>
                  ) : (
                    ""
                  )}
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="sortToggle"
                    checked={byAccenDecc === "-1"}
                    onChange={handleSortValueChange}
                  />
                  <label className="form-check-label" htmlFor="sortToggle">
                    {byAccenDecc === "1" ? "Sort Ascending" : "Sort Descending"}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Employee Code</span>
                      {/* {
                                                sortCode === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "2", false, false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "2", true, false, false, false)} />
                                            } */}
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Employee Email</span>
                      {/* {
                                                sortEmail === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "3", false, false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "3", false, true, false, false)} />
                                            } */}
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Register On</span>
                      {/* {
                                                sortRegister === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "4", false, false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "4", false, false, true, false)} />
                                            } */}
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Created At</span>
                      {/* {
                                                sortByCreated === true ?
                                                    <SortDescendingOutlined onClick={() => handleSort("-1", "1", false, false, false, false)} />
                                                    :
                                                    <SortAscendingOutlined onClick={() => handleSort("1", "1", false, false, false, true)} />
                                            } */}
                    </div>
                  </th>
                  <th>Block/Unblock</th>
                  {/* <th>Delete Code</th> */}
                </tr>
              </thead>
              <tbody>
                {apiData?.codes?.map((code, index) => (
                  <tr key={index}>
                    <td>{pageLimit * (pageNo - 1) + (index + 1)}</td>
                    <td>{code?.code ?? "NA"}</td>
                    <td>{code?.email ?? "NA"}</td>
                    <td>
                      {code?.register ? (
                        <Moment format="DD/MM/YYYY">{code?.register}</Moment>
                      ) : (
                        "NA"
                      )}
                    </td>
                    <td>
                      <Moment format="DD/MM/YYYY">
                        {code?.created ?? "NA"}
                      </Moment>
                    </td>
                    <td>
                      <div className="form-check form-switch">
                        <input
                          checked={code?.status ? true : false}
                          onChange={(e) =>
                            codeStatus(e, code?._id, code?.isAvailable)
                          }
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                        />

                        {/* <label className="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label> */}
                      </div>
                      {/* <Switch defaultChecked={content?.isActiveOrg ? true : false}
                                                    onChange={(e) => organizationStatus(e, content?._id)}
                                                /> */}
                    </td>
                    {/* <td>
                      <div className="form-check form-switch">
                        <i
                          className="cursor-pointer fa fa-trash text-danger"
                          onClick={(e) => codeDelete(e, code?._id)}
                        ></i>
                      </div>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="text-center">
            <Pagination
              showSizeChanger={false}
              current={pageNo}
              onChange={handlePagination}
              total={apiData.total ? apiData.total : 1}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Code;
