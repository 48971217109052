import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/util/Svg';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { addContentOrg, updateContentOrg, viewContentOrg } from '../../services/organization/content';

const AddEditContent = props => {
    const { contentId } = useParams()
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
        }
    });
    let history = useHistory()
    const [loader, setLoader] = useState(false);
    const [ImageUrl, setImageUrl] = useState([]);
    const [url, setURL] = useState("");
    const [pictureType, setPictureType] = useState('');
    const [fileData, setFileData] = useState(null);
    const [thumbnail, setThumbnail] = useState('');

    useEffect(() => {
        if (contentId) {
            viewContentApi();
        }
    }, [])

    const viewContentApi = () => {
        setLoader(true)
        viewContentOrg(contentId)
            .then(res => {
                if (res?.statusCode === 1) {
                    setFileData(res?.responseData?.content?.media);
                    setThumbnail(`${res?.responseData?.th_bP}${res?.responseData?.content?.th_media}`);
                    setURL(res?.responseData?.bP);
                    setPictureType(res?.responseData?.content?.contentFormat === 0 ? "video" : res?.responseData?.content?.contentFormat === 1 ? "document" : "image")
                    setValue("title", res?.responseData?.content?.title);
                    setValue("sectionContent", res?.responseData?.content?.contentSection);
                    setValue("sectionContent", res?.responseData?.content?.contentSection);
                    setValue("contentFormat", res?.responseData?.content?.contentFormat === 1 ? "document" : res?.responseData?.content?.contentFormat === 2 ? "image" : "video");
                }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                    localStorage.clear()
                    history.push("/login");
                }
                setLoader(false)
            })
            .catch(err => {
                setLoader(false)
            })
    }


    // add edit content
    const onSubmit = (data) => {
        if (!contentId) {
            let mediaData = data.media[0]
            setImageUrl(URL.createObjectURL(mediaData))
            setLoader(true);
            let contentFormData = new FormData();
            contentFormData.append('title', data.title);
            contentFormData.append('media', mediaData);
            contentFormData.append('type', 1);
            contentFormData.append('contentFormat', pictureType === "video" ? 0 : pictureType === "document" ? 1 : pictureType === "image" ? 2 : "");
            contentFormData.append('contentSection', data.sectionContent);
            addContentOrg(contentFormData)
                .then(res => {

                    if (res?.statusCode === 1) {
                        history.push('/organization-content');
                    }else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                        localStorage.clear()
                        history.push("/login");
                    }
                    setLoader(false);
                })
                .catch(err => {
                    console.log("error", err);
                    setLoader(false);
                })
        } else {
            let mediaData = data.media[0]
            let contentFormData = new FormData();
            if (mediaData !== undefined) {
                setImageUrl(URL.createObjectURL(mediaData))
                contentFormData.append('media', mediaData);
                contentFormData.append('contentFormat', pictureType === "video" ? 0 : pictureType === "document" ? 1 : pictureType === "image" ? 2 : "");
            }
            contentFormData.append('title', data.title);
            contentFormData.append('contentId', contentId);
            contentFormData.append('type', 1);
            contentFormData.append('contentSection', data.sectionContent);
            updateContentOrg(contentFormData)
                .then(res => {

                    if (res?.statusCode === 1) {
                        history.push('/organization-content');
                    }
                    else if (res?.error?.errorCode === 46 || res?.error?.errorCode === 2) {
                        localStorage.clear()
                        history.push("/login");
                    }
                    setLoader(false);
                })
                .catch(err => {
                    console.log("error", err);
                    setLoader(false);
                })
        }

    }



    const handleChange = (e) => {
        if (e.target.files[0]) {
            let mediaType = e.target.files[0]?.type?.split("/")[0] === "application" ? "document" : e.target.files[0]?.type?.split("/")[0]
            setValue("contentFormat", mediaType)
            setPictureType(mediaType);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setFileData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    return (
        <>
            {loader ? <div className="loader_lg"><Loader /></div> : ''}
            <div className="content-route">
                <div className="card mb-3">
                    <div className="page-header">
                        <h2 className="page-title mb-0">Content / {contentId ? "Edit" : "Add New"}</h2>
                    </div>
                </div>
                <form className="card px-3 pb-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="my-4">
                        <div className="row justify-content-between flex-column">
                            <div className="col-md-5">
                                <div className="mb-4">
                                    <label>Title</label>
                                    <input
                                        {...register('title', { required: true })}
                                        className={`form-control ${errors.title ? 'is-invalid' : ''} `} type="text" placeholder="Content Title" />
                                    {errors.title && errors.title.type === "required" && <p className="input-error">Title is required.</p>}
                                </div>
                            </div>
                            {/* <div className="col-md-5">
                                <div className="mb-4">
                                    <label>Select Type</label>
                                    <select className={`form-control ${errors.type ? 'is-invalid' : ''} `}
                                        {...register('type', { required: true })}
                                    >
                                        <option value="" disabled selected>Select</option>
                                        <option value="0" >Admin</option>
                                        <option value="1" >Organization</option>
                                    </select>
                                    {errors.type && errors.type.type === "required" && <p className="input-error">Content type is required.</p>}
                                </div>
                            </div> */}

                            <div className="col-md-5">
                                <div className="mb-4">
                                    <label>Module</label>
                                    <select className={`form-control ${errors.sectionContent ? 'is-invalid' : ''} `}
                                        {...register('sectionContent', { required: true })}
                                    >
                                        <option value="" disabled selected>Select </option>
                                        <option value="0" >Travel tips info</option>
                                        {/* <option value="1" >Select two</option> */}
                                    </select>
                                    {errors.sectionContent && errors.sectionContent.type === "required" && <p className="input-error">Section is required.</p>}
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="mb-4">
                                    <div className="media-preview">
                                        {
                                            pictureType === 'video' ?
                                                <video controls className="video-preview w-100"  poster={thumbnail}>
                                                    {
                                                        contentId ? <source src={fileData.split(":")[0] == "data" ? fileData : `${url}${fileData}`} id="video_here"></source> :
                                                            <source src={fileData} id="video_here"></source>
                                                    }
                                                </video> :
                                                pictureType === 'image' ?
                                                    <div className="image-preview mb-4">
                                                        {
                                                            contentId ?
                                                                <img src={fileData.split(":")[0] == "data" ? fileData : `${url}${fileData}`} className="w-100" /> :
                                                                <img src={fileData} className="w-100" />
                                                        }
                                                    </div>
                                                    :
                                                    pictureType === 'document' ?
                                                        <div className="document-preview mb-4 d-flex align-items-center justify-content-center">
                                                            <i className="fa fa-file fa-3x"></i>
                                                        </div>
                                                        : null

                                        }
                                    </div>
                                    {
                                        contentId ?
                                            <>
                                                <label>Edit Media</label>
                                                <input
                                                    {...register('media', { required: false })}
                                                    onChange={handleChange}
                                                    className={`form-control`} type="file" placeholder="Enter Email" />
                                            </> :
                                            <>
                                                <label>Upload Media</label>
                                                <input
                                                    {...register('media', { required: true })}
                                                    onChange={handleChange}
                                                    className={`form-control ${errors.media ? 'is-invalid' : ''} `} type="file" placeholder="Enter Email" />
                                                {errors.media && errors.media.type === "required" && <p className="input-error">Media is required.</p>}
                                            </>
                                    }

                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="mb-4">
                                    <label>Category</label>
                                    <input
                                        {...register('contentFormat', { required: true })}
                                        disabled={true}
                                        className={`form-control ${errors.contentFormat ? 'is-invalid' : ''} `} type="text" placeholder="Content Format" />
                                    {errors.contentFormat && errors.contentFormat.type === "required" && <p className="input-error">contentFormat is required.</p>}
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddEditContent


